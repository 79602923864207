import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBInput } from "mdbreact";
import InputMask from "react-input-mask";
import axios from "../../Shared/auth-header";
import AlreadyRegisteredModal from "./Modals/AlreadyRegisteredModal";
import { toast } from "react-toastify";

export default class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };
  handleOnBlur(e) {
    axios
      .post(
        `/api/Account/CheckRegistrationStatus?memberNumber=${this.props.MemberNumber}`
      )
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          if (data.isRegisteredAlready === false) {
          } else if (data.isRegisteredAlready === true) {
            this.setState({ showModal: true });
          }
        } else {
          toast.error(data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  render() {
    if (this.props.currentStep !== 1) {
      // Prop: The current step
      return null;
    }
    return (
      <div className="step1Parent">
        <div className="form-group">
          <MDBInput
            name="MemberNumber"
            value={this.props.MemberNumber}
            onChange={this.props.changeHandler}
            label="NATCA Member Number"
            maxLength="5"
            containerClass="mb-2"
            group
            type="text"
            onBlur={this.handleOnBlur}
          >
            <span className="text-danger">{this.props.MemberNumber_err}</span>
          </MDBInput>
          <a target="_blank" href="/MemberNumberLookup">
            I don't know my member number.
          </a>
        </div>
        <div className="form-group">
          <MDBInput
            name="Email"
            value={this.props.Email}
            onChange={this.props.handleChangeEmail}
            label="Email"
            group
            type="text"
          >
            <span className="text-danger">{this.props.email_err}</span>
          </MDBInput>
        </div>
        <div className="md-form form-group mt-0 mb-2">
          <InputMask
            value={this.props.DateofBirth}
            onChange={this.props.handleChangeDOB}
            name="DateofBirth"
            data-type="P"
            mask="99/99/9999"
            className="form-control"
            type="text"
            placeholder="Birthdate"
            label="Date of Birth"
          />
          <span className="text-danger">{this.props.DateofBirth_err}</span>
        </div>

        <div className="form-group">
          <MDBInput
            name="userName"
            value={this.props.userName}
            onChange={this.props.changeUserNameHandler}
            label="Username"
            group
            type="text"
          >
            <span className="text-danger">{this.props.userName_err}</span>
          </MDBInput>
        </div>
        <div className="form-group">
          <MDBInput
            name="Password"
            value={this.props.Password}
            onChange={this.props.handleChangePwd}
            label="Password"
            group
            type="password"
          >
            <span className="text-danger">{this.props.Password_err}</span>
          </MDBInput>
        </div>
        <div className="form-group">
          <MDBInput
            name="RePassword"
            value={this.props.RePassword}
            onChange={this.props.handleChangePwd}
            label="Confirm Password"
            group
            type="password"
          >
            <span className="text-danger">{this.props.RePassword_err}</span>
          </MDBInput>
        </div>
        <div className="form-group m-t-10 mb-4 row">
          <div className="col-12 m-t-20 mx-auto text-center">
            <button
              disabled={!this.props.valid}
              className="btn btn-primary btn-block w-md waves-effect waves-light"
              type="button"
              onClick={(e) => this.props.registerClick(e)}
            >
              Register
            </button>
          </div>
          <div className="col-12 m-t-20 text-left ">
            <span style={{ fontWeight: "450" }}>
              Already have an account ?{" "}
            </span>
            <Link to="login" className="font-500 text-primary text-right">
              {" "}
              Login{" "}
            </Link>{" "}
          </div>

          <div className="col-12 m-t-15 text-left font-weight-bold">
            By registering for an account with NATCA you agree to our
            <Link
              to="/privacy-policy"
              className="font-500 text-primary text-right"
            >
              {" "}
              Terms & Conditions.{" "}
            </Link>{" "}
          </div>

          <div className="col-12 m-t-5 text-left font-weight-bold">
            Need help?
          </div>
          <div className="col-12 m-t-5 text-left font-weight-lighter">
            Can't remember your Member Number?
          </div>
          <div className="col-12 m-t-5 text-left">
            <a
              className="font-500 text-primary text-right"
              href="/MemberNumberLookup"
              target="_blank"
            >
              Member Number Lookup
            </a>
            {/* <Link
              to="/ForgotUsername.aspx"
              className="font-500 text-primary text-right"
            >
              {" "}
              Member Number Lookup{" "}
           </Link>{" "}*/}
          </div>
          <div className="col-12 m-t-5 text-left font-weight-lighter">
            Unable to complete account registration?
          </div>
          <div className="col-12 m-t-5 text-left">
            <a target="_blank" href={`mailto:support@natca.org`}>
              support@natca.org
            </a>
          </div>

          <AlreadyRegisteredModal
            showModal={this.state.showModal}
            onClose={this.handleClose}
          />
        </div>
      </div>
    );
  }
}
