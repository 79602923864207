import React, { Component } from "react";
import {
  Nav,
  Navbar,
  NavItem,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaTrash } from "react-icons/fa";
import _ from "lodash";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import "./topbar.css";
import actionFilter from "../../../src/store/advancedfilter/action";
import actionSaveSearch from "../../../src/store/saveSearch/action";
import actionNotification from "../../../src/store/userNotification/action";
import TopSearchBar from "../Layout/TopSearchBar";
import { connect } from "react-redux";
import { isMobile } from "../../modules/Shared/Helpers";
import AuthService from "../../modules/Auth/AuthService";
import PermissionFeature from "../../modules/Permissions/PermissionFeature";
import Notifications from "../../modules/Notifications/CustomNotifications";
import payfileActions from "../../../src/store/payfile/action";

const data = [
  {
    id: 1,
    message: "Lorem ipsum dolor sit amet.",
    detailPage: "/events",
    receivedTime: "12h ago",
  },
  {
    id: 2,
    message: (
      <p>
        Lorem S had shared a <span style={{ color: "#7ac2fa" }}>feedback</span>{" "}
        with you.
      </p>
    ),
    detailPage: "/events",
    receivedTime: "12h ago",
  },
  {
    id: 3,
    message: (
      <p>
        ipsum S had shared a <span style={{ color: "#7ac2fa" }}>feedback</span>{" "}
        with you.
      </p>
    ),
    detailPage: "/events",
    receivedTime: "12h ago",
  },
  {
    id: 4,
    message: (
      <p>
        dolor S had shared a <span style={{ color: "#7ac2fa" }}>feedback</span>{" "}
        with you.
      </p>
    ),
    detailPage: "/events",
    receivedTime: "12h ago",
  },
  {
    id: 5,
    message: (
      <p>
        sit S had shared a <span style={{ color: "#7ac2fa" }}>feedback</span>{" "}
        with you.
      </p>
    ),
    detailPage: "/events",
    receivedTime: "12h ago",
  },
  {
    id: 6,
    message: (
      <p>
        amet S had shared a <span style={{ color: "#7ac2fa" }}>feedback</span>{" "}
        with you.
      </p>
    ),
    detailPage: "/events",
    receivedTime: "12h ago",
  },
  {
    id: 7,
    message: (
      <p>
        dolor sit had shared a{" "}
        <span style={{ color: "#7ac2fa" }}>feedback</span> with you.
      </p>
    ),
    detailPage: "/events",
    receivedTime: "12h ago",
  },
  {
    id: 8,
    message: (
      <p>
        Lorem ipsum S had shared a{" "}
        <span style={{ color: "#7ac2fa" }}>feedback</span> with you.
      </p>
    ),
    detailPage: "/events",
    receivedTime: "12h ago",
  },
];
class topbar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleprofile = this.toggleprofile.bind(this);
    this.togglebadge = this.togglebadge.bind(this);
    this.handlesignOut = this.handlesignOut.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.state = {
      dropdownOpen: false,
      dropdownOpen1: false,
      dropdownOpenprofile: false,
      dropdownOpenbadge: false,
      dropDownLable: "",
    };
    this.Auth = AuthService;
    this.user = AuthService.getProfile().memberData;
  }

  toggleSidebar() {
    this.props.toggleSidebarCallback();
    //  document.body.classList.toggle('enlarged');
  }
  handlesignOut() {
    this.disconnectSignalR();
    this.Auth.logoutAuthO();

    //this.props.history.push("/login");
  }

  disconnectSignalR = async () => {
    console.log("disconnecting.........");
    //debugger;
    await this.props.wsDisconnect(
      `${process.env.REACT_APP_API_BASE_URL}/updateProgress`
    );
    console.log("disconnected.........");
  };

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  toggle1() {
    this.setState((prevState) => ({
      dropdownOpen1: !prevState.dropdownOpen1,
    }));
  }
  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }
  togglebadge() {
    this.setState((prevState) => ({
      dropdownOpenbadge: !prevState.dropdownOpenbadge,
    }));
  }

  togglescreen(e) {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  componentWillMount() {
    if (this.Auth.loggedIn()) {
      this.state.user = this.Auth.getToken().memberData;
    }
  }
  componentDidMount() {
    console.log("componentDidMount topbar");
    if (this.Auth.loggedIn()) {
      this.props.GetUserNotifications(this.state.user.id);
    }
  }
  render() {
    return (
      <div className="topbar">
        <div className="topbar-left">
          <Link to="/" className="logo">
            <img
              width="100"
              src="/assets/images/logo.png"
              alt=""
              height="100"
            />
          </Link>
        </div>

        <nav className="navbar-custom">
          <ul className="navbar-right d-flex list-inline float-right mb-0">
            <li className="dropdown notification-list d-md-inline home-btn-li">
              <a
                href={`https://www.natca.org/members-home/`}
                className="home-link c-pointer"
              >
                <span className="mt-3 float-right p-2 text-secondary">
                  <i className="fas fa-globe"></i> 
                  <label className="pl-1 support-btn-li">Member's Home</label>
                </span>
              </a>
            </li>
       
            {AuthService.canView(PermissionFeature.NotificationIcon) && (
              <li className="mt-3 ml-2 notification-list d-md-inline">
                <Notifications
                  // classNamePrefix="okrjoy"
                  data={this.props.notiState.notificationList}
                  viewAllBtn={{ text: "see all", linkTo: "/seeAll" }}
                  markAsRead={(data) => {
                    // data.isread = !data.isread;
                    // console.log(data.isread);
                  }}
                />
              </li>
            )}
            <li className="dropdown notification-list d-md-inline">
              {this.user !== null && (
                <UncontrolledDropdown
                  setActiveFromChild
                  className="dropdown-menu-toggle"
                >
                  <DropdownToggle
                    tag="a"
                    className="nav-link nav-link-font-size"
                  >
                    {/* {this.user.nickname === null
                      ? this.user.firstname === null
                        ? ""
                        : this.user.firstname
                      : this.user.nickname}{" "} */}
                    {!_.isEmpty(this.user) &&
                      !_.isEmpty(this.user.nickname) &&
                      this.user.nickname}
                    {!_.isEmpty(this.user) &&
                      _.isEmpty(this.user.nickname) &&
                      !_.isEmpty(this.user.firstname) &&
                      this.user.firstname}{" "}
                    <i className="fas fa-caret-down"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-body">
                    <h5 className="dropdown-menu-header">
                      Hi,{" "}
                      {this.user.firstname === null ? "" : this.user.firstname}!
                    </h5>
                    <p className="memberNumberLabel">
                      {" "}
                      Member Number:{" "}
                      {this.user.membernumber === null
                        ? ""
                        : this.user.membernumber}
                    </p>
                    {/* <DropdownItem href="/account/profile" className="dropdown-item-custom"> Profile </DropdownItem> */}
                    {/* <DropdownItem
                      href="/account/changePassword"
                      className="dropdown-item-custom"
                    >
                      {" "}
                      Change Password{" "}
                    </DropdownItem> */}
                    <hr />
                    <DropdownItem
                      onClick={this.handlesignOut}
                      className="dropdown-item-custom"
                    >
                      {" "}
                      Logout{" "}
                      <i className="fas fa-sign-out-alt" aria-hidden="true"></i>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </li>
          </ul>

          <ul className="list-inline menu-left mb-0 display-none hamburger-menu">
            <li className="float-left">
              <button
                onClick={this.toggleSidebar}
                className="button-menu-mobile open-left waves-effect"
              >
                <i className="mdi mdi-menu" />
              </button>
            </li>
          </ul>

          <ul className="list-inline menu-left mb-0 TopBar-SearchBar">
            <li className="float-left mt-3 ml-3 col-sm-5">
              {AuthService.canView(PermissionFeature.Member) && (
                <TopSearchBar
                  history={this.props.history}
                  setAdvancedSearch={this.props.setAdvancedSearch}
                  setBasicSearchKeyWord={this.props.setBasicSearchKeyWord}
                  basicSearchKeyWord={this.props.filterState.basicSearchKeyWord}
                  setFilterResultPageNo={this.props.setFilterResultPageNo}
                  setFilterResultPageSize={this.props.setFilterResultPageSize}
                  setMemberAccordianType={this.props.setMemberAccordianType}
                  setFilterData={this.props.setFilterData}
                //isSavedSearchSelected={this.props.isSavedSearchSelected}
                // directoryMenuState={this.props.directoryMenuState}
                />
              )}
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    filterState: state.advancedFilter,
    SavedSearch: state.saveSearch,
    notiState: state.notification,
  }),
  {
    ...actionFilter,
    ...actionSaveSearch,
    ...actionNotification,
    ...payfileActions,
  }
)(topbar);
