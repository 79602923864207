import React from "react";

const footer = (props) => (
  <footer className="footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {new Date().getFullYear()} © NATCA. V 2.2.3
        </div>
      </div>
    </div>
  </footer>
);

export default footer;
