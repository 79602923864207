import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import SideNav from "./SideNav";
import Scrollbars from "react-custom-scrollbars";
import $ from "jquery";
import "./Leftsidebar.css";
import AuthService from "../../modules/Auth/AuthService";
import PropTypes from "prop-types";
import { isMobile } from "../../modules/Shared/Helpers";
import { ID } from "webpack/lib/ModuleFilenameHelpers";

class sidebar extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {};
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.resize = this.resize.bind(this);
  }

  resize = () => {
    if (window.innerWidth <= 1024) {
      this.props.removeSidebarOpenedCallback();
    } else if (window.innerWidth > 1025) {
      this.props.addSidebarOpenedCallback();
    }
  };

  toggleSidebar = () => {
    this.props.toggleSidebarCallback();
  };
  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (window.innerWidth <= 1024) {
      try {
        if (
          event.target.className.indexOf("button-menu-mobile") < 0 &&
          event.target.className.indexOf("mdi-menu") < 0
        ) {
          if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.closeSidebarCallback();
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
  componentDidMount() {
    window.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.resize);
  }

  render() {
    return (
      <AUX>
        <div className="left side-menu" ref={this.setWrapperRef}>
          {this.props.children}
          {this.props.isEnlarged ? (
            <SideNav toggleSidebarCallback={this.props.toggleSidebarCallback} />
          ) : (
            <Scrollbars>
              <SideNav
                toggleSidebarCallback={this.props.toggleSidebarCallback}
              />
            </Scrollbars>
          )}
        </div>
      </AUX>
    );
  }
}
sidebar.propTypes = {
  children: PropTypes.element.isRequired,
};
export default withRouter(sidebar);
