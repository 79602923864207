import React, { Component } from "react";
export default class Step2 extends React.Component {
  hoverEffect = (e) => {
    e.target.style.color = "#0056b3";
  };
  hoverRemove = (e) => {
    e.target.style.color = "#626ed4";
  };
  validateVerificationCode = () => {
    var result = false;
    if (!this.props.verificationcode) {
      result = true;
    }
    if (!(this.props.verificationcode.length === 7)) {
      result = true;
    }
    return result;
  };

  render() {
    if (this.props.currentStep !== 2) {
      // Prop: The current step
      return null;
    }

    // The markup for the Step 1 UI
    return (
      <div className="form-group">
        <h4 className="headerPos">Email Verfication</h4>
        <label htmlFor="verificationcode" className="font-weight-lighter">
          To confirm your email address, we've sent you a personal Verification
          code to{" "}
          <span
            style={{ textDecoration: "underline", color: "#626ed4" }}
            className="font-weight-normal"
          >
            {" "}
            {this.props.enteredEmail}
          </span>
        </label>
        <input
          className="form-control"
          id="verificationcode"
          name="verificationcode"
          type="text"
          placeholder="Verification Code"
          value={this.props.verificationcode} // Prop: The email input data
          onChange={this.props.handleChange} // Prop: Puts data into state
        />
        <span
          className="mb-6"
          style={{ cursor: "pointer" }}
          onClick={this.props.resendcode}
          onMouseOver={this.hoverEffect}
          onMouseOut={this.hoverRemove}
        >
          {" "}
          <small style={{ textDecoration: "underline", color: "#626ed4" }}>
            Resend code
          </small>
        </span>
        {/* <small> or </small> */}
        {/* <span
          className="mb-6"
          onClick={this.props.previous}
          style={{ cursor: "pointer" }}
          onMouseOver={this.hoverEffect}
          onMouseOut={this.hoverRemove}
        >
          {" "}
          <small style={{ textDecoration: "underline", color: "#626ed4" }}>
            Change Email
          </small>
        </span> */}
        <button
          disabled={this.validateVerificationCode()}
          className="btn btn-primary btn-block w-md waves-effect waves-light mt-5"
          type="button"
          onClick={this.props.verifyEmailAddressBtnClick}
        >
          Verify Email Address
        </button>
      </div>
    );
  }
}
