import React, { Component, Fragment } from "react";
import $, { nodeName } from "jquery";
import { MDBInput } from "mdbreact";
import _ from "lodash";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
class UserAccount extends Component {
  render() {
    const {
      handleChangeUsernameErr,
      changeHandlerUsername,
      handleResetPassword,
      handleFormSubmitUsername,
      handleChangIsLocked,
      handleChangIsEnabled,
      handleChangIsVerified,
      handleChangePwd,
      handleFormSubmitPassword,
      handleResetUsername,
      togglePasswordVisiblity,
      isPasswordShown,
      username,
      password,
      islocked,
      isenabled,
      isverified,
      ispassword,
      isusername,
      status,
      password_err,
      username_err,
      member,
    } = this.props;
    return (
      <div className="row col-12 px-0 mx-0 order-3 pr-md-2 ps-row-mac ps-column-mac">
        <div className="card directory-card border-rounded shadow w-100">
          <h5 className="card-header mt-0">
            <i className="fas fa-lock mr-2" />
            User Account
          </h5>
          <div className="card-body pb-3 pt-1">
            <div className="userAccount">
              <div className="row" id="userAccountInline">
                <div className="col-12">
                  {(AuthService.canOwnView(
                    PermissionFeature.AccountUsername,
                    this.props.memberid
                  ) ||
                    AuthService.canMemberView(
                      PermissionFeature.AccountUsername
                    )) && (
                    <div className="py-1">
                      <div id="username-inline">
                        <span className="card-subtitle mb-2 text-muted">
                          <strong>Username: </strong>
                        </span>

                        <span className="card-text pr-1">
                          {username == null || username == ""
                            ? "none"
                            : username}
                        </span>
                        {(AuthService.canOwnUpdate(
                          PermissionFeature.AccountUsername,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberUpdate(
                            PermissionFeature.AccountUsername
                          )) && (
                          <i
                            className="fas fa-pencil-alt edit-pencil-icon"
                            onClick={() => {
                              $("#username-inline").addClass("d-none");
                              $("#username-edit").removeClass("d-none");
                            }}
                          ></i>
                        )}
                      </div>
                      {(AuthService.canOwnUpdate(
                        PermissionFeature.AccountUsername,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberUpdate(
                          PermissionFeature.AccountUsername
                        )) && (
                        <div id="username-edit" className="d-none">
                          <span className="card-subtitle mb-2 pr-1 text-secondary">
                            Username:
                          </span>
                          <i
                            className="c-pointer fas fa-times"
                            onClick={() => {
                              $("#username-inline").removeClass("d-none");
                              $("#username-edit").addClass("d-none");
                              handleResetUsername();
                              handleChangeUsernameErr("");
                            }}
                          ></i>
                          <MDBInput
                            containerClass="mt-0 mb-2"
                            className="py-0 mb-0"
                            name="username"
                            value={username ? username : ""}
                            onChange={changeHandlerUsername}
                            hint="write your username"
                            autocomplete="off"
                            disabled={
                              !AuthService.canOwnUpdate(
                                PermissionFeature.AccountUsername,
                                this.props.memberid
                              ) &&
                              !AuthService.canMemberUpdate(
                                PermissionFeature.AccountUsername
                              )
                            }
                            group
                            type="text"
                          />
                          <span className="text-danger d-block">
                            {username_err}
                          </span>

                          <div className="mt-2">
                            <button
                              onClick={handleFormSubmitUsername}
                              className="btn btn-success btn-sm mr-1 px-3 waves-effect waves-light"
                              type="submit"
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-sm btn-danger px-3 waves-effect waves-light"
                              type="button"
                              onClick={() => {
                                $("#username-inline").removeClass("d-none");
                                $("#username-edit").addClass("d-none");
                                handleResetUsername();
                                handleChangeUsernameErr("");
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* {canView("status") && (
                                      <div className="py-1">
                                          <span className="card-subtitle mb-2 text-muted">
                                              <strong>Account Status: </strong>
                                          </span>
                                          <span className="card-text">
                                              {status == null || status == ""
                                                  ? "none"
                                                  : status}
                                          </span>
                                      </div>
                                  )} */}
                  <div className="col-12 align-self-center resetPassMobile pl-0">
                    {(AuthService.canOwnUpdate(
                      PermissionFeature.AccountPassword,
                      this.props.memberid
                    ) ||
                      AuthService.canMemberUpdate(
                        PermissionFeature.AccountPassword
                      )) && (
                      <Fragment>
                        <div id="password-btn-inline">
                          <button
                            onClick={() => {
                              $("#password-btn-inline").addClass("d-none");
                              $("#password-edit").removeClass("d-none");
                              $("#password-edit").addClass("mt-2");
                            }}
                            className="btn btn-lg mt-1 mt-md-0 restPwdBtn rounded-pill btn-success"
                          >
                            Reset Password
                          </button>
                        </div>
                        <div id="password-edit" className="d-none mt-2">
                          <span className="card-subtitle mb-2 pr-1 text-secondary">
                            <strong>Password:</strong>
                          </span>
                          <div className="form-group position-relative">
                            <MDBInput
                              containerClass="mt-0 mb-2"
                              className="py-0 mb-0"
                              name="password"
                              value={password}
                              onChange={handleChangePwd}
                              autocomplete="off"
                              disabled={
                                !AuthService.canOwnUpdate(
                                  PermissionFeature.AccountPassword,
                                  this.props.memberid
                                ) &&
                                !AuthService.canMemberUpdate(
                                  PermissionFeature.AccountPassword
                                )
                              }
                              group
                              type={isPasswordShown ? "text" : "password"}
                            />
                            <i
                              className={`fa ${
                                isPasswordShown ? "fa-eye-slash" : "fa-eye"
                              } password-icon-position`}
                              onClick={togglePasswordVisiblity}
                            />
                            <span className="text-danger d-block">
                              {password_err}
                            </span>
                          </div>

                          <div className="mt-2">
                            <button
                              onClick={handleFormSubmitPassword}
                              className="btn btn-success btn-sm mr-1 px-3 waves-effect waves-light"
                              type="submit"
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-sm btn-danger px-3 waves-effect waves-light"
                              type="button"
                              onClick={() => {
                                $("#password-btn-inline").removeClass("d-none");
                                $("#password-edit").addClass("d-none");
                                handleResetPassword();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  {(AuthService.canOwnView(
                    PermissionFeature.AccountIsEnabled,
                    this.props.memberid
                  ) ||
                    AuthService.canMemberView(
                      PermissionFeature.AccountIsEnabled
                    )) && (
                    <div className="py-1">
                      <span className="card-subtitle mb-2 text-muted">
                        <strong>Account Enabled: </strong>
                      </span>
                      <span className="card-text">
                        <input
                          type="checkbox"
                          switch="bool"
                          disabled={
                            !AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsEnabled,
                              this.props.memberid
                            ) &&
                            !AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsEnabled
                            )
                          }
                          checked={isenabled}
                          onChange={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsEnabled,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsEnabled
                            )
                              ? handleChangIsEnabled
                              : ""
                          }
                          id={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsEnabled,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsEnabled
                            )
                              ? "isenabled"
                              : ""
                          }
                          name={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsEnabled,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsEnabled
                            )
                              ? "isenabled"
                              : ""
                          }
                        />
                        <label
                          className="mb-n2"
                          htmlFor={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsEnabled,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsEnabled
                            )
                              ? "isenabled"
                              : ""
                          }
                          data-on-label="Yes"
                          data-off-label="No"
                        />
                      </span>
                    </div>
                  )}
                  {(AuthService.canOwnView(
                    PermissionFeature.AccountIsLocked,
                    this.props.memberid
                  ) ||
                    AuthService.canMemberView(
                      PermissionFeature.AccountIsLocked
                    )) && (
                    <div className="py-1">
                      <span className="card-subtitle mb-2 text-muted">
                        <strong>Account Locked: </strong>
                      </span>
                      <span className="card-text">
                        <input
                          type="checkbox"
                          switch="bool"
                          disabled={
                            !AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsLocked,
                              this.props.memberid
                            ) &&
                            !AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsLocked
                            )
                          }
                          checked={islocked}
                          onChange={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsLocked,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsLocked
                            )
                              ? handleChangIsLocked
                              : ""
                          }
                          id={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsLocked,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsLocked
                            )
                              ? "islocked"
                              : ""
                          }
                          name={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsLocked,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsLocked
                            )
                              ? "islocked"
                              : ""
                          }
                        />
                        <label
                          className="mb-n2"
                          htmlFor={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsLocked,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsLocked
                            )
                              ? "islocked"
                              : ""
                          }
                          data-on-label="Yes"
                          data-off-label="No"
                        />
                      </span>
                    </div>
                  )}
                  {(AuthService.canOwnView(
                    PermissionFeature.AccountIsVerified,
                    this.props.memberid
                  ) ||
                    AuthService.canMemberView(
                      PermissionFeature.AccountIsVerified
                    )) && (
                    <div className="py-1">
                      <span className="card-subtitle mb-2 text-muted">
                        <strong>Account Verified: </strong>
                      </span>
                      <span className="card-text">
                        <input
                          type="checkbox"
                          switch="bool"
                          checked={isverified}
                          disabled={
                            isusername == false || ispassword == false
                              ? true
                              : false
                          }
                          onChange={
                            (AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsVerified,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberUpdate(
                                PermissionFeature.AccountIsVerified
                              )) &&
                            username != null &&
                            username != "" &&
                            username != undefined &&
                            ispassword == true
                              ? handleChangIsVerified
                              : ""
                          }
                          id={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsVerified,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsVerified
                            )
                              ? "isverified"
                              : ""
                          }
                          name={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsVerified,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsVerified
                            )
                              ? "isverified"
                              : ""
                          }
                        />
                        <label
                          className="mb-n2"
                          htmlFor={
                            AuthService.canOwnUpdate(
                              PermissionFeature.AccountIsVerified,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.AccountIsVerified
                            )
                              ? "isverified"
                              : ""
                          }
                          title={
                            isusername == false || ispassword == false
                              ? "Username, Password, and Email are required for isverified!"
                              : false
                          }
                          data-on-label="Yes"
                          data-off-label="No"
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserAccount;
