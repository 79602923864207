import React, { Component } from "react";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
export class Email extends Component {
  Checkbox = ({ input, meta: { touched, error }, data }) => {
    return (
        <div className="ml-3 w-100 m-2 custom-checkbox">
        <input
          {...input}
          type="checkbox"
          id={input.name}
          htmlFor={input.name}
          checked={input.value == true}
          className="custom-control-input pr-2"
        />
        <label
          className="h6 custom-control-label"
          for={input.name}
          style={{ paddingTop: 2, zIndex: 0 }}
        >
          {data.hasPrimaryEmail}
          {this.props.filterState.filterData && (
            <small className="totalCount">({data.total})</small>
          )}
        </label>
      </div>
    );
  };
  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <label>{label} </label>
        ) : (
          <label> </label>
        )}
        <input
          {...input}
          className={classForm}
          type={type}
          autoComplete="off"
          placeholder={this.props.isMobile == true ? placeholder : ""}
          style={
            meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
          }
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    console.log(formValues);
  };

  validateNumber = (value) => {
    // value && isNaN(Number(value))
    //   ? "Please enter numeric characters only."
    //   : undefined;
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    let nullPrimaryEmail =
      this.props.filterState.filterData &&
      this.props.filterState.filterData.nullPrimaryEmail
        ? this.props.filterState.filterData.nullPrimaryEmail
        : null;
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Email Address</h4>
            <hr />
            <br />
          </>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2">
              <Col className="m-2 m-sm-2">
                <Field
                  name="Email"
                  validate={[this.validateNumber]}
                  id="Email"
                  classForm="form-control"
                  placeholder="Email"
                  label="Email"
                  component={this.renderInput}
                  labelFor="Email"
                />
              </Col>
              <Col xs="12">
                <div className="">
                  <ul class="chechbox-list">
                    <li
                      className="subheading-chks"
                      style={{ color: this.props.isMobile && "white" }}
                    >
                      Has Primary Email
                                    </li>

                    {this.props.filterState.filterData &&
                      nullPrimaryEmail &&
                      nullPrimaryEmail.map((item, index) => (
                        <Field
                          name={"NullPrimaryEmail" + item.hasPrimaryEmail}
                          data={item}
                          component={this.Checkbox}
                        />
                      ))}
                  </ul>
                </div>
              </Col>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "emailForm",
  destroyOnUnmount: false,
})(Email);

const mapStateToProps = (state) => ({
  errorMessage: {},
  filterState: state.advancedFilter,
});

export default connect(mapStateToProps, {})(formWrapped);
