import React, { Component } from "react";
import { connect } from "react-redux";
import actionFilter from "../../../../store/advancedfilter/action";
import Status from "../../../AdvanceSearch/MemberDetails/Status";
import Name from "../../../AdvanceSearch/MemberDetails/Name";
import Type from "../../../AdvanceSearch/MemberDetails/Type";
import Region from "../../../AdvanceSearch/MemberDetails/Region";
import Number from "../../../AdvanceSearch/MemberDetails/Number";
import ECI from "../../../AdvanceSearch/MemberDetails/ECI";
import Facility from "../../../AdvanceSearch/MemberDetails/Facility";
import Gender from "../../../AdvanceSearch/MemberDetails/Gender";
import Facebook from "../../../AdvanceSearch/MemberDetails/FacebookId";
import DOB from "../../../AdvanceSearch/MemberDetails/DOB";
import PAC from "../../../AdvanceSearch/MemberDetails/PAC";
import DRF from "../../../AdvanceSearch/MemberDetails/DRF";
import FinanceDues from "../../../AdvanceSearch/MemberDetails/Finance";
import ShirtSize from "../../../AdvanceSearch/MemberDetails/ShirtSize";
import FacilityRoutingSymbol from "../../../AdvanceSearch/MemberDetails/FacilityRoutingSymbol";
import OrgCode from "../../../AdvanceSearch/MemberDetails/OrgCode";
import DutyStation from "../../../AdvanceSearch/MemberDetails/DutyStation";
import Unit from "../../../AdvanceSearch/MemberDetails/Unit";
import OccupationSeries from "../../../AdvanceSearch/MemberDetails/OccupationSeries";
import CareerLevel from "../../../AdvanceSearch/MemberDetails/CareerLevel";
import PayGrade from "../../../AdvanceSearch/MemberDetails/PayGrade";
import FLSACategory from "../../../AdvanceSearch/MemberDetails/FLSACategory";
import Communication from "../../../AdvanceSearch/MemberDetails/Communication";
import Address from "../../../AdvanceSearch/MemberDetails/Address";
import Veteran from "../../../AdvanceSearch/MemberDetails/Veteran";
import RetiredMilitary from "../../../AdvanceSearch/MemberDetails/RetiredMilitary";
import HourlySalary from "../../../AdvanceSearch/MemberDetails/HourlySalary";
import AnnualSalary from "../../../AdvanceSearch/MemberDetails/AnnualSalary";
import Email from "../../../AdvanceSearch/MemberDetails/Email";
import Phone from "../../MemberDetails/Phone";
import PlannedRetirement from "../../../AdvanceSearch/MemberDetails/PlannedRetirement";
import Retirement from "../../../AdvanceSearch/MemberDetails/Retirement";
import SCD from "../../../AdvanceSearch/MemberDetails/SCD";
import EODDate from "../../../AdvanceSearch/MemberDetails/EODDate";
import NATCABUDate from "../../../AdvanceSearch/MemberDetails/NATCABUDate";
import BUDate from "../../../AdvanceSearch/MemberDetails/BUDate";
import UnionStartDate from "../../../AdvanceSearch/MemberDetails/UnionStartDate";
import UnionJoinDate from "../../../AdvanceSearch/MemberDetails/UnionJoinDate";
import TeamPosition from "../../../AdvanceSearch/MemberDetails/TeamPosition";
import AuthService from "../../../../modules/Auth/AuthService";
import PermissionFeature from "../../../../modules/Permissions/PermissionFeature";
import PositionTypes from "../../../AdvanceSearch/MemberDetails/PositionTypes";
import Teams from "../../../AdvanceSearch/MemberDetails/Teams";
import ActiveDatePosition from "../../../AdvanceSearch/MemberDetails/ActiveDatePosition";
import "react-input-range/lib/css/index.css";
import { Collapse, CardBody } from "reactstrap";
import _ from "lodash";
import Employer from "../../MemberDetails/Employer";
import FE_AdvanceFilter_NewTabs from "../../../F&E/AdvanceFilter/FE_AdvanceFilter_NewTabs";

import FE_BookVals from "../../../F&E/AdvanceFilter/NavItemsContent/FE_BookVals";
import FE_Budgets from "../../../F&E/AdvanceFilter/NavItemsContent/FE_Budgets";
import FE_Description from "../../../F&E/AdvanceFilter/NavItemsContent/FE_Description";
import FE_Statuses from "../../../F&E/AdvanceFilter/NavItemsContent/FE_Statuses";
import FE_Types from "../../../F&E/AdvanceFilter/NavItemsContent/FE_Types";
import MandatoryRetirement from "../../MemberDetails/MandatoryRetirement";

export class MobileSideBar extends Component {
  constructor(props) {
    super(props);

    this.DEBUG = process.env.NODE_ENV === "development";
    if (this.DEBUG) {
      // console.log("log permissons" + JSON.stringify(this.permissions))
    }
  }

  render() {
    const checkWidth = window.innerWidth <= 897;
    const isMobile = checkWidth; //this.props.filterState.showMobileSideBar;
    return (
      <div
        className="col parentMobileDiv sidebar-adv pr-0 pl-0"
        //style={{ maxHeight: "calc(100vh - 510px)", overflowY: "auto" }}
      >
        <div
          className={
            this.props.filterState.collaps === "1"
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.onParentAccordionClick("1")}
        >
          <a>ACCOUNT</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "1"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>

        <Collapse isOpen={this.props.filterState.collaps == "1"}>
          <CardBody>
            {AuthService.canView(PermissionFeature.MembershipMemberStatus) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "9"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("9")}
                >
                  <a>MEMBER STATUS</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "9"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "9"}>
                  <Status isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
          </CardBody>
        </Collapse>
        <div
          className={
            this.props.filterState.collaps === "2"
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.onParentAccordionClick("2")}
        >
          <a>CONTACT</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "2"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "2"}>
          <CardBody>
            {AuthService.canView(PermissionFeature.ContactEmailAddresses) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "33"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("33")}
                >
                  <a>EMAIL</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "33"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "33"}>
                  <Email isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.ContactPhones) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "51"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("51")}
                >
                  <a>PHONE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "51"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "51"}>
                  <Phone isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.ContactAddresses) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "26"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("26")}
                >
                  <a>ADDRESS</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "26"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "26"}>
                  <Address isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(
              PermissionFeature.CommunicationAllowFacebook
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "52"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("52")}
                >
                  <a>FACEBOOK</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "52"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "52"}>
                  <Facebook isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {(AuthService.canView(PermissionFeature.CommunicationAllowEmails) ||
              AuthService.canView(
                PermissionFeature.CommunicationAllowPhoneCall
              ) ||
              AuthService.canView(
                PermissionFeature.CommunicationAllowPostalMail
              ) ||
              AuthService.canView(
                PermissionFeature.CommunicationAllowTextMessages
              ) ||
              AuthService.canView(
                PermissionFeature.CommunicationAllowFacebook
              )) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "25"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("25")}
                >
                  <a>SUBSCRIPTIONS</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "25"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "25"}>
                  <Communication isMobile={isMobile} />
                </Collapse>
              </div>
            )}
          </CardBody>
        </Collapse>

        <div
          className={
            this.props.filterState.collaps === "3"
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.onParentAccordionClick("3")}
        >
          <a>DATES</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "3"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "3"}>
          <CardBody>
            {AuthService.canView(PermissionFeature.FacilityMemberSeniority) ||
            AuthService.canView(PermissionFeature.DatesEntryOnDutyDate) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "36"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("36")}
                >
                  <a>EOD DATE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "36"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "36"}>
                  <EODDate isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {(AuthService.canView(PermissionFeature.FacilityMemberSeniority) ||
              AuthService.canView(
                PermissionFeature.DatesServiceComputationDate
              )) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "35"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("35")}
                >
                  <a>SCD</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "35"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "35"}>
                  <SCD isMobile={isMobile} />
                </Collapse>
              </div>
            )}
            {AuthService.canView(PermissionFeature.FacilityMemberSeniority) ||
            AuthService.canView(PermissionFeature.DatesBargainingUnitDate) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "38"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("38")}
                >
                  <a>BU DATE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "38"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "38"}>
                  <BUDate isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}

            {AuthService.canView(PermissionFeature.FacilityMemberSeniority) ||
            AuthService.canView(
              PermissionFeature.DatesNatcaBargainingUnitDate
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "37"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("37")}
                >
                  <a>NATCA BU DATE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "37"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "37"}>
                  <NATCABUDate isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}

            {AuthService.canView(PermissionFeature.DatesJoinedUnionDate) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "39"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("39")}
                >
                  <a>UNION START DATE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "39"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "39"}>
                  <UnionStartDate isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.DatesJoinedUnionDate) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "40"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("40")}
                >
                  <a>UNION JOIN DATE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "40"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "40"}>
                  <UnionJoinDate isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.DatesRetirementDate) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "41"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("41")}
                >
                  <a>RETIREMENT</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "41"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "41"}>
                  <Retirement isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(
              PermissionFeature.DatesPlannedRetirementDate
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "34"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("34")}
                >
                  <a> PLANNED RETIREMENT</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "34"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>
                <Collapse isOpen={this.props.filterState.activeTab == "34"}>
                  <PlannedRetirement isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(
              PermissionFeature.DatesMandatoryRetirementDate
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "50"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("50")}
                >
                  <a> MANDATORY RETIREMENT</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "50"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "50"}>
                  <MandatoryRetirement isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
          </CardBody>
        </Collapse>

        <div
          className={
            this.props.filterState.collaps === "2"
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.onParentAccordionClick("4")}
        >
          <a>FINANCIAL</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "2"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "4"}>
          <CardBody>
            {AuthService.canView(PermissionFeature.FinancialHourlySalary) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "31"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("31")}
                >
                  <a>HOURLY SALARY</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "31"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "31"}>
                  <HourlySalary isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.FinancialYearlySalary) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "32"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("32")}
                >
                  <a>ANNUAL SALARY</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "32"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "32"}>
                  <AnnualSalary isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.FinancialPacDonation) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "17"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("17")}
                >
                  <a>PAC</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "17"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "17"}>
                  {!_.isEmpty(this.props.filterState.filterData) && (
                    <PAC
                      isMobile={isMobile}
                      filterData={this.props.filterState.filterData}
                    />
                  )}
                </Collapse>
              </div>
            )}
            {AuthService.canView(PermissionFeature.FinancialDrfDonation) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "18"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("18")}
                >
                  <a>DRF</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "18"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "18"}>
                  {!_.isEmpty(this.props.filterState.filterData) && (
                    <DRF
                      isMobile={isMobile}
                      filterData={this.props.filterState.filterData}
                    />
                  )}
                </Collapse>
              </div>
            )}
            {AuthService.canView(PermissionFeature.Finance) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "53"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("53")}
                >
                  <a>Finance Dues</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "53"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "53"}>
                  {!_.isEmpty(this.props.filterState.filterData) && (
                    <FinanceDues
                      isMobile={isMobile}
                      filterData={this.props.filterState.filterData}
                    />
                  )}
                </Collapse>
              </div>
            )}
          </CardBody>
        </Collapse>
        <div
          className={
            this.props.filterState.collaps === "5"
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.onParentAccordionClick("5")}
        >
          <a>JOBS</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "5"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "5"}>
          <CardBody>
            {AuthService.canView(PermissionFeature.Facility) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "8"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("8")}
                >
                  <a>FACILITY</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "8"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "8"}>
                  <Facility isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}

            {AuthService.canView(PermissionFeature.FinancialPayGrade) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "23"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("23")}
                >
                  <a>PAY GRADE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "23"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "23"}>
                  <PayGrade isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.FinancialEmployer) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "43"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("43")}
                >
                  <a>EMPLOYER</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "43"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "43"}>
                  <Employer isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.FinancialCareerLevel) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "22"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("22")}
                >
                  <a>CAREER LEVEL</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "22"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "22"}>
                  <CareerLevel isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.FinancialUnit) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "20"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("20")}
                >
                  <a>UNIT</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "20"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "20"}>
                  <Unit isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(
              PermissionFeature.FinancialFacilityAddressRoutingSymbol
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "29"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("29")}
                >
                  <a>FACILITY ROUTING SYMBOL</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "29"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "29"}>
                  <FacilityRoutingSymbol isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.PersonalInfoFlsaCategory) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "24"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("24")}
                >
                  <a>FLSA CATEGORY</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "24"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "24"}>
                  <FLSACategory isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(
              PermissionFeature.FinancialOccupationSeries
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "21"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("21")}
                >
                  <a>OCCUPATION SERIES</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "21"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "21"}>
                  <OccupationSeries isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}

            {AuthService.canView(
              PermissionFeature.FinancialOrganizationalCode
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "30"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("30")}
                >
                  <a>ORG. CODE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "30"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "30"}>
                  <OrgCode isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}

            {AuthService.canView(
              PermissionFeature.FinancialDutyStationDescription
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "19"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("19")}
                >
                  <a>DUTY STATION</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "19"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "19"}>
                  <DutyStation isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
          </CardBody>
        </Collapse>

        <div
          className={
            this.props.filterState.collaps === "6"
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.onParentAccordionClick("6")}
        >
          <a>MEMBERSHIP</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "6"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "6"}>
          <CardBody>
            {AuthService.canView(PermissionFeature.PersonalInfoMemberNumber) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "12"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("12")}
                >
                  <a> MEMBER NUMBER</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "12"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "12"}>
                  <Number isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.MembershipMemberType) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "10"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("10")}
                >
                  <a>MEMBER TYPE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "10"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "10"}>
                  <Type isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
          </CardBody>
        </Collapse>

        <div
          className={
            this.props.filterState.collaps === "7"
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.onParentAccordionClick("7")}
        >
          <a>PERSONAL INFO</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "7"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "7"}>
          <CardBody>
            {AuthService.canView(
              PermissionFeature.PersonalInfoEmployeeCommonId
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "7"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("7")}
                >
                  <a>EMPLOYEE COMMON ID</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "7"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "7"}>
                  <ECI isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}

            {AuthService.canView(PermissionFeature.PersonalInfoFirstName) ||
            AuthService.canView(PermissionFeature.PersonalInfoLastName) ||
            AuthService.canView(
              PermissionFeature.PersonalInfoPreviousLastName
            ) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "14"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("14")}
                >
                  <a>NAME</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "14"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "14"}>
                  <Name isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(PermissionFeature.PersonalInfoGender) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "16"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("16")}
                >
                  <a>GENDER</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "16"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "16"}>
                  <Gender isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}

            {AuthService.canView(PermissionFeature.PersonalInfoShirtSize) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "15"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("15")}
                >
                  <a>SHIRT SIZE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "15"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "15"}>
                  <ShirtSize isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}

            {AuthService.canView(PermissionFeature.PersonalInfoDateOfBirth) ? (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "4"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("4")}
                >
                  <a>AGE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "4"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "4"}>
                  <DOB isMobile={isMobile} />
                </Collapse>
              </div>
            ) : null}
            {AuthService.canView(
              PermissionFeature.PersonalInfoRetiredMilitaryController
            ) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "28"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("28")}
                >
                  <a>RETIRED MILLITARY</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "28"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "28"}>
                  <RetiredMilitary isMobile={isMobile} />
                </Collapse>
              </div>
            )}

            {AuthService.canView(PermissionFeature.PersonalInfoVetStatus) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "27"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("27")}
                >
                  <a>VETERAN</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "27"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "27"}>
                  <Veteran isMobile={isMobile} />
                </Collapse>
              </div>
            )}
          </CardBody>
        </Collapse>

        <div
          className={
            this.props.filterState.collaps === "8"
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.onParentAccordionClick("8")}
        >
          <a>POSITIONS</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "8"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "8"}>
          <CardBody>
            {AuthService.canView(PermissionFeature.Teams) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "54"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("54")}
                >
                  <a>TEAMS</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "54"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "54"}>
                  <Teams isMobile={isMobile} />
                </Collapse>
              </div>
            )}
            {AuthService.canView(PermissionFeature.MembersPositions) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "42"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("42")}
                >
                  <a>POSITION NAME</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "42"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "42"}>
                  <TeamPosition isMobile={isMobile} />
                </Collapse>
              </div>
            )}
            {AuthService.canView(PermissionFeature.MembersPositions) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "55"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("55")}
                >
                  <a>ACTIVE DATES</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "55"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "55"}>
                  <ActiveDatePosition isMobile={isMobile} />
                </Collapse>
              </div>
            )}
            {AuthService.canView(PermissionFeature.MembersPositions) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab === "44"
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() => this.props.onAccordionClick("44")}
                >
                  <a>POSITION TYPE</a>

                  <i
                    className={
                      this.props.filterState.activeTab === "44"
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse isOpen={this.props.filterState.activeTab == "44"}>
                  <PositionTypes isMobile={isMobile} />
                </Collapse>
              </div>
            )}
          </CardBody>
        </Collapse>

        {/* Starting FE Module Changes */}
        <div
          className={
            this.props.filterState.collaps === "9"
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.onParentAccordionClick("9")}
        >
          <a>EQUIPMENT</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "9"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "9"}>
          <CardBody>
            {AuthService.canView(PermissionFeature.FEAccountingStatus) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab ===
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() =>
                    this.props.onAccordionClick(
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS
                    )
                  }
                >
                  <a>STATUS</a>

                  <i
                    className={
                      this.props.filterState.activeTab ===
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse
                  isOpen={
                    this.props.filterState.activeTab ==
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS
                  }
                >
                  <FE_Statuses
                    isInventoryTab={true}
                    isMobile={isMobile}
                    isMemberAF={true}
                  />
                </Collapse>
              </div>
            )}
            {AuthService.canView(PermissionFeature.FEAccountingBudgetID) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab ===
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() =>
                    this.props.onAccordionClick(
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES
                    )
                  }
                >
                  <a> BUDGET LINE</a>

                  <i
                    className={
                      this.props.filterState.activeTab ===
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse
                  isOpen={
                    this.props.filterState.activeTab ==
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES
                  }
                >
                  <FE_Budgets
                    isInventoryTab={true}
                    isMemberAF={true}
                    isMobile={isMobile}
                  />
                </Collapse>
              </div>
            )}

            {AuthService.canView(PermissionFeature.FEAccountingCost) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab ===
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() =>
                    this.props.onAccordionClick(
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL
                    )
                  }
                >
                  <a> BOOK VALUE</a>

                  <i
                    className={
                      this.props.filterState.activeTab ===
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse
                  isOpen={
                    this.props.filterState.activeTab ==
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL
                  }
                >
                  <FE_BookVals
                    isMobile={isMobile}
                    isMemberAF={true}
                    formId="fe_mem_bookValsForm"
                  />
                </Collapse>
              </div>
            )}
            {AuthService.canView(PermissionFeature.FEAccountingType) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab ===
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() =>
                    this.props.onAccordionClick(
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE
                    )
                  }
                >
                  <a> TYPE</a>

                  <i
                    className={
                      this.props.filterState.activeTab ===
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse
                  isOpen={
                    this.props.filterState.activeTab ==
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE
                  }
                >
                  <FE_Types
                    isMobile={isMobile}
                    isInventoryTab={true}
                    isMemberAF={true}
                  />{" "}
                </Collapse>
              </div>
            )}
            {AuthService.canView(PermissionFeature.FEAccountingDescription) && (
              <div>
                <div
                  className={
                    this.props.filterState.activeTab ===
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() =>
                    this.props.onAccordionClick(
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC
                    )
                  }
                >
                  <a> DESCRIPTION</a>

                  <i
                    className={
                      this.props.filterState.activeTab ===
                      FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  ></i>
                </div>

                <Collapse
                  isOpen={
                    this.props.filterState.activeTab ==
                    FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC
                  }
                >
                  <FE_Description
                    formId="fe_mem_descriptionForm"
                    isInventoryTab={true}
                    isMemberAF={true}
                    isMobile={isMobile}
                  />
                </Collapse>
              </div>
            )}
          </CardBody>
        </Collapse>
      </div>
    );
  }
}
export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(MobileSideBar);
