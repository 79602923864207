import AUX from "../../hoc/Aux_";
import React, { Component, Fragment } from "react";
import { MDBInput } from "mdbreact";
import axios from "../Shared/auth-header";
import AuthService from "../Auth/AuthService";
import $ from "jquery";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

class ForcedResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CurrentPassword: "",
            Password: "",
            RePassword: "",
            Password_err: "",
            RePassword_err: "",
            CurPassword_err: "",
            valid: false,
            message: "",
            status: false,
            key:null
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChangePwd = this.handleChangePwd.bind(this);
    }
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    async handleFormSubmit(e) {
        e.preventDefault();

        if (this.state.Password === "" || this.state.Password_err != "") {
            this.setState({ Password_err: "Fix the Error" });
            this.setState({ valid: false });
            return false;
        }
        if (this.state.RePassword === "" || this.state.RePassword_err != "") {
            this.setState({ RePassword_err: "Fix the Error" });
            this.setState({ valid: false });
            return false;
        }
        if (this.state.valid) {
            $(".loading").show();
            const obj = {
                Key: this.state.key,
                    OldPassword: this.state.CurrentPassword,
                    NewPassword: this.state.Password,
                };
                try {
                    var { data } = await axios.post("/api/Account/ForcedResetPassword", obj);
                    if (data.status && data.status.toLowerCase() == "success") {
                        this.setState({ status: true });
                        toast.success("Password changed successfully.Please login with your new password");
                        this.setState({ Password: "" });
                        this.setState({ CurrentPassword: "" });
                        this.setState({ RePassword: "" });
                        AuthService.logoutAuthO();
                        
                    }
                    else if (data.status && data.status.toLowerCase() == "sessionend") {
                        AuthService.logoutAuthO();
                        $(".loading").hide();
                    }
                    else {
                        toast.error(data.message );
                        $(".loading").hide();
                    }
                } catch (error) {
                    console.log(error);
                    $(".loading").hide();
                }
                
                        //$(".loading").hide();
        }
    }
    handleChangePwd(e) {
        this.setState({ [e.target.name]: e.target.value });
        let passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        if (e.target.value === "") {
            this.setState({ [`${e.target.name}_err`]: "Required Field" });
            this.setState({ valid: false });
        } else {
            if (e.target.name == "Password") {
                if (!passRegex.test(e.target.value)) {
                    this.setState({
                        Password_err:
                            "Password must be 8 characters long and must contain 1 uppercase, 1 lower case and 1 number.",
                    });
                    this.setState({ valid: false });
                } else {
                    if (this.state.RePassword != e.target.value) {
                        this.setState({
                            RePassword_err: "Password should be the same.",
                            Password_err: "",
                        });
                        this.setState({ valid: false });
                    } else {
                        this.setState({ RePassword_err: "", Password_err: "" });
                        this.setState({ valid: true });
                    }
                }
            } else {
                if (this.state.Password != e.target.value) {
                    this.setState({ RePassword_err: "Password should be the same." });
                    this.setState({ valid: false });
                } else {
                    this.setState({ RePassword_err: "" });
                    this.setState({ valid: true });
                }
            }
        }
    }
    componentDidMount() {
        
        ///Pending Registration Scenario
        if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.forcedReset
        ) {
            this.setState({ key: this.props.location.state.forcedReset });
        }
        else {
            AuthService.logoutAuthO();

        }
    }
     render() {
        return (
            <AUX>
                <div className="wrapper-page">
                    <div
                        className="card overflow-hidden account-card mx-3"
                        
                    >
                        <div className="bg-primary ptb-login text-white text-center position-relative">
                            <span className="logo logo-admin">
                                <img
                                    src="assets/images/natca-logo.png"
                                    height="142"
                                    alt="logo"
                                />
                            </span>
                        </div>
                        <div className="account-card-content m-t-40">
                            <div className="col-16 m-t-20 text-center font-weight-bold">
                                <h5>Create a New Password</h5>

                                    <small>
                                        Enter your new account password below. Passwords must be at
                                        least eight (8) characters utilizing at least one uppercase,
                                        lowercase, and number.
                                    </small>
                            </div>
                                    <Fragment>
                                        <form
                                            className="resetForm form-horizontal m-t-40"
                                            onSubmit={this.handleFormSubmit}
                                        >
                                                <div className="form-group">
                                                    <MDBInput
                                                        name="CurrentPassword"
                                                        value={this.state.CurrentPassword}
                                                        onChange={this.changeHandler}
                                                        label="Current Password"
                                                        group
                                                        type="password"
                                                    >
                                                        <span className="text-danger">
                                                            {this.state.CurPassword_err}
                                                        </span>
                                                    </MDBInput>
                                                </div>
                                            <div className="form-group">
                                                <MDBInput
                                                    name="Password"
                                                    value={this.state.Password}
                                                    onChange={this.handleChangePwd}
                                                    label="New Password"
                                                    group
                                                    type="password"
                                                >
                                                    <span className="text-danger">{this.state.Password_err}</span>
                                                </MDBInput>
                                            </div>
                                            <div className="form-group">
                                                <MDBInput
                                                    name="RePassword"
                                                    value={this.state.RePassword}
                                                    onChange={this.handleChangePwd}
                                                    label="Confirm New Password"
                                                    group
                                                    type="password"
                                                >
                                                    <span className="text-danger">{this.state.RePassword_err}</span>
                                                </MDBInput>
                                            </div>
                                            <div className="form-group  mb-4 row">
                                                <div className="col-12 mx-auto text-center">
                                                    <button
                                                        disabled={!this.state.valid}
                                                        className="btn btn-primary btn-block w-md waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Save Password
                                            </button>

                                            <div className="col-12 text-center mt-3 font-weight-lighter">
                                                <span
                                                    onClick={() => {
                                                        AuthService.logoutAuthO();
                                                    }}
                                                    className="font-500 c-pointer text-primary text-right"
                                                >
                                                    Go to Login
                                                </span>
                                            </div>
                                        </div>

                                            </div>
                                        </form>
                                    </Fragment>

                            <div className="col-12 m-t-20 text-left font-weight-bold">
                                By registering for an account with NATCA you agree to our
                                <a
                                    className="font-500 text-primary text-right"
                                    href="https://www.natca.org/privacy-policy/"
                                    target="_blank"
                                >
                                    Terms & Conditions.
                                </a>
                            </div>

                            <div className="col-12 m-t-5 text-left font-weight-bold">
                                Need help?
                            </div>
                            <div className="col-12 m-t-5 text-left font-weight-lighter">
                                Other issues?{" "}
                                <a target="_blank" href={`mailto:support@natca.org`}>
                                    Email Us
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="m-t-40 text-center">
                        <p>� {new Date().getFullYear()} NATCA</p>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default ForcedResetPassword;
