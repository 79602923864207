import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import AuthService from "../../Auth/AuthService";
import Scope from "../Scope/Scope";
import _ from "lodash";
import { toast } from "react-toastify";
import "../../../assets/css/TeamStyle.css";
import RolesModal from "../../Shared/RolesModal";
import { Link } from "react-router-dom";
import Services from "../PermissionService";
import InfiniteScroll from "react-infinite-scroll-component";
import { isValid } from "redux-form";
import LinkedPositionContent from "../General/LinkedPositionContent";
import ConfirmLinkedPositionModal from "../General/ConfirmLinkedPositionModal";
import Permissions from "./Permissions";
import PermissionAlgo from "../General/PermissionAlgo";
const ButtonToNavigate = ({ title, history, color, classes }) => (
  <Button
    type="button"
    onClick={() => history.push({ pathname: "/admin/positions" })}
    color={color}
    className={classes}
  >
    {title}
  </Button>
);

const modalHeadings = {
  Orignal: "Create New Team",
  Permissions: "Configure Permissions",
  Member: "Assign Member",
};
class TeamPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teamName: "",
      type: this.props.type,
      parentTeamId: this.props.parentTeamId,
      permissionTeamId: this.props.permissionTeamId,
      applyChild: false,
    };

    this.cloneTeamPermission = this.cloneTeamPermission.bind(this);
    this.buttonLoader = this.buttonLoader.bind(this);

    this.teamPermissions = this.teamPermissions.bind(this);
    this.verifyData = this.verifyData.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.cancelButtonBottom = this.cancelButtonBottom.bind(this);
    this.cancelButtonLoader = this.cancelButtonLoader.bind(this);
    this.savePermissionData = this.savePermissionData.bind(this);
    this.handleSaveTeamPermission = this.handleSaveTeamPermission.bind(this);
    this.handleResetTeamPermission = this.handleResetTeamPermission.bind(this);
    this.handleChangeTeamPermission = this.handleChangeTeamPermission.bind(
      this
    );
    this.handleChangeApplyTeam = this.handleChangeApplyTeam.bind(this);
    this.handleConfigurePermissionSection = this.handleConfigurePermissionSection.bind(
      this
    );
  }

  ConvertPermissionToNewScheme(permissions) {
    let originalPermissions = [];
    const createPermissions = (permissions) => {
      permissions.map((item) => {
        originalPermissions.push(Object.assign({}, item));
        if (item.childs) {
          createPermissions(item.childs);
        }
      });
    };
    createPermissions(permissions);
    return originalPermissions;
  }

  cloneTeamPermission = (originalPermissions) =>
    originalPermissions.map((item) =>
      (({ isView, isAdd, isUpdate, isDelete, id }) => {
        return {
          id: item.teamPermissionID,
          permissionFeatureId: item.id,
          isView,
          isAdd,
          isUpdate,
          isDelete,
        };
      })(item)
    );

  handleCancelTeamPermission(e) {
    //e.preventdefault();
    this.handleResetTeamPermission();
    window.$("#TeamPermission").modal("hide");
  }

  handleResetTeamPermission(e) {
    //e.preventdefault();
    if (this.state.type == "childteam") {
      this.state.teamPermissions = this.cloneTeamPermission(
        this.state.teamPermissionsData
      );
    } else {
      this.state.teamPermissions = this.cloneTeamPermission(
        this.state.originalPermissions
      );
    }

    this.setState({
      teamPermissions: this.state.teamPermissions,
    });
  }

  handleChangeTeamPermission(e) {
    var permissions = this.state.teamPermissions;
    this.setState({
      teamPermissions: PermissionAlgo.changeChild(permissions, e),
    });
  }
  handleChangeApplyTeam(e) {
    var checked = e.target.checked;
    this.setState({ applyChild: checked });
  }

  performSlide(parentid) {
    let element = $(".slide-collapsers[data-slide-Id='slide" + parentid + "']");
    let expand = element.attr("data-slide-expand");

    const collapseChild = (parentid, expand) => {
      let element = $(".slide-collapsers[data-slide-Id='" + parentid + "']");

      if (expand) {
        $(".slide-collapsers[data-slide-parentId='" + parentid + "']").addClass(
          "perm-row-hide"
        );
      } else {
        let expandChild = element.attr("data-slide-expand");

        if (expandChild == "true") {
          $(
            ".slide-collapsers[data-slide-parentId='" + parentid + "']"
          ).removeClass("perm-row-hide");
        }
        //in case false
        else {
          expand = true;
        }
      }
      $(".slide-collapsers[data-slide-parentId='" + parentid + "']").each(
        function(i) {
          let parentid2 = $(this).attr("data-slide-Id");
          if (
            $(".slide-collapsers[data-slide-parentId='" + parentid2 + "']")
              .length > 0
          ) {
            collapseChild(parentid2, expand);
          }
        }
      );
    };

    if (expand == "true") {
      $(
        ".slide-collapsers[data-slide-parentId='slide" + parentid + "']"
      ).addClass("perm-row-hide");
      element.attr("data-slide-expand", false);
    } else {
      $(
        ".slide-collapsers[data-slide-parentId='slide" + parentid + "']"
      ).removeClass("perm-row-hide");
      element.attr("data-slide-expand", true);
    }
    collapseChild("slide" + parentid, expand == "true" ? true : false);
  }

  handleSaveTeamPermission = async (e) => {
    $(".loading").show();
    if (this.validateTeamPermissions) {
      let data = await Services.EditTeamPermission({
        teamID: this.state.permissionTeamId,
        permissions: this.state.teamPermissions,
        applyChild: this.state.applyChild,
      });
      if (!_.isEmpty(data)) {
        if (data.statusCode == 200) {
          toast.success(data.message);
          this.props.BulkProcessStart();
          if (data.message == "Team permission edited successfully!") {
            this.props.setReloadPosition();
            await this.props.ReloadActivityLogging();
          }

          this.handleConfigurePermissionSection();
          window.$("#TeamPermission").modal("hide");
        }
      }
    }

    $(".loading").hide();
  };

  sectionLoader = () => {
    if (!_.isEmpty(this.state.teamPermissions)) {
      return this.teamPermissions();
    }
  };

  cancelModal = (e) => {
    this.handleCancelTeamPermission();
  };
  verifyData = (e) => {
    this.verifyPermissions();
  };
  buttonLoader = () => {
    return this.savePermissionButton();
  };

  cancelButtonLoader = () => {
    return (
      <button type="button" class="close text-white" onClick={this.cancelModal}>
        <span aria-hidden="true">&times;</span>
      </button>
    );
  };

  handleConfigurePermissionSection = async (e) => {
    $(".loading").show();

    let data =
      this.state.type === "childteam"
        ? await Services.TeamPermissions(this.state.parentTeamId)
        : await Services.TeamPermissions(this.state.permissionTeamId);
    if (!_.isEmpty(data)) {
      this.state.originalPermissions = this.ConvertPermissionToNewScheme(
        data.result
      );
      await this.setState({
        originalPermissions: this.state.originalPermissions,
        applyChild: false,
      });
    }
    if (this.state.type === "childteam") {
      let dataChild = await Services.TeamPermissions(
        this.state.permissionTeamId
      );
      if (!_.isEmpty(dataChild)) {
        this.state.teamPermissionsData = this.ConvertPermissionToNewScheme(
          dataChild.result
        );
        await this.setState({
          teamPermissionsData: this.state.teamPermissionsData,
        });
      }
    }
    this.handleResetTeamPermission();

    $(".loading").hide();
  };

  teamPermissions = () => {
    return (
      <>
        <h6 className="mt-0 font-weight-normal font-italic">
          <small>
            All the permissions that change to Turn OFF will also Turn OFF for
            the positions, child teams and child teams positions. However, If
            you change the permissions to Turn ON will not Turn ON for the child
            teams and child teams positions until and unless{" "}
            <b className="font-weight-bold">Apply to Child Teams</b> checkbox is
            not checked
          </small>
        </h6>
        <Permissions
          prefix={"TP-"}
          permissionFeature={this.state.originalPermissions}
          permissions={this.state.teamPermissions}
          originalPermissions={this.state.originalPermissions}
          uncheckClass=" uncheck-light"
          handleChangePermission={this.handleChangeTeamPermission}
          hasParent={this.state.type === "childteam"}
          listId="teamPermission"
        />
      </>
    );
  };

  cancelButtonBottom = () => {
    return (
      <button
        className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
        type="button"
        onClick={this.cancelModal}
      >
        Cancel
      </button>
    );
  };

  savePermissionButton = () => {
    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-4">
            {!_.isEmpty(this.state.teamPermissions) && (
              <div className="w-100 position-relative pl-4">
                <input
                  type="checkbox"
                  style={{ bottom: "5px", left: "27px" }}
                  checked={this.state.applyChild}
                  title="It will change the 'Turn ON' permissions of the child teams and their positions."
                  onChange={this.handleChangeApplyTeam}
                  className="form-check-input"
                  id="ApplyChild"
                />
                <label
                  className="form-check-label font-italic text-secondary"
                  for="ApplyChild"
                >
                  Apply to Child Teams
                </label>
              </div>
            )}
          </div>
          <div className="col-8">
            <div className="text-right">
              {this.cancelButtonBottom()}
              <button
                onClick={this.savePermissionData}
                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  validateTeamPermissions() {
    let teamPermissions = this.state.teamPermissions;
    let ValidData = teamPermissions.findIndex(
      (el) =>
        el.isAdd == true ||
        el.isDelete == true ||
        el.isUpdate == true ||
        el.isView == true
    );
    if (ValidData > -1) {
      return true;
    } else {
      toast.error("Please set appropriate permission.");
      return false;
    }
  }

  savePermissionData = (e) => {
    this.handleSaveTeamPermission();
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.permissionTeamId != nextProps.permissionTeamId) {
      this.state.permissionTeamId = nextProps.permissionTeamId;
      this.state.type = nextProps.type;
      this.state.parentTeamId = nextProps.parentTeamId;
      this.setState({
        permissionTeamId: this.state.permissionTeamId,
        type: this.state.type,
        parentTeamId: this.state.parentTeamId,
      });
      this.handleConfigurePermissionSection();
    }
  }
  componentDidMount() {
    // this.GetPermissions();
    if (this.props.permissionTeamId) {
      this.handleConfigurePermissionSection();
    }

    window.onunload = function() {
      $('[data-toggle="tooltipApplyChild"]').tooltip();
    };
  }

  render() {
    return (
      <AUX>
        <div
          class="modal fade"
          id="TeamPermission"
          tabindex="-1"
          role="dialog"
          aria-labelledby="TeamPermissionTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5
                  class="modal-title text-uppercase text-white mt-0"
                  id="TeamPermissionTitle"
                >
                  TEAM PERMISSIONS
                </h5>
                {this.cancelButtonLoader()}
              </div>

              <div className="modal-body">
                <div>
                  {this.sectionLoader()}

                  {this.buttonLoader()}
                </div>
                {/* <RolesModal rolesMethods={this.rolesMethods} />*/}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default TeamPermission;
