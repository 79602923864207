import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import Services from "../PermissionService";
import TableCustomTotal from "../../Shared/TableCustomTotal";

class MyTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: "",
      page: 1,
      sizePerPageMyTeam: 10,
      keyword: this.props.location.keyword || "",
      data: [],
      totalCount: "",
    };

    this.columns = [
      {
        text: "Team Name",
        dataField: "teamName",
        sort: true,
        classes: "c-pointer",
      },
      {
        text: "Positions",
        isDummyField: true,
        classes: "c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <div>
              {row.positionName} {row.isOwner && <b> (OWNER)</b>}
            </div>
          );
        },
      },
    ];
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.history.push({
          pathname: "/permissions/teams/teamDetail/" + row.id,
          type: "add",
          uid: row.id,
          keyword: $("#keyword").val(),
        });
      },
    };
    this.RemotePagination = this.RemotePagination.bind(this);
    this.myTeamSizePerPageChange = this.myTeamSizePerPageChange.bind(this);
  }
  myTeamSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPageMyTeam: sizePerPage });
  };
  RemotePagination = (
    data,

    sizePerPage,
    SizePerPageChange,
    rowEvents
  ) => {
    let paginationOptions = {
      totalSize: data && data.length,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      paginationTotalRenderer: TableCustomTotal,
      //sizePerPage: 10,
      //hidePageListOnlyOnePage: true
    };
    return (
      <div>
        <BootstrapTable
          remote
          keyField="id"
          data={data}
          columns={this.columns}
          className="table"
          pagination={
            data.length > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          rowEvents={rowEvents}
        />
      </div>
    );
  };
  async getTeams() {
    $(".loading").show();
    let data = await Services.MyTeam();
    if (!_.isEmpty(data)) {
      this.setState({
        data: data.result,
      });
    }
    $(".loading").hide();
  }
  componentDidMount() {
    this.props.history.push("/myTeams");
    this.getTeams();
  }

  componentWillUnmount() {}
  render() {
    // New Work
    const { data, sizePerPage, page, totalCount } = this.state;
    const showTotal = true;
    return (
      <AUX>
        <div className="container-fluid">
          <div class="page-title-box">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <h4 class="text-uppercase page-title-search">My Teams</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {this.RemotePagination(
                data,

                this.state.sizePerPageMyTeam,

                this.myTeamSizePerPageChange,
                this.rowEvents
              )}
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default MyTeams;
