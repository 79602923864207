import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Spinner } from "reactstrap";

import actionFilter from "../../../../store/advancedfilter/action";
import Status from "../../../AdvanceSearch/MemberDetails/Status";
import ActiveDatePosition from "../../../AdvanceSearch/MemberDetails/ActiveDatePosition";
import PositionTypes from "../../../AdvanceSearch/MemberDetails/PositionTypes";
import Teams from "../../../AdvanceSearch/MemberDetails/Teams";
import Name from "../../../AdvanceSearch/MemberDetails/Name";
import Type from "../../../AdvanceSearch/MemberDetails/Type";
import Region from "../../../AdvanceSearch/MemberDetails/Region";
import FinanceDues from "../../../AdvanceSearch/MemberDetails/Finance";
import Number from "../../../AdvanceSearch/MemberDetails/Number";
import ECI from "../../../AdvanceSearch/MemberDetails/ECI";
import Facility from "../../../AdvanceSearch/MemberDetails/Facility";
import Gender from "../../../AdvanceSearch/MemberDetails/Gender";
import DOB from "../../../AdvanceSearch/MemberDetails/DOB";
import ShirtSize from "../../../AdvanceSearch/MemberDetails/ShirtSize";
import PAC from "../../../AdvanceSearch/MemberDetails/PAC";
import DRF from "../../../AdvanceSearch/MemberDetails/DRF";
import FacilityRoutingSymbol from "../../../AdvanceSearch/MemberDetails/FacilityRoutingSymbol";
import OrgCode from "../../../AdvanceSearch/MemberDetails/OrgCode";
import DutyStation from "../../../AdvanceSearch/MemberDetails/DutyStation";
import Unit from "../../../AdvanceSearch/MemberDetails/Unit";
import OccupationSeries from "../../../AdvanceSearch/MemberDetails/OccupationSeries";
import CareerLevel from "../../../AdvanceSearch/MemberDetails/CareerLevel";
import PayGrade from "../../../AdvanceSearch/MemberDetails/PayGrade";
import FLSACategory from "../../../AdvanceSearch/MemberDetails/FLSACategory";
import Communication from "../../../AdvanceSearch/MemberDetails/Communication";
import Address from "../../../AdvanceSearch/MemberDetails/Address";
import Veteran from "../../../AdvanceSearch/MemberDetails/Veteran";
import RetiredMilitary from "../../../AdvanceSearch/MemberDetails/RetiredMilitary";
import HourlySalary from "../../../AdvanceSearch/MemberDetails/HourlySalary";
import AnnualSalary from "../../../AdvanceSearch/MemberDetails/AnnualSalary";
import Email from "../../../AdvanceSearch/MemberDetails/Email";
import PlannedRetirement from "../../../AdvanceSearch/MemberDetails/PlannedRetirement";
import Retirement from "../../../AdvanceSearch/MemberDetails/Retirement";
import SCD from "../../../AdvanceSearch/MemberDetails/SCD";
import EODDate from "../../../AdvanceSearch/MemberDetails/EODDate";
import NATCABUDate from "../../../AdvanceSearch/MemberDetails/NATCABUDate";
import BUDate from "../../../AdvanceSearch/MemberDetails/BUDate";
import UnionStartDate from "../../../AdvanceSearch/MemberDetails/UnionStartDate";
import UnionJoinDate from "../../../AdvanceSearch/MemberDetails/UnionJoinDate";
import TeamPosition from "../../../AdvanceSearch/MemberDetails/TeamPosition";
import Employer from "../../../AdvanceSearch/MemberDetails/Employer";
import "react-input-range/lib/css/index.css";
import Tags from "../Tags";
import TagsActionButtons from "../TagsButtons";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import SelectedFilter from "../SelectedFilter";
import FE_Budgets from "../../../F&E/AdvanceFilter/NavItemsContent/FE_Budgets";
import FE_Description from "../../../F&E/AdvanceFilter/NavItemsContent/FE_Description";
import FE_Statuses from "../../../F&E/AdvanceFilter/NavItemsContent/FE_Statuses";
import FE_Types from "../../../F&E/AdvanceFilter/NavItemsContent/FE_Types";
import FE_AdvanceFilter_NewTabs from "../../../F&E/AdvanceFilter/FE_AdvanceFilter_NewTabs";
import FE_BookVals from "../../../F&E/AdvanceFilter/NavItemsContent/FE_BookVals";
import MandatoryRetirement from "../../MemberDetails/MandatoryRetirement";
import Phone from "../../MemberDetails/Phone";
import FacebookId from "../../../AdvanceSearch/MemberDetails/FacebookId";

export class DeskTopTabContents extends Component {
  render() {
    return (
      <Col xs="9" sm="9" md="9" xl="9" className="pr-0">
        <TabContent activeTab={this.props.filterState.activeTab}>
          <TabPane tabId="4">
            <DOB />
          </TabPane>
          <TabPane tabId="7">
            <ECI />
          </TabPane>
          <TabPane tabId="8">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "8" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Facility />
            )}
          </TabPane>
          <TabPane tabId="9">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "9" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Status />
            )}
          </TabPane>
          <TabPane tabId="10">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "10" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Type />
            )}
          </TabPane>
          <TabPane tabId="11">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "11" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Region />
            )}
          </TabPane>
          <TabPane tabId="12">
            <Number />
          </TabPane>
          <TabPane tabId="14">
            <Name />
          </TabPane>
          <TabPane tabId="15">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "15" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <ShirtSize />
            )}
          </TabPane>
          <TabPane tabId="29">
            <FacilityRoutingSymbol />
          </TabPane>
          <TabPane tabId="30">
            <OrgCode />
          </TabPane>
          <TabPane tabId="19">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "19" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <DutyStation />
            )}
          </TabPane>
          <TabPane tabId="20">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "20" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Unit />
            )}
          </TabPane>
          <TabPane tabId="21">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "21" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <OccupationSeries />
            )}
          </TabPane>
          <TabPane tabId="43">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "43" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Employer />
            )}
          </TabPane>
          <TabPane tabId="22">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "22" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <CareerLevel />
            )}
          </TabPane>
          <TabPane tabId="23">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "23" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <PayGrade />
            )}
          </TabPane>
          <TabPane tabId="24">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "24" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <FLSACategory />
            )}
          </TabPane>
          <TabPane tabId="31">
            <HourlySalary />
          </TabPane>
          <TabPane tabId="32">
            <AnnualSalary />
          </TabPane>
          <TabPane tabId="16">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "16" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Gender />
            )}
          </TabPane>
          <TabPane tabId="17">
            {!_.isEmpty(this.props.filterState.filterData) && (
              <PAC filterData={this.props.filterState.filterData} />
            )}
          </TabPane>
          <TabPane tabId="18">
            {!_.isEmpty(this.props.filterState.filterData) && (
              <DRF filterData={this.props.filterState.filterData} />
            )}
          </TabPane>

          <TabPane tabId="53">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "53" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <FinanceDues />
            )}
          </TabPane>
          <TabPane tabId="25">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "25" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Communication />
            )}
          </TabPane>
          <TabPane tabId="26">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "26" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Address />
            )}
          </TabPane>
          <TabPane tabId="33">
            <Email />
          </TabPane>
          <TabPane tabId="52">
            <FacebookId />
          </TabPane>
          <TabPane tabId="51">
            <Phone />
          </TabPane>
          <TabPane tabId="27">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "27" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Veteran />
            )}
          </TabPane>
          <TabPane tabId="28">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "28" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <RetiredMilitary />
            )}
          </TabPane>
          <TabPane tabId="34">
            <PlannedRetirement />
          </TabPane>
          <TabPane tabId="50">
            <MandatoryRetirement />
          </TabPane>
          <TabPane tabId="41">
            <Retirement />
          </TabPane>
          <TabPane tabId="35">
            <SCD />
          </TabPane>
          <TabPane tabId="36">
            <EODDate />
          </TabPane>
          <TabPane tabId="37">
            <NATCABUDate />
          </TabPane>
          <TabPane tabId="38">
            <BUDate />
          </TabPane>
          <TabPane tabId="39">
            <UnionStartDate />
          </TabPane>
          <TabPane tabId="40">
            <UnionJoinDate />
          </TabPane>
          <TabPane tabId="42">
            <TeamPosition />
          </TabPane>
          {/* ########Member Advance Filter Tabs for Equipment Accordion######### */}
          {/* #######################Equipment Module Tabs in Members AF###########################*/}
          <TabPane tabId={FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS}>
            <FE_Statuses isInventoryTab={true} isMemberAF={true} />
          </TabPane>
          <TabPane tabId={FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES}>
            <FE_Budgets isInventoryTab={true} isMemberAF={true} />
          </TabPane>
          <TabPane tabId={FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL}>
            <FE_BookVals isMemberAF={true} formId="fe_mem_bookValsForm" />
          </TabPane>
          <TabPane tabId={FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE}>
            <FE_Types isInventoryTab={true} isMemberAF={true} />
          </TabPane>
          <TabPane tabId={FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC}>
            <FE_Description formId="fe_mem_descriptionForm" />
          </TabPane>
          {/*################### Equipment Module Tabs END################################## */}
          <TabPane tabId="44">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "44" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <PositionTypes />
            )}
          </TabPane>
          <TabPane tabId="54">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "54" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <Teams />
            )}
          </TabPane>
          <TabPane tabId="55">
            {this.props.filterState.loadingAdvanceFilter &&
            this.props.filterState.currentActiveTab !== "55" ? (
              <Spinner className="spinnerPosition" />
            ) : (
              <ActiveDatePosition />
            )}
          </TabPane>
        </TabContent>
        <div style={{ height: "95px" }}></div>
        <div className="bottom-stick">
          <Row>
            <Col className="apply-filter-selection pl-0">
              <Row>
                <Col md={6} sm={6} xs={6} lg={8} xl={9} className="pr-0">
                  <TagsActionButtons
                    dontAllowEditFilter={this.props.dontAllowEditFilter}
                  />
                </Col>
                <Col md={6} sm={6} xs={6} lg={4} xl={3} className="pl-0">
                  <SelectedFilter
                    dontAllowEditFilter={this.props.dontAllowEditFilter}
                  />
                </Col>
              </Row>
              <Row></Row>
            </Col>
          </Row>
          <Row className="setTagsPadding">
            {/* Tags for advance filter */}
            <Col className="filtersBorder p-2 tagsScroll">
              <Tags dontAllowEditFilter={this.props.dontAllowEditFilter} />
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
}

export default connect(
  (state) => ({ filterState: state.advancedFilter, formValues: state.form }),
  {
    ...actionFilter,
  }
)(DeskTopTabContents);
