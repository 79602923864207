import React, { Component } from "react";
import { connect } from "react-redux";
import "moment/locale/en-ca";
import "rc-datepicker/lib/style.css";
import $ from "jquery";
import _ from "lodash";
import "./grievances.css";
import moment from "moment";
import { withRouter } from "react-router-dom";
import StepElevation from "../Grievances/GrievanceSteps/StepElevation";
import StepMeeting from "../Grievances/GrievanceSteps/StepMeeting";
import StepResponse from "../Grievances/GrievanceSteps/StepResponse";
import Services from "./GrievanceService";
import ActivityActions from "../../store/activitylogging/action";
import GrievanceAttachments from "./GrievanceAttachments";
import GrievanceComments from "./GrievanceComments";
import GrievanceAction from "../../store/grievance/action";
import GrievanceHeader from "./General/GrievanceHeader";
import AuthService from "../Auth/AuthService";
import { Collapse } from "reactstrap";
import { toast } from "react-toastify";
import FileEditModal from "./FileEditModal";
import GrievanceActivityLogging from "../Grievances/General/GrievanceActivityLogging";
import { PdfDocument } from "../Grievances/General/GrievanceStepPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import FileSaver from "file-saver";

class GrievanceDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: props.match.params.Id,
      newStep: {},
      grievanceStatus: null,
      currentStatePdfData: {},
      stepsData: [],
      grievantId: null,
      ActiveTab: 2,
      fileItems: [],
      files: [],
      filesTable: [],
      shouldReloadAttachments: this.props.grievanceState.isReloadAttachments,
      representativesList: [],
      recipientsList: [],
      articlesList: [],
      recipient: "",
      article: "",
      representative: "",
      subject: "",
      dateSubmitted: new Date().toLocaleDateString(),
      meetingDate: moment(new Date()).format("MM/DD/YYYY"),
      grievanceGeneratedId: "",
      grievantName: "",
      isAdverse: false,
      canEdit: false,
      representativeName: "",
      recipientName: "",
      step1Active: true,
      reloadLogs: false,
      isEditMode: true,
      selectedFile: null,
      grievantValue: "",
      showFileEditModal: false,
      editOriginalFileName: "",
      editFileName: "",
      isLastStepElevated: false,
      responseDate: moment(new Date()).format("MM/DD/YYYY"),
      editReference: {},
      currentStep: "Step 1",
      stepsList: [
        { id: 1, name: "Step 1" },
        { id: 2, name: "Step1 Meeting" },
        { id: 3, name: "Step1 Response" },
        { id: 4, name: "Step 2" },
        { id: 5, name: "Step2 Meeting" },
        { id: 6, name: "Step2 Response" },
        { id: 7, name: "PAR" },
        { id: 8, name: "PAR Meeting" },
        { id: 9, name: "PAR Response" },
        { id: 10, name: "GRT" },
        { id: 11, name: "Arbitration" },
        { id: 12, name: "Arbitration Meeting" },
        { id: 13, name: "Arbitration Response" },
        { id: 14, name: "Expedited" },
        { id: 15, name: "Expedited Arbitration Hearing" },
        { id: 15, name: "Expedited Arbitration Response" },
      ],
      commentsData: [],
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.loadGrievanceData = this.loadGrievanceData.bind(this);
    this.getNextStepName = this.getNextStepName.bind(this);
    this.getNextStepType = this.getNextStepType.bind(this);
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.rerenderParentCallback = this.rerenderParentCallback.bind(this);
    this.reloadLogs = this.reloadLogs.bind(this);
    this.getLastStepType = () => {
      const stepsLength = this.state.stepsData.length;
      if (stepsLength > 0) {
        const lastStep = this.state.stepsData[stepsLength - 1];
        if (lastStep.stepName == "GRT") return "Elevation";
        if (lastStep.stepType == "Elevation" && !lastStep.isMeeting)
          return "Response";
        if (lastStep.stepType == "Elevation") return "Meeting";
        if (lastStep.stepType == "Meeting") return "Response";
        if (lastStep.stepType == "Response") return "Elevation";
      }
    };
    this.getLastStepElevation = () => {
      const stepsLength = this.state.stepsData.length;
      if (stepsLength > 0) {
        const lastStep = this.state.stepsData[stepsLength - 1];
        if (lastStep.isElevated) return true;
        else return false;
      }
    };
    this.deleteFile = (filename) => {
      var currentFile = _.forEach(this.state.file, (item) => {
        return item.filename == filename;
      });
      var newFileArray = this.state.files.filter((item) => {
        return item.filename !== filename;
      });
      var newFileTableArray = this.state.filesTable.filter((item) => {
        return item.filename !== filename;
      });
      Services.DeleteAttachment(filename).then((response) => {
        this.setState({
          files: newFileArray,
          filesTable: newFileTableArray,
        });
      });
    };
    this.openFileEditModal = (originalName, name, reference, stepName) => {
      var refObj = this.state.stepsList.filter((i) => i.name === stepName);
      this.setState({
        editOriginalFileName: originalName,
        editFileName: name,
        editReference: refObj[0],
        showFileEditModal: true,
      });
    };
    this.closeFileEditModal = () => {
      this.setState({
        editOriginalFileName: "",
        editFileName: "",
        editReference: {},
        showFileEditModal: false,
      });
    };
    this.updateFiles = (fileItem) => {
      var stateFilesTable = this.state.filesTable;
      var currentFile = {
        Uploaded: fileItem.Uploaded,
        filename: fileItem.filename,
        reference: fileItem.reference,
        stepName: fileItem.stepEnumId,
        originalfilename: fileItem.originalfilename,
        fileDownloadLink: fileItem.fileDownloadLink,
      };
      stateFilesTable.push(currentFile);
      this.setState({ filesTable: stateFilesTable });
    };
    this.updateFileData = (dataObj) => {
      let dataToSave = {};
      var filesdata = this.state.filesTable.map((i) => {
        if (i.filename === dataObj.filename) {
          dataToSave.previousFileName = i.filename;
          dataToSave.originalfilename = dataObj.originalfilename;
          dataToSave.reference = dataObj.reference.name;
          i.originalfilename = dataObj.originalfilename;
          i.reference = dataObj.reference.name;
        }
        return i;
      });
      filesdata = filesdata.filter((n) => n.originalfilename);
      _.forEach(filesdata, (file) => {
        if (file.filename == dataObj.filename)
          file.stepName = dataObj.reference.name;
      });
      Services.GetAttachments(this.state.requestId).then((result) => {
        var selectedFile = result.data.filter(function(el) {
          return el.filename === dataObj.filename;
        });
        dataToSave.grievanceId = selectedFile[0].grievanceId;
        Services.UpdateFile(dataToSave).then((result) => {
          this.setState({
            filesTable: filesdata,
            editReference: dataObj.reference,
          });
          if (result) toast.success(result.message);
          else toast.error(result.error);
        });
      });
    };
    this.submitComment = (comment) => {
      let commentsList = this.state.commentsData;
      commentsList.push({
        actionDate: new Date().toLocaleDateString(),
        memberName:
          AuthService.getProfile().memberData.firstname +
          " " +
          AuthService.getProfile().memberData.lastname,
        comments: comment,
      });
      Services.SaveComment({
        comment: comment,
        grievanceId: props.match.params.Id,
      }).then((result) => {
        if (result.status == "Success")
          toast.success("Comment Added Successfully");
        else toast.error(result.Message);
        this.setState({ commentsData: commentsList });
      });
    };
  }
  reloadLogs = () => {
    this.setState({ reloadLogs: false });
  };
  rerenderParentCallback = async () => {
    $(".loading").show();
    await this.setState({ reloadLogs: true });
    this.loadGrievanceData();
  };
  getNextStepName = (lastStep) => {
    if (!lastStep.isMeeting && lastStep.stepType == "Elevation") {
      switch (lastStep.stepName) {
        case "Step 1":
          return "Step 1 Response";
        case "Step 2":
          return "Step 2 Response";
        case "PAR":
          return "PAR Response";
        case "GRT":
          return "Arbitration";
        case "Arbitration":
          return "Arbitration Response";
        case "Expedited Arbitration":
          return "Expedited Arbitration Response";
      }
    } else {
      switch (lastStep.stepName) {
        case "Step 1":
          return "Step 1 Meeting";
        case "Step 1 Meeting":
          return "Step 1 Response";
        case "Step 1 Response":
          return "Step 2";
        case "Step 2":
          return "Step 2 Meeting";
        case "Step 2 Meeting":
          return "Step 2 Response";
        case "Step 2 Response":
          return "PAR";
        case "PAR":
          return "PAR Meeting";
        case "PAR Meeting":
          return "PAR Response";
        case "PAR Response":
          return "GRT";
        case "GRT":
          return "Arbitration";
        case "Arbitration":
          return "Arbitration Meeting";
        case "Arbitration Meeting":
          return "Arbitration Response";
        case "Arbitration Response":
          return "Expedited Arbitration";
        case "Expedited Arbitration":
          return "Expedited Arbitration Hearing";
        case "Expedited Arbitration Hearing":
          return "Expedited Arbitration Response";
      }
    }
  };
  getNextStepType = (lastStepName) => {
    if (lastStepName == "GRT") return "Elevation";
    if (lastStepName.indexOf("Meeting") > -1) return "Response";
    else if (lastStepName.indexOf("Response") > -1) return "Elevation";
    else return "Meeting";
  };
  handleCollapse = (id) => {
    if (
      $("#label" + id)
        .text()
        .trim() == "collapse"
    ) {
      $("#collapse" + id).hide(1000);
      $("#label" + id).text("expand");
    } else {
      $("#collapse" + id).show(1000);
      $("#label" + id).text("collapse");
    }
  };
  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };
  onFileUpload = () => {
    const formData = new FormData();
    formData.append(
      "myFile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
  };
  changeActiveTab(value) {
    this.setState({ ActiveTab: value });
  }
  updateChange = () => {};
  downloadAllAttachment = () => {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = this.state.grievanceGeneratedId;
    let filesSorted = [];
    if (zipFilename) {
      _.forEach(this.state.filesTable, function(el) {
        filesSorted.push({
          link: el.fileDownloadLink,
          name: el.originalfilename,
        });
      });
      filesSorted.forEach(function(file) {
        var filename = file.name;
        JSZipUtils.getBinaryContent(file.link, function(err, data) {
          if (err) {
            throw err;
          }
          zip.file(filename, data, { binary: true });
          count++;
          if (count === filesSorted.length) {
            zip.generateAsync({ type: "blob" }).then(function(content) {
              var a = $("<a style='display: none;'/>");
              var url = window.URL.createObjectURL(content);
              a.attr("href", url);
              a.attr("download", zipFilename);
              $("body").append(a);
              a[0].click();
              window.URL.revokeObjectURL(url);
              a.remove();
            });
          }
        });
      });
    }
  };
  loadGrievanceData = async () => {
    let comments = [];
    Services.GetComments(this.state.requestId).then((result) => {
      _.forEach(result.data, function(el) {
        comments.push({
          actionDate: el.actionDate,
          memberName: el.memberName,
          comments: el.comments,
        });
      });
      this.setState({
        commentsData: comments,
      });
    });
    Services.GetAttachments(this.state.requestId).then((result) => {
      let filesTableArray = [];
      _.forEach(result.data, function(el, index, arr) {
        filesTableArray.push({
          Uploaded: el.Uploaded,
          filename: el.filename,
          reference: el.reference,
          stepName: el.stepEnumId,
          originalfilename: el.originalfilename,
          fileDownloadLink: el.fileDownloadLink,
        });
      });

      this.setState({
        files: result.data,
        filesTable: filesTableArray,
      });
    });
    await Services.LoadGrievanceDataById(this.state.requestId).then(
      (result) => {
        if (!_.isEmpty(result.data)) {
          if (result.data.steps.length > 0) {
            const firstStep = result.data.steps[0];
            const lastStep = result.data.steps[result.data.steps.length - 1];
            Services.GetNextStepDueDate(
              lastStep.stepId,
              lastStep.grievanceId
            ).then((ac) => {
              var nextDueDate = ac.data;
              this.setState({
                isLastStepElevated: this.getLastStepElevation(),
              });
              this.setState({
                currentStatePdfData: result.data.pdfData,
                subject: firstStep.subject,
                grievanceStatus: result.data.grievanceStatus,
                dateSubmitted: new Date(
                  firstStep.dateSubmitted
                ).toLocaleDateString(),
                grievanceGeneratedId: result.data.grievanceGeneratedId,
                grievantId: result.data.grievant.id,
                grievantName:
                  result.data.grievant.firstname +
                  " " +
                  result.data.grievant.lastname,
                representativeName:
                  result.data.representative.firstname +
                  " " +
                  result.data.representative.lastname,
                isAdverse: result.data.isAdverse,
                canEdit: result.data.canEdit,
                responseDate: result.data.responseDate,
                recipientName: result.data.recipient.recipientName,
                stepsData: result.data.steps,
                article: result.data.article
                  ? {
                      id: result.data.article.id,
                      name: result.data.article.articleName,
                    }
                  : {},
                recipient: {
                  id: result.data.recipient.id,
                  name: result.data.recipient.articleName,
                },
                meetingDate: result.data.meetingDate,
                representative: {
                  id: result.data.representative.id,
                  name: result.data.representative.articleName,
                },
                newStep: {
                  stepName: this.getNextStepName(lastStep),
                  stepType: this.getNextStepType(lastStep.stepName),
                  isAdverse: lastStep.isAdverse,
                  isMeeting: lastStep.isMeeting,
                  meetingDate: moment(new Date()).format("MM/DD/YYYY"),
                  stepDueByDate: nextDueDate,
                  dateOfVoilation: moment(new Date()).format("MM/DD/YYYY"),
                  dateSubmitted: moment(new Date()).format("MM/DD/YYYY"),
                  subject: lastStep.subject,
                  fAANumber: lastStep.fAANumber,
                  statementOfFacts: lastStep.statementOfFacts,
                  representativeId: lastStep.representativeId,
                  recipientId: lastStep.recipientId,
                  lrRepresentativeId: lastStep.lrRepresentativeId,
                  grtRepresentativeId: lastStep.grtRepresentativeId,
                  remedySought: lastStep.remedySought,
                  articleVoilated: "",
                  additionalFacts: "",
                  contractId: lastStep.contractId,
                  pdfData: result.data.pdfData,
                  responseDate: moment(new Date()).format("MM/DD/YYYY"),
                },
              });
              $(".loading").hide();
            });
          }
        }
      }
    );
  };
  componentDidMount() {
    this.loadGrievanceData();
    setTimeout(() => {
      $(".loading").hide();
    }, 5000);
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    return (
      <div>
        <GrievanceHeader
          changeActiveTab={this.changeActiveTab}
          ActiveTab={this.state.ActiveTab}
        />
        <div
          className="pac-container container-fluid"
          style={{ marginBottom: "80px" }}
        >
          <div className="row pt-3">
            <div className="col-lg-8 px-3 py-0">
              <div style={{ float: "right" }}>
                <PDFDownloadLink
                  document={
                    <PdfDocument data={this.state.currentStatePdfData} />
                  }
                  fileName={"test.pdf"}
                >
                  <i class="fas fa-file-pdf" />
                </PDFDownloadLink>
              </div>
              <div id="EditForm">
                <div
                  className="col-lg-12 cardHeaderMainDiv p-0 mb-2"
                  style={{
                    border: "1px solid darkgrey",
                    marginTop: "20px",
                  }}
                >
                  <div className="cardHeader">
                    <div className="col-lg-10">
                      {this.state.subject} - {this.state.dateSubmitted} -
                      {"                  "}
                      {this.state.grievanceGeneratedId}
                    </div>
                  </div>
                  <div className="cardBody">
                    <div class="row mx-2">
                      <div class="col-sm-6">
                        <h6>
                          <i>Grievant</i>
                        </h6>
                        {this.state.grievantName.trim() != "NATCA" && (
                          <a
                            target="_blank"
                            href={
                              "/directory/userAction/" + this.state.grievantId
                            }
                          >
                            {this.state.grievantName}
                          </a>
                        )}
                        {this.state.grievantName.trim() == "NATCA" && (
                          <a>{this.state.grievantName}</a>
                        )}
                      </div>
                      <div class="col-sm-6">
                        <h6>
                          <i>Representative</i>
                        </h6>
                        <a
                          target="_blank"
                          href={
                            "/directory/userAction/" +
                            this.state.representative.id
                          }
                        >
                          {this.state.representativeName}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ clear: "both" }} />
                <div className="">
                  <ul id="progress">
                    {this.state.stepsData.map((item, index) => (
                      <>
                        {item.stepType == "Elevation" && (
                          <StepElevation
                            stepsData={item}
                            pdfData={this.state.newStep.pdfData}
                            rerenderParentCallback={this.rerenderParentCallback}
                            isAdverse={this.state.isAdverse}
                            recipientName={this.state.recipientName}
                            stepCreateMode={false}
                            grievantId={this.state.grievantId}
                            updateChange={this.updateChange}
                          />
                        )}

                        {item.stepType == "Meeting" && (
                          <StepMeeting
                            rerenderParentCallback={this.rerenderParentCallback}
                            stepsData={item}
                            isAdverse={this.state.isAdverse}
                            recipientName={this.state.recipientName}
                            stepCreateMode={false}
                            updateChange={this.updateChange}
                          />
                        )}
                        {item.stepType == "Response" && (
                          <StepResponse
                            rerenderParentCallback={this.rerenderParentCallback}
                            stepsData={item}
                            grievanceStatus={this.state.grievanceStatus}
                            isAdverse={this.state.isAdverse}
                            recipientName={this.state.recipientName}
                            stepCreateMode={false}
                            updateChange={this.updateChange}
                          />
                        )}
                      </>
                    ))}
                    {this.getLastStepElevation() && this.state.canEdit && (
                      <>
                        {this.getLastStepType() == "Elevation" && (
                          <StepElevation
                            grievantId={this.state.grievantId}
                            rerenderParentCallback={this.rerenderParentCallback}
                            stepsData={this.state.newStep}
                            isAdverse={this.state.isAdverse}
                            recipientName={this.state.recipientName}
                            stepCreateMode={true}
                            updateChange={this.updateChange}
                          />
                        )}
                        {this.getLastStepType() == "Meeting" && (
                          <StepMeeting
                            rerenderParentCallback={this.rerenderParentCallback}
                            stepsData={this.state.newStep}
                            isAdverse={this.state.isAdverse}
                            recipientName={this.state.recipientName}
                            stepCreateMode={true}
                            updateChange={this.updateChange}
                          />
                        )}
                        {this.getLastStepType() == "Response" && (
                          <StepResponse
                            rerenderParentCallback={this.rerenderParentCallback}
                            stepsData={this.state.newStep}
                            isAdverse={this.state.isAdverse}
                            recipientName={this.state.recipientName}
                            stepCreateMode={true}
                            updateChange={this.updateChange}
                          />
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            {this.state.requestId && this.state.requestId !== -1 ? (
              <div className="col-lg-4 p-0">
                <div className="col-lg-12 cardHeaderMainDiv mb-2">
                  <div>
                    <div className="cardHeader">
                      <div className="col-md-12">
                        <div
                          className="col-md-6"
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginLeft: "-32px",
                          }}
                        >
                          <a className="font-italic">Attachments</a>
                        </div>
                        <div
                          className="col-md-6"
                          style={{
                            float: "right",
                            fontSize: "26px",
                            textAlign: "right",
                          }}
                        >
                          <a onClick={this.downloadAllAttachment}>
                            <i
                              class="fa fa-cloud-download"
                              aria-hidden="true"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <Collapse isOpen={true}>
                      <GrievanceAttachments
                        requestId={this.state.requestId}
                        attachedFiles={this.state.files}
                        attachedFilesTable={this.state.filesTable}
                        deleteFile={this.deleteFile}
                        updateFiles={this.updateFiles}
                        disableAttachments={this.state.canEdit}
                        openFileEditModal={this.openFileEditModal}
                      />
                    </Collapse>
                  </div>
                </div>

                <div className="col-lg-12 cardHeaderMainDiv mb-2">
                  <div>
                    <div className="cardHeader">
                      <a className="font-italic">Comments</a>
                    </div>
                    <Collapse isOpen={true}>
                      <GrievanceComments
                        requestId={this.state.requestId}
                        commentsData={this.state.commentsData}
                        submitComment={this.submitComment}
                        disableComments={this.state.canEdit}
                      />
                    </Collapse>
                  </div>
                </div>

                <GrievanceActivityLogging
                  id={this.state.requestId}
                  reloadLogs={this.state.reloadLogs}
                  resetReloadLogs={this.reloadLogs}
                  grievanceState={this.props.grievanceState}
                  loggingfn={this.props.toggleGrievanceLogAccordion}
                  getActivityLoggingByModuleId={
                    this.props.getActicityLoggingByModuleId
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <FileEditModal
            showModal={this.state.showFileEditModal}
            onClose={this.closeFileEditModal}
            originalfilename={this.state.editOriginalFileName}
            filename={this.state.editFileName}
            reference={this.state.editReference}
            updateFileData={this.updateFileData}
            referencesData={this.state.stepsList}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect((state) => ({ grievanceState: state.grievances }), {
    ...GrievanceAction,
    ...ActivityActions,
  })(GrievanceDetailsForm)
);
