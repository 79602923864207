import React, { Component } from "react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { MDBInput } from "mdbreact";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { FilePond, registerPlugin, setOptions, File } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import _ from "lodash";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import Services from "../GrievanceService";
import "font-awesome/css/font-awesome.min.css";
import "../../Grievances/grievances.css";
import { PARPdfDocument } from "../General/GrievancePARPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import EditRecipient from "../General/EditRecipient";
import GrievanceDuplicateForm from "../General/GrievanceDuplicateForm";
import moment from "moment";
import DatePicker from "react-datepicker";
const filter = createFilterOptions();
registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode
);

class StepElevation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EditRecipientId: 0,
      EditRecipientName: null,
      GrievanceStepId: this.props.stepsData.stepId,
      showRecipientModal: false,
      showDuplicateGrievanceModal: false,
      fAANumber: this.props.stepsData.fAANumber,
      remedySought: this.props.stepsData.remedySought,
      statementOfFacts: this.props.stepsData.statementOfFacts,
      dateSubmitted: new Date(),
      recipientName: "",
      pdfData: {},
      collapse: false,
      isMeetingRequired: true,
      selectedFiles: [],
      grtRepresentativeData: [],
      grtRepresentativeId: null,
      grtRepresentative: null,
      lrRepresentativeData: [],
      lrRepresentative: null,
      lrRepresentativeId: null,
      representativeData: [],
      representative: null,
      representativeId: null,
      recipientData: [],
      recipient: null,
      recipientId: null,

      randomId: Math.round(Math.random() * (100 - 10) + 10),
    };
    this.getRecipientByRepresentativeId = this.getRecipientByRepresentativeId.bind(
      this
    );
    this.handleInit = () => {
      // console.log("FilePond instance has initialised", this.pond);
    };
    this.updateFiles = (fileItems) => {
      try {
        this.setState({
          selectedFiles: fileItems
            .filter((el) => {
              return el.status !== 8;
            })
            .map((fileItem) => fileItem.file),
        });
      } catch (err) {
        console.log(err);
      }
    };
    this.cancelStep = () => {
      this.props.history.push({
        pathname: "/grievances",
        type: "list",
      });
    };
    this.LoadLists = this.LoadLists.bind(this);
    this.editRecipient = this.editRecipient.bind(this);
    this.closeRecipientModal = this.closeRecipientModal.bind(this);
    this.deleteRecipient = this.deleteRecipient.bind(this);
  }
  editRecipient = async (e, id, name) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    await this.setState({
      EditRecipientId: id,
      EditRecipientName: name,
      showRecipientModal: true,
    });
  };
  closeRecipientModal = () => {
    this.setState({ showRecipientModal: false });
  };
  handleCollapse = (id) => {
    if ($("#collapse" + id).is(":visible")) {
      $("#collapse" + id).hide(1000);
      this.setState({
        collapse: false,
      });
    } else {
      $("#collapse" + id).show(1000);
      this.setState({
        collapse: true,
      });
    }
  };
  componentDidMount() {
    this.LoadLists();
  }
  async saveElevation() {
    $(".loading").show();
    if (this.props.stepsData.stepName == "PAR Elevation") {
      Services.GetPDFData(
        this.props.match.params.Id,
        this.props.stepsData.stepId
      ).then((response) => {
        if (!_.isEmpty(response)) {
          Services.SaveSnapShot(response.data, this.props.stepsData.stepId);
        }
      });
    }
    if (this.props.stepsData.stepName == "Step 2") {
      if (this.state.recipientId == null) {
        await this.setState({ recipient_err: "Please select the recipient" });
        $(".loading").hide();
        return false;
      } else {
        await this.setState({ recipient_err: "" });
      }
      if (this.state.representativeId == null) {
        await this.setState({
          representative_err: "Please select the representative",
        });
        $(".loading").hide();
        return false;
      } else {
        await this.setState({ representative_err: "" });
      }
    }
    var data = {
      stepName: this.props.stepsData.stepName,
      meetingDate: this.props.stepsData.meetingDate,
      isMeetingRequired: this.state.isMeetingRequired,
      grievanceId: this.props.match.params.Id,
      grtRepresentativeId: this.props.stepsData.grtRepresentativeId,
      representativeId:
        this.props.stepsData.stepName == "Step 2"
          ? this.state.representativeId
          : this.props.stepsData.representativeId,
      recipientId:
        this.props.stepsData.stepName == "Step 2"
          ? this.state.recipientId
          : this.props.stepsData.recipientId,
      recipientName: this.state.recipientName,
      stepType: "Elevation",
      isAdverse: this.props.stepsData.isAdverse,
      dateOfVoilation: this.props.stepsData.dateOfVoilation,
      dateSubmitted: this.state.dateSubmitted,
      subject: this.props.stepsData.subject,
      lrRepresentativeId:
        this.props.stepsData.stepName == "PAR"
          ? this.state.lrRepresentativeId
          : this.props.stepsData.lrRepresentativeId,
      fAANumber: this.state.fAANumber,
      statementOfFacts: this.state.statementOfFacts,
      remedySought: this.state.remedySought,
      statusId: 2,
    };
    let formdata = new FormData();
    for (var i = 0; i < this.state.selectedFiles.length; i++) {
      formdata.append("File" + i, this.state.selectedFiles[i]);
    }
    formdata.append("data", JSON.stringify(data));
    Services.SaveStep(formdata).then((response) => {
      if (!_.isEmpty(response)) {
        toast.success("Request saved successfully");
        this.props.rerenderParentCallback();
        $(".loading").hide();
      }
    });
  }
  saveResponse(isElevated, closeStatus) {
    $(".loading").show();
    var data = {
      stepName: this.props.stepsData.stepName,
      meetingDate: this.props.stepsData.meetingDate,
      isMeetingRequired: true,
      grievanceId: this.props.match.params.Id,
      stepType: "Elevation",
      isAdverse: this.props.stepsData.isAdverse,
      dateOfVoilation: this.props.stepsData.dateOfVoilation,
      dateSubmitted: this.props.stepsData.dateSubmitted,
      representativeId: this.props.stepsData.representativeId,
      recipientId: this.props.stepsData.recipientId,
      lrRepresentativeId: this.props.stepsData.lrRepresentativeId,
      subject: this.props.stepsData.subject,
      fAANumber: this.props.stepsData.fAANumber,
      statementOfFacts: this.state.statementOfFacts,
      remedySought: this.state.remedySought,
      grtRepresentativeId: this.state.grtRepresentativeId,
      additionalFacts: "",
      responseDate: this.state.responseDate,
      contractId: this.props.stepsData.contractId,
    };
    if (isElevated) {
    } else {
      switch (closeStatus) {
        case "Denied":
          data.statusId = 6;
          break;
        case "Sustained":
          data.statusId = 5;
          break;
        case "Withdrawn":
          data.statusId = 7;
          break;
        case "Settled":
          data.statusId = 8;
          break;
      }
    }
    let formdata = new FormData();
    for (var i = 0; i < this.state.selectedFiles.length; i++) {
      formdata.append("File" + i, this.state.selectedFiles[i]);
    }
    if (closeStatus == "Withdrawn") {
      this.setState({
        saveDataForModal: data,
        reason_err: "",
        grievanceReason: "",
        isReasonModalOpen: !this.state.isReasonModalOpen,
      });
      $(".loading").hide();
    } else {
      formdata.append("data", JSON.stringify(data));
      Services.SaveStep(formdata).then((response) => {
        if (!_.isEmpty(response)) {
          Services.SaveSnapShot(data, response.stepId).then(() => {
            if (!_.isEmpty(response)) {
              toast.success("Request saved successfully");
              this.props.rerenderParentCallback();
            }
          });
        }
        $(".loading").hide();
      });
    }
  }

  closeDuplicateGrievanceModal = () => {
    this.setState({ showDuplicateGrievanceModal: false });
  };
  elevateToArbitration() {
    var data = {
      stepName: this.props.stepsData.stepName,
      meetingDate: this.props.stepsData.meetingDate,
      isMeetingRequired: this.state.isMeetingRequired,
      grievanceId: this.props.match.params.Id,
      representativeId:
        this.props.stepsData.stepName == "Step 2"
          ? this.state.representativeId
          : this.props.stepsData.representativeId,
      recipientId:
        this.props.stepsData.stepName == "Step 2"
          ? this.state.recipientId
          : this.props.stepsData.recipientId,
      recipientName: this.state.recipientName,
      stepType: "Elevation",
      shouldSubmitArbitration: true,
      isAdverse: this.props.stepsData.isAdverse,
      dateOfVoilation: this.props.stepsData.dateOfVoilation,
      dateSubmitted: this.state.dateSubmitted,
      subject: this.props.stepsData.subject,
      fAANumber: this.state.fAANumber,
      statementOfFacts: this.state.statementOfFacts,
      remedySought: this.state.remedySought,
      statusId: 2,
    };
    let formdata = new FormData();
    for (var i = 0; i < this.state.selectedFiles.length; i++) {
      formdata.append("File" + i, this.state.selectedFiles[i]);
    }
    formdata.append("data", JSON.stringify(data));
    Services.SaveStep(formdata).then((response) => {
      if (!_.isEmpty(response)) {
        toast.success("Request saved successfully");
        this.props.rerenderParentCallback();
        $(".loading").hide();
      }
    });
  }
  async getRecipientByRepresentativeId() {
    if (this.state.representative != null && this.state.representativeId) {
      let recipientsList = [];
      let result = await Services.GetRecipient(this.state.representativeId);
      _.forEach(result, function(el, index, arr) {
        recipientsList.push({
          id: el.id,
          name: el.recipientName,
        });
      });
      this.state.recipientData = recipientsList;
    } else {
      this.state.recipientData = [];
    }

    await this.setState({
      recipientData: this.state.recipientData,
    });
  }
  LoadLists = async () => {
    if (this.props.stepsData.stepName == "Step 2") {
      {
        let result = await Services.LoadDataByGrievant(this.props.grievantId);
        let representativesList = [];
        //let recipientsList = [];

        if (!_.isEmpty(result.data)) {
          _.forEach(result.data.representativesList, function(el, index, arr) {
            representativesList.push({
              id: el.id,
              name: el.firstname + " " + el.lastname,
            });
          });

          const selectedRepresentative = result.data.representative;
          this.state.representative = selectedRepresentative
            ? {
                name:
                  selectedRepresentative.firstname +
                  " " +
                  selectedRepresentative.lastname,
                id: selectedRepresentative.id,
              }
            : null;
          const selectedRecipient = result.data.recipient;
          this.state.recipient = selectedRecipient
            ? {
                name: selectedRecipient.recipientName,
                id: selectedRecipient.id,
              }
            : null;
          await this.setState({
            representativeData: representativesList,
            representativeId: !_.isEmpty(this.state.representative)
              ? this.state.representative.id
              : null,
            recipientId: !_.isEmpty(this.state.recipient)
              ? this.state.recipient.id
              : null,
            recipient: this.state.recipient,
            representative: this.state.representative,
          });

          await this.getRecipientByRepresentativeId();
        }
      }
    }
    if (this.props.stepsData.stepName == "PAR") {
      let result = await Services.GetLRRepresentatives(0);
      if (!_.isEmpty(result.data)) {
        var LRMembers = [];
        result.data.map((item) => {
          LRMembers.push({
            id: item.id,
            name: item.firstname + " " + item.lastname,
          });
        });
        this.setState({ lrRepresentativeData: LRMembers });
      }
    }
    if (this.props.stepsData.stepName == "GRT") {
      let result = await Services.GetGRTRepresentatives();
      if (!_.isEmpty(result.data)) {
        var GRTMembers = [];
        result.data.map((item) => {
          GRTMembers.push({
            id: item.id,
            name: item.firstname + " " + item.lastname,
          });
        });
        this.setState({ grtRepresentativeData: GRTMembers });
      }
    }
  };
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  deleteRecipient = async (id) => {
    $(".loading").show();
    let val = await Services.DeleteRecipient(id);
    if (!_.isEmpty(this.state.recipientsList)) {
      this.getRecipientByRepresentativeId();
    }
    $(".loading").hide();
  };
  render() {
    return (
      <>
        {(() => {
          if (!this.props.stepCreateMode) {
            return (
              <>
                <li>
                  <div class="node green" />
                  <div class="stepPara">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-md-12">
                          <div className="float-left">
                            <h5>{this.props.stepsData.stepName}</h5>
                          </div>
                          <div
                            style={{
                              float: "left",
                              marginTop: "13px",
                              marginLeft: "10px",
                            }}
                          >
                            <a
                              onClick={() => {
                                this.handleCollapse(this.state.randomId);
                              }}
                              id={"label" + this.state.randomId}
                            >
                              {this.state.collapse ? (
                                <i className="fas fa-times"></i>
                              ) : (
                                <i className="fas fa-plus"></i>
                              )}
                            </a>
                            {this.props.stepsData.stepName == "PAR" && (
                              <PDFDownloadLink
                                document={
                                  <PARPdfDocument data={this.props.pdfData} />
                                }
                                fileName={
                                  this.props.stepsData.stepName + ".pdf"
                                }
                              >
                                {({ blob, url, loading, error }) => {
                                  //this.sendBlobToServer(blob);
                                  return (
                                    <div>
                                      <i class="fas fa-file-pdf" />{" "}
                                    </div>
                                  );
                                }}
                              </PDFDownloadLink>
                            )}
                          </div>
                        </div>
                        <div class="col-md-12">
                          Submitted {this.props.stepsData.dateSubmitted}
                          {this.props.grievanceStatus != "Denied" &&
                            this.props.grievanceStatus != "Sustained" &&
                            this.props.grievanceStatus != "Withdrawn" &&
                            this.props.grievanceStatus != "Settled" && (
                              <i
                                class="pl-2 fas fa-files-o text-primary c-pointer"
                                onClick={async () => {
                                  await this.setState({
                                    showDuplicateGrievanceModal: true,
                                  });
                                }}
                              />
                            )}
                        </div>
                        <div
                          class="col-md-12"
                          style={{
                            border: "1px solid darkgrey",
                            padding: "15px",
                            display: "none",
                          }}
                          id={"collapse" + this.state.randomId}
                        >
                          <h5>
                            <i>Details of Grievance</i>
                          </h5>
                          <div className="row">
                            <div className="col-sm-6">
                              <p>
                                <b>Adverse Action? : </b>{" "}
                                {this.props.isAdverse ? "YES" : "NO"}
                              </p>
                              <p>
                                <b>Recipient : </b>{" "}
                                {this.props.recipientName
                                  ? this.props.recipientName
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6">
                              <p>
                                <b>Request Meeting? : </b>{" "}
                                {this.props.stepsData.isMeeting ? "YES" : "NO"}
                              </p>
                              <p>
                                <b>FAA Number : </b>{" "}
                                {this.props.stepsData.fAANumber}
                              </p>
                            </div>
                          </div>
                          <p>
                            <b>Violation : </b> {"  "}
                            {this.props.stepsData.articleVoilated}
                          </p>
                          <p>
                            <b>Statement of Facts : </b> {"  "}
                            {this.props.stepsData.statementOfFacts}
                          </p>
                          <p>
                            <b>Remedy Sought : </b> {"  "}
                            {this.props.stepsData.remedySought}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <GrievanceDuplicateForm
                  GrievanceId={this.props.match.params.Id}
                  StepId={this.state.GrievanceStepId}
                  ShowModal={this.state.showDuplicateGrievanceModal}
                  OnClose={this.closeDuplicateGrievanceModal}
                />
              </>
            );
          } else {
            if (this.props.stepsData.stepName == "GRT") {
              return (
                <li>
                  <div class="node meeting" />
                  <div class="stepPara">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-md-12">
                          <div className="float-left">
                            <h5>{this.props.stepsData.stepName}</h5>
                          </div>
                          <div
                            style={{
                              float: "left",
                              marginTop: "13px",
                              marginLeft: "10px",
                            }}
                          ></div>
                        </div>

                        <div
                          class="col-md-12"
                          style={{ border: "1px solid darkgrey" }}
                        >
                          <div style={{ clear: "both" }} />

                          <div className="col-md-12 mb-2 mt-4">
                            <Autocomplete
                              options={this.state.grtRepresentativeData}
                              autoComplete={true}
                              autoHighlight={true}
                              value={this.state.grtRepresentative}
                              getOptionLabel={(option) => {
                                if (option != undefined) {
                                  return option.name;
                                } else {
                                  return "";
                                }
                              }}
                              renderOption={(option) => (
                                <React.Fragment>{option.name}</React.Fragment>
                              )}
                              onChange={(e, value, reason) => {
                                this.setState({
                                  grtRepresentative: value,
                                  grtRepresentativeId: !_.isEmpty(value)
                                    ? value.id
                                    : null,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="GRT Representative"
                                  placeholder="Select GRT Representative..."
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                    classes: { shrink: "mt-n1" },
                                  }}
                                  fullWidth
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-12 my-0">
                            <span className="">Additional Facts</span>
                            <textarea
                              name=""
                              className="w-100 border"
                              value={this.state.statementOfFacts}
                              rows="5"
                              maxLength="500"
                              onChange={(event, value) => {
                                this.setState({
                                  statementOfFacts: event.target.value,
                                });
                              }}
                            />
                            <span className="text-danger">{}</span>
                          </div>
                          <div className="col-md-12 my-0">
                            <label>Remedy Sought</label>
                            <textarea
                              name="remedySought"
                              className="w-100 border"
                              value={this.state.remedySought}
                              rows="5"
                              maxLength="500"
                              onChange={(event, value) =>
                                this.setState({
                                  remedySought: event.target.value,
                                })
                              }
                            />
                            <span className="text-danger">{}</span>
                          </div>
                          <div className="col-md-12">
                            <span className="">
                              Upload Response and Additional Documentation :
                            </span>
                            <FilePond
                              ref={(ref) => (this.pond = ref)}
                              files={this.props.attachedFiles}
                              allowMultiple={true}
                              allowFileTypeValidation={true}
                              allowImageExifOrientation={true}
                              allowImagePreview={true}
                              allowImageCrop={true}
                              allowFileEncode={true}
                              acceptedFileTypes={this.state.acceptedFileTypes}
                              fileValidateTypeDetectType={(source, type) =>
                                new Promise((resolve, reject) => {
                                  let rejectedExtensions = [
                                    "ade",
                                    "adp",
                                    "apk",
                                    "bat",
                                    "chm",
                                    "cmd",
                                    "com",
                                    "cpl",
                                    "dll",
                                    "dmg",
                                    "exe",
                                    "hta",
                                    "ins",
                                    "isp",
                                    "jar",
                                    "js",
                                    "jse",
                                    "lib",
                                    "lnk",
                                    "mde",
                                    "msc",
                                    "msi",
                                    "msp",
                                    "mst",
                                    "nsh",
                                    "pif",
                                    "scr",
                                    "sct",
                                    "shb",
                                    "sys",
                                    "vb",
                                    "vbe",
                                    "vbs",
                                    "vxd",
                                    "wsc",
                                    "wsf",
                                    "wsh",
                                    "cab",
                                  ];
                                  // If the file extension is not in our rejected list.
                                  if (
                                    !rejectedExtensions.some((ext) =>
                                      source.name.endsWith("." + ext)
                                    )
                                  ) {
                                    resolve(type);
                                  } else {
                                    // Otherwise it is rejected.
                                    //toast.error("File type is unsupported!");
                                    //reject(new Error('Files contains unsupported format!'));
                                    reject(type);
                                  }
                                })
                              }
                              onupdatefiles={(fileItems) => {
                                this.updateFiles(fileItems);
                              }}
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="text-center">
                              <button
                                className="btn btn-dark btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                type="button"
                                onClick={() => {
                                  this.cancelStep();
                                }}
                                style={{
                                  marginRight: "30px",
                                  marginTop: "7px",
                                  height: "33px",
                                }}
                              >
                                Cancel
                              </button>

                              <div
                                class="btn-group"
                                style={{ marginLeft: "-20px" }}
                              >
                                <button type="button" class="btn btn-primary">
                                  Close Grievance
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                  <a
                                    onClick={() => {
                                      this.saveResponse(false, "Denied");
                                    }}
                                    class="dropdown-item"
                                    href="#"
                                  >
                                    Denied
                                  </a>
                                  <a
                                    onClick={() => {
                                      this.saveResponse(false, "Sustained");
                                    }}
                                    class="dropdown-item"
                                    href="#"
                                  >
                                    Sustained
                                  </a>
                                  <a
                                    onClick={() => {
                                      this.saveResponse(false, "Withdrawn");
                                    }}
                                    class="dropdown-item"
                                    href="#"
                                  >
                                    Withdrawn
                                  </a>
                                  {/* <div class="dropdown-divider"></div> */}
                                  <a
                                    onClick={() => {
                                      this.saveResponse(false, "Settled");
                                    }}
                                    class="dropdown-item"
                                    href="#"
                                  >
                                    Settled
                                  </a>
                                </div>
                              </div>
                              <button
                                className="btn btn-danger btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                onClick={() => {
                                  this.saveResponse(true, "");
                                }}
                                type="submit"
                                style={{
                                  marginLeft: "30px !important",
                                  marginTop: "7px",
                                  height: "33px",
                                }}
                              >
                                Undo Elevation
                              </button>
                              <button
                                className="btn btn-danger btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                onClick={() => {
                                  this.saveResponse(true, "");
                                }}
                                type="submit"
                                style={{
                                  marginLeft: "30px !important",
                                  marginTop: "7px",
                                  height: "33px",
                                }}
                              >
                                Elevate
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li>
                  <div class="node meeting" />
                  <div class="stepPara">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-md-12">
                          <div className="float-left">
                            <h5>{this.props.stepsData.stepName}</h5>
                          </div>
                          <div
                            style={{
                              float: "left",
                              marginTop: "13px",
                              marginLeft: "10px",
                            }}
                          >
                            <label
                              onClick={() => {
                                this.handleCollapse("1");
                              }}
                              id="label1"
                            >
                              {this.state.collapse ? (
                                <i className="fas fa-times"></i>
                              ) : (
                                <i className="fas fa-plus"></i>
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          class="col-md-12"
                          style={{ border: "1px solid darkgrey" }}
                          id="collapse1"
                        >
                          <div class="col-md-12">
                            <div
                              class="col-md-6 pl-md-0"
                              style={{ float: "left" }}
                            >
                              <div>
                                {this.props.stepsData.stepDueByDate !== "" && (
                                  <p>
                                    Due By {this.props.stepsData.stepDueByDate}{" "}
                                  </p>
                                )}

                                <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                  <small>Submission Date:</small>
                                </span>
                                <DatePicker
                                  dateForm="MM/DD/YYYY"
                                  className="form-control bg-light rounded-0 "
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  onChange={(date) => {
                                    if (moment(date).isValid()) {
                                      this.state.dateSubmitted = moment(
                                        date
                                      ).format("MM/DD/YYYY");
                                    } else {
                                      this.state.dateSubmitted = date;
                                    }
                                    this.setState({
                                      dateSubmitted: this.state.dateSubmitted,
                                    });
                                  }}
                                  selected={
                                    this.state.dateSubmitted
                                      ? moment(
                                          this.state.dateSubmitted
                                        ).toDate()
                                      : moment(new Date()).toDate()
                                  }
                                  placeholderText={"MM/DD/YYYY"}
                                />
                                <span className="text-danger">{""}</span>
                              </div>
                            </div>
                            <div
                              class="col-md-6 pr-md-0"
                              style={{ float: "left" }}
                            >
                              <div className="py-1">
                                <span className="">Request Meeting?</span>
                                <div class="input-group mb-3">
                                  <input
                                    type="checkbox"
                                    switch="bool"
                                    checked={this.state.isMeetingRequired}
                                    onChange={() =>
                                      this.setState({
                                        isMeetingRequired: !this.state
                                          .isMeetingRequired,
                                      })
                                    }
                                    id={"isMeetingRequested"}
                                    name={"isMeetingRequested"}
                                  />
                                  <label
                                    className="mb-n2"
                                    htmlFor={"isMeetingRequested"}
                                    data-on-label="Yes"
                                    data-off-label="No"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ clear: "both" }} />
                          <div className="col-md-6">
                            <MDBInput
                              name="fAANumber"
                              value={this.state.fAANumber}
                              hint="Provide FAA Number"
                              type="text"
                              maxLength="100"
                              onChange={(event, value) =>
                                this.setState({ fAANumber: event.target.value })
                              }
                            />
                          </div>
                          {this.props.stepsData.stepName == "PAR" && (
                            <div className="col-md-12 mb-2">
                              <Autocomplete
                                options={this.state.lrRepresentativeData}
                                filterOptions={(options, state) => options}
                                autoComplete={true}
                                autoHighlight={true}
                                value={this.state.lrRepresentative}
                                getOptionLabel={(option) => {
                                  if (option != undefined) {
                                    return option.name;
                                  } else {
                                    return "";
                                  }
                                }}
                                onChange={(e, value, reason) => {
                                  this.setState({
                                    lrRepresentative: value,
                                    lrRepresentativeId: !_.isEmpty(value)
                                      ? value.id
                                      : null,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      className:
                                        "pb-1" +
                                        (params.inputProps &&
                                          " " + params.inputProps.className),
                                    }}
                                    variant="outlined"
                                    label="LR Representatives"
                                    InputLabelProps={{
                                      shrink: true,
                                      classes: { shrink: "mt-n1" },
                                    }}
                                    placeholder="Select LR Representative..."
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                          )}
                          {this.props.stepsData.stepName == "Step 2" && (
                            <div className="row m-0 my-3">
                              <div className="col-md-6 mb-2">
                                <Autocomplete
                                  options={this.state.representativeData}
                                  autoComplete={true}
                                  autoHighlight={true}
                                  onChange={async (event, value) => {
                                    await this.setState({
                                      representative: value,
                                      representativeId: !_.isEmpty(value)
                                        ? value.id
                                        : null,
                                      representative_err: !_.isEmpty(value)
                                        ? ""
                                        : "Please select the representative.",
                                      recipient: null,
                                      recipientId: null,
                                      recipientData: [],
                                    });
                                    if (!_.isEmpty(value)) {
                                      this.getRecipientByRepresentativeId();
                                    }
                                  }}
                                  value={this.state.representative}
                                  getOptionLabel={(option) => {
                                    if (option != undefined) {
                                      return option.name;
                                    } else {
                                      return "";
                                    }
                                  }}
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      {option.name}
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Representative"
                                      placeholder="Select Representative..."
                                      variant="outlined"
                                      InputLabelProps={{
                                        shrink: true,
                                        classes: { shrink: "mt-n1" },
                                      }}
                                      fullWidth
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "disabled", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                                <span className="text-danger">
                                  {this.state.representative_err}
                                </span>
                              </div>
                              <div className="col-md-6 mb-2">
                                <Autocomplete
                                  options={this.state.recipientData}
                                  onChange={(event, value) =>
                                    this.setState({
                                      recipient: value,
                                      recipientId: !_.isEmpty(value)
                                        ? value.id
                                        : null,
                                      recipientName: !_.isEmpty(value)
                                        ? value.name
                                        : null,
                                      recipient_err: !_.isEmpty(value)
                                        ? ""
                                        : "Please select the recipient.",
                                    })
                                  }
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== "") {
                                      filtered.push({
                                        id: 0,
                                        name: `${params.inputValue}`,
                                      });
                                    }

                                    return filtered;
                                  }}
                                  value={this.state.recipient}
                                  getOptionLabel={(option) => {
                                    if (option != undefined) {
                                      return option.name;
                                    } else {
                                      return "";
                                    }
                                  }}
                                  renderOption={(option) => {
                                    var exist = true;
                                    if (
                                      !_.find(this.state.recipientData, {
                                        id: option.id,
                                      })
                                    ) {
                                      exist = false;
                                    }
                                    if (exist) {
                                      return (
                                        <li className="w-100">
                                          {" "}
                                          <div className="row mx-0">
                                            <div className="col-10">
                                              {option.name}
                                            </div>
                                            <div className="col-2 text-right">
                                              {" "}
                                              <i
                                                className="text-secondary pr-2 fas fa-pencil-alt"
                                                onClick={(e) => {
                                                  this.editRecipient(
                                                    e,
                                                    option.id,
                                                    option.name
                                                  );
                                                }}
                                              ></i>
                                              <i
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.nativeEvent.stopImmediatePropagation();
                                                  this.deleteRecipient(
                                                    option.id
                                                  );
                                                }}
                                                className="text-danger fas fa-trash"
                                              ></i>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li className="w-100">
                                          {" "}
                                          <div className="row mx-0">
                                            <div className="col-12">
                                              {option.name}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Recipient"
                                      placeholder="Select Management Recipient..."
                                      variant="outlined"
                                      InputLabelProps={{
                                        shrink: true,
                                        classes: { shrink: "mt-n1" },
                                      }}
                                      required={
                                        this.state.recipientData.length > 0
                                          ? true
                                          : false
                                      }
                                      fullWidth
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "disabled", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                                <span className="text-danger">
                                  {this.state.recipient_err}
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="col-md-12 my-0">
                            <span className="">Additional Facts</span>
                            <textarea
                              name=""
                              className="w-100 border"
                              value={this.state.statementOfFacts}
                              rows="5"
                              maxLength="500"
                              onChange={(event, value) => {
                                this.setState({
                                  statementOfFacts: event.target.value,
                                });
                              }}
                            />
                            <span className="text-danger">{}</span>
                          </div>
                          <div className="col-md-12 my-0">
                            <label>Remedy Sought</label>
                            <textarea
                              name="remedySought"
                              className="w-100 border"
                              value={this.state.remedySought}
                              rows="5"
                              maxLength="500"
                              onChange={(event, value) =>
                                this.setState({
                                  remedySought: event.target.value,
                                })
                              }
                            />
                            <span className="text-danger">{}</span>
                          </div>
                          <div className="col-md-12">
                            <span className="">Additional Documentation</span>
                            <FilePond
                              ref={(ref) => (this.pond = ref)}
                              files={this.props.attachedFiles}
                              allowMultiple={true}
                              allowFileTypeValidation={true}
                              allowImageExifOrientation={true}
                              allowImagePreview={true}
                              allowImageCrop={true}
                              allowFileEncode={true}
                              acceptedFileTypes={this.state.acceptedFileTypes}
                              fileValidateTypeDetectType={(source, type) =>
                                new Promise((resolve, reject) => {
                                  let rejectedExtensions = [
                                    "ade",
                                    "adp",
                                    "apk",
                                    "bat",
                                    "chm",
                                    "cmd",
                                    "com",
                                    "cpl",
                                    "dll",
                                    "dmg",
                                    "exe",
                                    "hta",
                                    "ins",
                                    "isp",
                                    "jar",
                                    "js",
                                    "jse",
                                    "lib",
                                    "lnk",
                                    "mde",
                                    "msc",
                                    "msi",
                                    "msp",
                                    "mst",
                                    "nsh",
                                    "pif",
                                    "scr",
                                    "sct",
                                    "shb",
                                    "sys",
                                    "vb",
                                    "vbe",
                                    "vbs",
                                    "vxd",
                                    "wsc",
                                    "wsf",
                                    "wsh",
                                    "cab",
                                  ];
                                  // If the file extension is not in our rejected list.
                                  if (
                                    !rejectedExtensions.some((ext) =>
                                      source.name.endsWith("." + ext)
                                    )
                                  ) {
                                    resolve(type);
                                  } else {
                                    // Otherwise it is rejected.
                                    //toast.error("File type is unsupported!");
                                    //reject(new Error('Files contains unsupported format!'));
                                    reject(type);
                                  }
                                })
                              }
                              oninit={() => this.handleInit()}
                              onupdatefiles={(fileItems) => {
                                this.updateFiles(fileItems);
                              }}
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="text-center">
                              <button
                                className="btn btn-dark btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                type="button"
                                onClick={() => {
                                  this.cancelStep();
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                onClick={() => {
                                  this.saveElevation();
                                }}
                                type="submit"
                              >
                                Save Changes
                              </button>
                              {this.props.stepsData.stepName == "PAR" && (
                                <button
                                  className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                  onClick={() => {
                                    this.elevateToArbitration();
                                  }}
                                  type="submit"
                                >
                                  Submit to Arbitration
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }
          }
        })()}
        <EditRecipient
          Id={this.state.EditRecipientId}
          Name={this.state.EditRecipientName}
          ShowModal={this.state.showRecipientModal}
          OnClose={this.closeRecipientModal}
          GetRecipientByRepresentativeId={this.getRecipientByRepresentativeId}
        />
      </>
    );
  }
}

export default withRouter(StepElevation);
