import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { connect } from "react-redux";
import * as actionTypes from "../../store/action";
import { Link } from "react-router-dom";
import axios from "../Shared/auth-header";
import $ from "jquery";
import AuthService from "./AuthService";
import { toast } from "react-toastify";
import Axios from "../Shared/auth-header";
import fetrackerActions from "../../store/feTracker/action";
import { bindActionCreators } from "redux";

class StartSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      status: false,
      updatelogin: true,
      show: false,
      logout: false,
      spinner: true,
      heading: "Configuring the Session!",
      iteration: 2,
    };
    const page = window.location.href;

    if (this.props.loginpage === false) {
      this.props.UpdateLogin();
    }
    this.state.show = true;
    this.loadStorage = this.loadStorage.bind(this);
    this.parseHash = this.parseHash.bind(this);
    this.checkTokenError = this.checkTokenError.bind(this);
    this.loadProfileWithExistingData = this.loadProfileWithExistingData.bind(
      this
    );
  }
  loadStorage = async (code) => {
    var { data } = await Axios.get("/api/Account/StartSession?code=" + code);

    if (data.status && data.status == "Success") {
        await AuthService.setToken(data.data);
        AuthService.intercomInitialization();
      //console.log(this.)
      window.addEventListener("storage", (event) => {
        if (event.key == "logout-event") {
          //this.Auth.logout();
          this.props.history.replace("/login");
        }
      });

      await this.props.UpdateLoginAgain();
      AuthService.checkSessionRepeat();
      this.props.history.push(
        "/dashboard/myProfile/" + data.data.memberData.id
      );
      this.props.LoginSucess();
      if (
        this.props.festate.showReviewRequest == "" &&
        !this.props.festate.checkingApproverPerson
      ) {
        this.props.fetrackerActions.checkIsBudgetApprover();
      }
    } else {
      await this.setState({ spinner: false });
      if (data.status.toLowerCase() == "logout") {
        $(".loading").hide();
        toast.warn("Token Not Found");
        AuthService.ClearLocalStorage();
          AuthService.logoutAuthO();
      }
      if (data.status.toLowerCase() == "forceresetpassword") {
        $(".loading").hide();
        AuthService.ClearLocalStorage();
        this.props.history.push("/forcedResetPassword", {
          forcedReset: data.key,
        });
      }
      if (data.status.toLowerCase() == "forceresetpasswordfailed") {
        $(".loading").hide();
        toast.error(data.message);
        AuthService.logoutAuthO();
      }

      if (data.status.toLowerCase() == "fail") {
        this.setState({ status: true, logout: true });
        this.setState({ message: data.message });
        localStorage.setItem("error", data.message);
        /// console.clear();
        $(".loading").hide();
        setTimeout(() => {
          localStorage.removeItem("error");
        }, 60000);
        }
        AuthService.clearIntercomCookiesAndRestart();
      // else {
      //    $(".loading").hide();
      //    this.props.UpdateLoginAgain();
      //    //this.props.history.replace("/dashboard");
      //    window.addEventListener("storage", (event) => {
      //        if (event.key == "logout-event") {
      //            //this.Auth.logout();
      //            this.props.history.replace("/login");
      //        }
      //    });
      //}
    }
  };
  //componentWillMount() {
  //}
  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&#]+([^=&]+)=([^&#]*)/gi,
      function(m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }
  async checkTokenError() {
    let token = localStorage.getItem("token");
    let error = localStorage.getItem("error");
    if (!error && !token) {
      if (this.state.iteration > 0) {
        await this.setState({ iteration: this.state.iteration - 1 });
        setTimeout(async () => {
          this.checkTokenError();
        }, 20000); // for release 20000 and for connect local to servers 120000
      } else {
        await this.setState({
          message: "Session Failed!",
          status: true,
          logout: true,
          spinner: false,
        });
        setTimeout(() => {
          AuthService.logoutAuthO();
        }, 4000);
        console.log("Session Failed");
      }
    } else if (error) {
      AuthService.ClearLocalStorage();
      localStorage.removeItem("error");
      this.setState({
        message: error,
        status: true,
        logout: true,
        spinner: false,
      });
    }
  }
  async parseHash(history) {
    await AuthService.auth0.parseHash((err, authResult) => {
      this.setState({ heading: "Loading Member's Details!" });
      if (authResult && authResult.idToken) {
        const { code, login } = this.getUrlVars();

        //let loggeduser = AuthService.getProfile();
        //AuthService.setSession(authResult);
        //if (loggeduser.memberData == null && AuthService.isAuthenticated()) {

        this.loadStorage(code);

        //}
        // else if (AuthService.isAuthenticated()) {
        //    this.props.history.push("/dashboard/myProfile/" + loggeduser.memberData.id);
        // }
        // else {
        //   AuthService.silentAuthorization();
        // }
      } else if (err) {
        if (err.error && err.error == "invalid_token") {
          this.checkTokenError();
          //let loggeduser = AuthService.getProfile();
          //if (loggeduser.memberData == null && AuthService.isAuthenticated()) {
          //    this.setState({ heading: "Loading Member's Details!" });
          //    this.loadStorage();
          //}
          //else if (AuthService.isAuthenticated()) {
          //    this.props.history.push("/dashboard/myProfile/" + loggeduser.memberData.id);
          //}
        } else if (err.error) {
          this.setState({ message: err.error, status: true });
          let redirect = sessionStorage.getItem("redirect");
          AuthService.ClearLocalStorage();
          if (
            redirect &&
            (redirect.indexOf("AccountRecovery") > 0 ||
              redirect.indexOf("register") > 0 ||
              redirect.indexOf("MemberNumberLookup") > 0)
          ) {
            history.push(redirect);
          } else {
            history.push("/login");
          }
            AuthService.clearIntercomCookiesAndRestart();
          console.log(err);
        } else {
          console.log(err);
        }
      }
    });
  }
  componentWillMount() {
    //const { login, forgotpassword, error, logout, code } = this.getUrlVars();
  }
  async loadProfileWithExistingData(redirect, loggeduser) {
    //var { data } = await Axios.get("/api/Account/GetStatus?Id=" + loggeduser.memberData.id);
    //if (data) {
    //    AuthService.logoutAuthO();
    //}
    if (
      redirect &&
      redirect.indexOf("AccountRecovery") < 0 &&
      redirect.indexOf("register") < 0 &&
      redirect.indexOf("MemberNumberLookup") < 0
    ) {
      AuthService.checkSessionRepeat();
      this.props.UpdateLoginAgain();

      this.props.history.push(redirect);
      sessionStorage.removeItem("redirect");
      if (
        this.props.festate.showReviewRequest == "" &&
        !this.props.festate.checkingApproverPerson
      ) {
        this.props.fetrackerActions.checkIsBudgetApprover();
      }
      return;
    } else {
      AuthService.checkSessionRepeat();
      this.props.UpdateLoginAgain();
      this.props.history.push(
        "/dashboard/myProfile/" + loggeduser.memberData.id
      );
      if (
        this.props.festate.showReviewRequest == "" &&
        !this.props.festate.checkingApproverPerson
      ) {
        this.props.fetrackerActions.checkIsBudgetApprover();
      }
      return;
    }
  }
  componentDidMount() {
    //debugger;
    let history = this.props.history;
    const {
      login,
      register,
      forgotpassword,
      error,
      logout,
      code,
      } = this.getUrlVars();
      if (forgotpassword || logout || register) {
          AuthService.clearIntercomCookiesAndRestart();
      }
    if (forgotpassword) {
      history.push("/AccountRecovery");
      return;
    }
    if (logout) {
      history.push("/login");
      return;
    }
    if (register) {
      history.push("/register");
      return;
      }

    let loggeduser = AuthService.getProfile();

    if (
      login &&
      loggeduser.memberData != null &&
      AuthService.isAuthenticated() &&
      !error
    ) {
      let redirect = sessionStorage.getItem("redirect");
      this.loadProfileWithExistingData(redirect, loggeduser);
      return;
    }

    const page = window.location.href;

    if (page.indexOf("#") != -1) {
      this.parseHash(this.props.history);
      return;
    } else {
      let loggeduser = AuthService.getProfile();
      if (
        loggeduser.memberData != null &&
        AuthService.isAuthenticated() &&
        page.indexOf("startSession") > 0
      ) {
        this.props.history.push(
          "/dashboard/myProfile/" + loggeduser.memberData.id
        );
        this.props.LoginSucess();
        if (
          this.props.festate.showReviewRequest == "" &&
          !this.props.festate.checkingApproverPerson
        ) {
          this.props.fetrackerActions.checkIsBudgetApprover();
          }
          AuthService.intercomInitialization();
      }
      if (!AuthService.isAuthenticated()) {
        AuthService.silentAuthorization();
      }
    }
  }
  render() {
    return (
      <>
        {this.state.show && (
          <div className="wrapper-page">
            <div className="card overflow-hidden account-card mx-3">
              {this.state.status && (
                <div class="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              )}
              <div className="bg-primary text-white text-center position-relative">
                <h4 className="font-20 m-b-5">{this.state.heading}</h4>
                <img src="assets/images/logo.png" height="142" alt="logo" />
              </div>
              <div className="account-card-content m-t-40" />
              {this.state.spinner && (
                <div className="col-12 text-center">
                  <div class="spinner-border ml-2 text-success" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              )}

              <div className="col-12 mb-4 text-center">
                {this.state.logout && (
                  <a
                    onClick={() => {
                      AuthService.logoutAuthO();
                    }}
                    className="font-500 text-white btn btn-danger text-right"
                  >
                    {" "}
                    Logout{" "}
                  </a>
                )}
              </div>
            </div>

            <div className="m-t-40 text-center">
              <p>© {new Date().getFullYear()} NATCA</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
    festate: state.fetracker,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    LoginSucess: () =>
      dispatch({
        type: actionTypes.WS_CONNECT,
        payload: `${process.env.REACT_APP_API_BASE_URL}/updateProgress`,
      }),
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
    fetrackerActions: bindActionCreators(fetrackerActions, dispatch),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(StartSession);
