import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import authAxios from "../../Shared/auth-header";
import Region from "./Region";
import ConfirmDeleteModal from "../../Shared/Modals";
import RegionModal from "../../Shared/RegionModal";
import { toast } from "react-toastify";
import Facilities from "../Facility/Facilities";
import AuthService from "../../Auth/AuthService";
import Directory from "../Directory";
import PermissionFeature from "../../Permissions/PermissionFeature";
import _ from "lodash";
import EquipmentsCardList from "../../Admin/Users/EditUser/EquipmentsCardList";
import FE_Equipment_Card_Types from "../../F&E/FE_Equipment_Card_Types";
const ButtonToNavigate = ({ title, history, color, classes }) => (
  <Button
    type="button"
    onClick={() =>
      history.push({
        pathname: "/directory/region",
        tab: Region,
        name: "Regions",
      })
    }
    color={color}
    className={classes}
  >
    {title}
  </Button>
);

class EditRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.location.type,
      uid: this.props.location.uid,
      code: "",
      region: "",
      description: "",
      valid: true,
      headerLabel: "",
      hasAnyEquipment: false,
    };

    this.regionMethods = () => {
      this.updateRegion = (args) => {
        this.setState({ region: args.code, regionid: args.id });
        $(".cancelModal").trigger("click");
      };
      return this;
    };
    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.uid === 0) {
          return false;
        }

        authAxios
          .delete("/api/Region/" + this.state.uid)
          .then(({ data }) => {
            if (data.status && data.status == "Success") {
              toast.success("Region Deleted Successfuly.");
              //this.setState({ delId: 0 });
              // $(".cancelModal").trigger("click");
              this.props.history.push({
                pathname: "/directory",
                tab: Region,
                name: "Regions",
              });
              //this.getRegions();
            } else {
              toast.error(data.message);
            }
            $(".cancelModal").trigger("click");
          })
          .catch(function(error) {
            console.log(error);
            $(".cancelModal").trigger("click");
          });
      };
      return this;
    };
  }
  handleChange = (evt) => {
    this.setState({
      [evt.target.name]:
        [evt.target.name] == "description"
          ? evt.target.value
          : evt.target.value.trim(),
    });
  };

  handleSubmit = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    if (!this.state.valid) {
      return false;
    }
    evt.target.className += " was-validated";
    if (evt.target.checkValidity() === false) {
      return false;
    }

    const data = {
      Id: this.state.id,
      code: this.state.code,
      description: this.state.description.trim(),
    };
    $(".loading").show();
    axios
      .post("/api/Region/SaveRegion", data)
      .then(({ data }) => {
        $(".loading").hide();

        if (data.status && data.status == "Success") {
          toast.success(data.message);
          let history_var = this.props.history;
          window.$("#EditRegionModal").on("hidden.bs.modal", function(e) {
            history_var.push({
              pathname: "/directory",
              tab: Region,
              name: "Regions",
            });
          });
        } else {
          toast.error(data.message);
        }
        window.$("#EditRegionModal").modal("hide");
      })
      .catch(function(error) {
        $(".loading").hide();
        window.$("#EditRegionModal").modal("hide");
        console.log(error);
      });
  };
  componentDidMount() {
    $(".loading").show();
    if (
      this.props.location.uid == null &&
      this.props.location.uid == undefined &&
      _.isEmpty(this.props.match.params.id)
    ) {
      this.props.history.push("/directory/region");
    }
    let url = "";
    url = `/api/Region/${this.props.match.params.id}`;

    axios.get(url).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          id: data.data.id,
          code: data.data.code,
          description: data.data.description,
          region: data.data.code,
          hasAnyEquipment: data.hasAnyEquipment,
          headerLabel:
            data.data.description + " Region - " + data.data.code + "  ",
        });
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
    if (this.state.region == "") $(".loading").hide();
  }
  componentWillUnmount() {
    if (this.props.history.action === "POP") {
      this.props.history.location.keyword = this.props.location.keyword;
      this.props.history.location.tab = Region;
      this.props.history.location.name = "Regions";
    }
    $(".modal-backdrop").length && $(".modal-backdrop").remove();
  }
  render() {
    return (
      <AUX>
        <Directory {...this.props} />

        <div className="container-fluid">
          <br />
          <div className="row align-items-center">
            <div className="col-sm-6">
              <h5 className="page-title">{this.state.headerLabel}</h5>
            </div>
          </div>

          {AuthService.canView(PermissionFeature.Facility) && (
            <div className="row">
              {this.state.region && (
                <Facilities
                  region={this.state.region}
                  backref={this.props.location}
                  his={this.props.history}
                />
              )}
            </div>
          )}
          <div className="row">
            <div className="col-12">
              {!_.isEmpty(this.props.match.params.id) &&
                this.state.hasAnyEquipment && (
                  <>
                    {AuthService.canView(
                      PermissionFeature.FERegionEquipment
                    ) && (
                      <EquipmentsCardList
                        recordId={this.props.match.params.id}
                        tableType={FE_Equipment_Card_Types.Region}
                      />
                    )}
                  </>
                )}
            </div>
          </div>
        </div>

        <ConfirmDeleteModal confirm={this.confirm} />

        {/* Edit region modal */}
        <div
          className="modal fade"
          id="EditRegionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="EditRegionModalTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="EditRegionModalTitle">
                  {" "}
                  Edit Region{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="needs-validation" noValidate>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        {AuthService.canView(PermissionFeature.RegionCode) && (
                          <div className="col-6">
                            <div className="form-group">
                              <MDBInput
                                value={this.state.code}
                                name="code"
                                id="code"
                                onChange={this.handleChange}
                                label="Region Code"
                                group
                                type="text"
                                disabled={
                                  !AuthService.canUpdate(
                                    PermissionFeature.Region
                                  )
                                }
                                required
                              >
                                <div className="invalid-feedback">
                                  Code is required.
                                </div>
                              </MDBInput>
                            </div>
                          </div>
                        )}
                        {AuthService.canView(PermissionFeature.RegionName) && (
                          <div className="col-6">
                            <div className="form-group">
                              <MDBInput
                                value={this.state.description}
                                name="description"
                                id="description"
                                onChange={this.handleChange}
                                label="Name"
                                disabled={
                                  !AuthService.canUpdate(
                                    PermissionFeature.Region
                                  )
                                }
                                group
                                type="text"
                                required
                              >
                                <div className="invalid-feedback">
                                  Name is required.
                                </div>
                              </MDBInput>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  {AuthService.canAdd(PermissionFeature.Region) && (
                    <Button color="primary" onClick={this.handleSubmit}>
                      Save Changes
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default EditRegion;
