import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../modules/Shared/auth-header";
import * as actionTypes from "../../store/action";
import Step1 from "./RegistrationWizard/Step1";
import Step2 from "./RegistrationWizard/Step2";
import Step4 from "./RegistrationWizard/Step4";
import Step3 from "./RegistrationWizard/Step3";

import SignUpService from "../Auth/SignUpService";

import _ from "lodash";
import $ from "jquery";
import MemberAddressModal from "./RegistrationWizard/Modals/MemberAddressModal";
import MemberPhoneModal from "./RegistrationWizard/Modals/MemberPhoneModal";
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //////////Sample Input////////////
      // MemberNumber: "78127",
      // Email: "ehsangondal258@gmail.com",
      // DateofBirth: "08/26/1997",
      // userName: "edi",
      // Password: "ehsan_123",
      // RePassword: "ehsan_123",
      // firstname: "",
      //////////Sample Input////////////

      MemberNumber: "",
      firstname: "",
      Email: "",
      DateofBirth: "",
      userName: "",
      Password: "",
      RePassword: "",
      email_err: "",
      MemberNumber_err: "",
      userName_err: "",
      DateofBirth_err: "",
      Password_err: "",
      RePassword_err: "",
      valid: false,
      message: "",
      status: false,

      ///Wizard Work//
      currentStep: 1,
      verificationcode: "",
      phoneVerificationCode: "",
      phoneNumber: "",
      isPhoneNoVerified: false,
      allowtextmessages: false,
      allowemails: false,
      allowphonecall: false,
      allowpostalmail: false,
      allowtext_newsalerts: false,
      allowtext_legislative: false,

      isVerified: false,
      addresses: [],
      phonenumbers: [],
      phonetypes: [],
      emails: [],
      addresstypes: [],
      editItem: false,
      newTitle: "",
      editItemId: "",
      currentAddress: {
        id: 0,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        isprimary: false,
        addresstypeid: 1,
      },
      currentPhone: {
        id: 0,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        isprimary: false,
        addresstypeid: 1,
      },
      showPhoneModal: false,
      addressModal: false,
      addresses_err: "",
      isAdressModalOpen: false,
      isPhoneModalOpen: false,
      isEmailModalOpen: false,
      modalLabel: "Add Address",
      hasLoadedContactInfo: false,
      ////////////Wizard Work//////////////
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePwd = this.handleChangePwd.bind(this);
    this.handleChangeDOB = this.handleChangeDOB.bind(this);

    //   Wizard Work
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.handleChange = this.handleChange.bind(this);

    //////Phone Events////////////
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleRemovePhone = this.handleRemovePhone.bind(this);
    this.handleFormSubmitPhone = this.handleFormSubmitPhone.bind(this);
    this.handleResetProfilePhones = this.handleResetProfilePhones.bind(this);
    this.handleAddPhone = this.handleAddPhone.bind(this);
    this.handleSelectChangePhoneType = this.handleSelectChangePhoneType.bind(
      this
    );

    ////////////////////////

    //   Wizard Work End
  }

  //   Wizard Work
  handleChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  }
  _next() {
    let currentStep = this.state.currentStep;

    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 6 ? 7 : currentStep + 1;
    this.setState(
      {
        currentStep: currentStep,
      },
      () => {
        if (this.state.currentStep === 3) {
          this.loadData(this.state.MemberNumber);
        }
      }
    );
  }

  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  get previousButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-secondary"
          type="button"
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    // ...else return nothing
    return null;
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 4) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Next
        </button>
      );
    }
    // ...else render nothing
    return null;
  }
  onChangeCB = (e) => {
    this.setState({
      [e.target.name]: e.currentTarget.checked,
    });
  };

  ////Step 2 verify Email addresss/////

  verifyEmailAddressBtnClick = (e) => {
    // this.loadData(this.state.MemberNumber);
    var mySelf = this;
    SignUpService.verifyEmailVerificationCode(
      this.state.verificationcode,
      mySelf
    );
  };
  resendEmailVerificationCode = () => {
    var mySelf = this;
    SignUpService.resendEmailVerificationCode(
      this.state.MemberNumber,
      this.state.Email,
      mySelf
    );
  };
  ////////////Step 2 End//////////////

  ///Step 3 Contact Info//
  myInfoCorrectBtnClick = () => {
    SignUpService.updateMemberStepInfo(3, this.state.MemberNumber);
    this._next();
  };
  handleFormSubmitAddresses = (e) => {
    e.preventDefault();
    e.target.className += " was-validated";
    if (e.target.checkValidity() === false) {
      return false;
    }

    return false;
    ///$(".loading").show();
    let valid = this.addressValidation();
    if (valid == "valid") {
      const data = { User_Id: 0, Addresses: [] };
      data.Addresses = this.state.addresses;
      data.User_Id = this.state.MemberNumber;

      axios
        .post("/api/Member/UpdateMemberAddresses", data)
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            toast.success("Addresses have been updated.");
            $(".loading").hide();
            this.toggleModal("address", true);
            this.loadData(this.state.MemberNumber);
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      $(".loading").hide();
      toast.error(valid);
    }
  };
  handleSelectChange = (e) => {
    const idx = e.target.dataset.id;
    const controlName = e.target.name;

    if (idx === "0") {
      this.state.currentAddress.controlName = e.target.value;

      this.setState({ currentAddress: this.state.currentAddress });
    }
    //this.state.addresses[idx][e.target.name] = e.target.value;
    //this.setState({ profile: this.state.profile });
  };
  SelectAddress = (id) => {
    var mySelf = this;
    var current = this.state.addresses.filter((x) => x.id === id);
    this.setState({ currentAddress: current });
    mySelf.toggleModal("address", false);
  };
  toggleModal = (param, isreset) => {
    if (param === "address") {
      this.setState({ isAdressModalOpen: !this.state.isAdressModalOpen });
      if (isreset) {
        this.setState({ modalLabel: "Add Address" });
        this.setState({
          currentAddress: {
            id: 0,
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            isprimary: false,
            addresstypeid: 1,
          },
        });
      }
    } else {
      this.setState({ modalLabel: "Edit Address" });
    }
    if (param === "phone") {
      this.setState({ isPhoneModalOpen: !this.state.isPhoneModalOpen });
    }
    if (param === "email") {
      if (param === "email") {
        this.setState({ isEmailModalOpen: !this.state.isEmailModalOpen });
      }
    }
  };
  handleAddAddressErrorRow = () => {
    const item = {
      address1_err: "",
      city_err: "",
      state_err: "",
      zipcode_err: "",
      isprimary_err: "",
    };
    let address_err = this.state.addresses_err;
    address_err = [...this.state.addresses_err, item];
    this.setState({
      addresses_err: address_err,
    });
  };
  loadData = async (id) => {
    //$(".loading").show();
    this.setState({ hasLoadedContactInfo: false });
    await axios
      .get("/api/Account/GetMemberContactInfo/" + id)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          const addresses = [];
          for (var i = 0; i < data.data.addresses.length; i++) {
            let address = Object.assign({}, data.data.addresses[i]);
            addresses.push(address);
            this.handleAddAddressErrorRow();
          }
          const emailAddresses = [];
          for (var i = 0; i < data.data.emailAddresses.length; i++) {
            let emailAddress = Object.assign({}, data.data.emailAddresses[i]);
            emailAddresses.push(emailAddress);
          }
          const phones = [];
          for (var i = 0; i < data.data.phones.length; i++) {
            let phone = Object.assign({}, data.data.phones[i]);
            phones.push(phone);
          }
          this.setState({ addresses: addresses });
          this.setState({ emails: emailAddresses });
          this.setState({ phonenumbers: phones });
          this.setState({ addresstypes: data.data.addressTypes });
          this.setState({ phoneTypes: data.data.phoneTypes });

          // $(".loading").hide();
          this.setState({ hasLoadedContactInfo: true });
        } else {
          //$(".loading").hide();
          this.setState({ hasLoadedContactInfo: true });
          toast.error(data.message);
        }
      });
  };
  changeHandlerAddress = (e) => {
    // const idx = e.target.parentElement.parentElement.dataset.id;
    // this.state[e.target.dataset.obj][idx][e.target.name] = e.target.value;
    // let name = e.target.name;
    // if (name != "address2") {
    //   if (e.target.value == null || e.target.value == "") {
    //     this.state[e.target.dataset.obj][idx][e.target.name + "_err"] =
    //       "Please Provide " + e.target.dataset.name;
    //   } else {
    //     this.state[e.target.dataset.obj][idx][e.target.name + "_err"] = "";
    //   }
    // }
    // this.setState({ profile: this.state.profile });
    const idx = e.target.parentElement.parentElement.dataset.id;
    const controlName = e.target.name;

    if (idx === "0") {
      this.state.currentAddress[controlName] = e.target.value;

      this.setState({ currentAddress: this.state.currentAddress[controlName] });
    }
  };

  /////////////////////////////////email events///////////////////////////////////
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
  EditItem = (id, oldTitle) => {
    this.setState({
      editItemId: id,
      newTitle: oldTitle,
    });
  };
  OnEdit = (id) => {
    this.setState({ editItem: true, editItemId: id });
  };

  SelectEmail = (id) => {
    var temp = [];
    temp = this.state.emails;
    var current = temp.filter((x) => x.id === id);
    this.state.emails = current;
    this.setState({ emails: current });
  };

  DeleteProcess = (type, id, primary) => {
    var temp = [];
    temp = this.state.profile;
    var current = temp.addresses.filter((x) => x.id === id);
    let Url =
      "/api/Member/DeleteMemberContactInfo?primaryType=" + type + "&id=" + id;
    axios.post(Url).then(({ data }) => {
      this.hideSpinner();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);
          /////////this.props.LoadData(this.props.memberid);//////////
        } else {
          this.hideSpinner();
          toast.error(data.message);
        }
      } else {
        toast.error("Information can not be deleted.");
      }
    });
  };

  handleFormSubmitEmail(e) {
    e.preventDefault();
    $(".loading").show();
    let valid = this.emailValidation();
    if (valid == "valid") {
      const data = { User_Id: 0, EmailAddresses: [] };
      data.EmailAddresses = this.state.profile.emailAddresses;
      data.User_Id = this.state.uid;

      axios
        .post("/api/Member/UpdateMemberEmailAddresses", data)
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            toast.success("Email Addresses have been updated.");
            $(".loading").hide();
            $("#emailPopupCloseBtn").click();
            this.loadData(this.state.uid);
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      $(".loading").hide();
      toast.error(valid);
    }
  }

  //////////////////////////////////email events end////////////////////////////////

  // phone events

  openPhoneModal = () => {
    this.setState({ showPhoneModal: true });
  };
  handleChangePhone(e) {
    const idx = e.target.parentElement.parentElement.dataset.id;
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.value;
    this.setState({ profile: this.state.profile });
    this.state.profile[e.target.dataset.obj][
      idx
    ].number_err = this.validatePhone(e.target.value).error;
  }

  handleChangePEmail = (e) => {
    const idx = e.target.parentElement.dataset.id;
    for (var i = 0; i < this.state.profile[e.target.dataset.obj].length; i++) {
      this.state.profile[e.target.dataset.obj][i][e.target.name] = false;
      this.state.profile[e.target.dataset.obj][i][e.target.name + "_err"] = "";
    }
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.checked;
    if (!e.target.checked) {
      this.state.profile[e.target.dataset.obj][idx][e.target.name + "_err"] =
        "Please assign atleast one " + e.target.dataset.name + " as primary";
    }
    this.setState({ profile: this.state.profile });
  };

  handleRemovePhone = (e) => {
    const idx = parseInt(e.target.dataset.id);
    this.state.profile.phones.splice(idx, 1);
    this.setState({
      profile: this.state.profile,
    });
  };

  handleFormSubmitPhone(e) {
    e.preventDefault();
    $(".loading").show();
    let valid = this.phoneValidation();
    if (valid == "valid") {
      const data = { User_Id: 0, Phones: [] };
      data.Phones = this.state.profile.phones;
      data.User_Id = this.state.uid;

      axios
        .post("/api/Member/UpdateMemberPhones", data)
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            toast.success("Phones have been updated.");
            $(".loading").hide();
            $("#phonePopupCloseBtn").click();
            this.loadData(this.state.uid);
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      $(".loading").hide();
      toast.error(valid);
    }
  }

  handleAddPhone = () => {
    const item = {
      number: "",
      isprimary: false,
      phonetypeid: this.state.profile.phoneTypes[0].id,
      phonetype: this.state.profile.phoneTypes[0].phonetype,
    };
    const p = this.state.profile;
    p.phones = [...this.state.profile.phones, item];
    this.setState({
      profile: p,
    });
  };
  handleResetProfilePhones = () => {
    // for avoid mutation we have to work like that by creating new fresh object.
    const phones = [];
    for (var i = 0; i < this.state.member.phones.length; i++) {
      let phone = Object.assign({}, this.state.member.phones[i]);
      phones.push(phone);
    }
    this.state.profile.phones = phones;
    this.setState({ profile: this.state.profile });
  };

  handleSelectChangePhoneType = (e) => {
    const idx = e.target.parentElement.dataset.id;
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.value;
    this.state.profile[e.target.dataset.obj][idx]["phonetype"] = $(e.target)
      .find("option:selected")
      .text();
    this.setState({ profile: this.state.profile });
  };
  // phone events end

  /////////END//////////

  //step 4 sendsms click

  resendPhoneCode = (e) => {
    this.setState({ currentStep: 4 });
    this.setState({ phoneNumber: "" });
    this.setState({ phoneVerificationCode: "" });
  };

  sendSMS = (e) => {
    var myself = this;

    if (!this.state.phoneNumber) {
      toast.error("Please Reenter your phone number!");
      return;
    }
    var actualNumber = this.state.phoneNumber;
    SignUpService.SendSMS(actualNumber, this.state.MemberNumber, myself);
  };
  changePhoneNumber = (e) => {
    this.setState({ phoneNumber: "" });
    this.setState({ currentStep: 4 });
    this.setState({ phoneVerificationCode: "" });
  };
  verifySMSCode = (e) => {
    var myself = this;
    var code = myself.state.phoneVerificationCode;
    var membernumber = myself.state.MemberNumber;
    var phoneNumber = myself.state.phoneNumber;
    if (_.isEmpty(phoneNumber)) {
      toast.warn("Please re-enter Phone No.");
      myself.setState({ currentStep: 4 });
      return;
    }
    SignUpService.VerifySMSCode(code, membernumber, phoneNumber, myself);
  };
  ////////////////   Wizrd Work End ///////////////////////

  keyPressHandler = (e) => {
    if (e.target.value.length == 5) return false;
  };

  changeUserNameHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    let error = this.validateUsername(event.target.value);
    if (error != "") {
      this.setState({ valid: false });
    } else {
      this.setState({ valid: true });
    }
    this.setState({ [event.target.name + "_err"]: error });
  };
  validateUsername = (username) => {
    let UsernameReg = /^[A-Za-z]+[A-Za-z0-9]*$/;

    if (username == "" || username == null) {
      return "Required Field";
    } else if (UsernameReg.test(username)) {
      return "";
    } else {
      return "Username should contain only letters or letters with numbers and no special characters";
    }
  };
  changeHandler = (event) => {
    let numberRegex = /^[0-9]+$/;
    let num = event.target.value.replace(
      /^[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/g,
      "z"
    );

    if (num === "z") return false;
    else {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.value === "") {
        this.setState({ [event.target.name + "_err"]: "Required Field" });
        this.setState({ valid: false });
      } else if (!numberRegex.test(event.target.value)) {
        this.setState({ [event.target.name + "_err"]: "Numbers only." });
        this.setState({ valid: false });
      } else {
        this.setState({ [event.target.name + "_err"]: "" });
        this.setState({ valid: true });
      }
    }
  };
  changeHandlerfirstname = (e) => {
    this.setState({ firstname: e.target.value });
  };
  handleChangeEmail(e) {
    this.setState({ Email: e.target.value });
    let EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value == "") {
      this.setState({ email_err: "Required Field" });
      this.setState({ valid: false });
    } else if (EmailReg.test(e.target.value)) {
      this.setState({ email_err: "" });
      this.setState({ valid: true });
    } else {
      this.setState({ email_err: "Enter Valid Email" });
      this.setState({ valid: false });
    }
  }
  handleChangeDOB(e) {
    this.setState({ DateofBirth: e.target.value });
    let dateRegex = new RegExp(
      "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$"
    );
    if (e.target.value === "") {
      this.setState({ DateofBirth_err: "Required Field" });
      this.setState({ valid: false });
    } else if (!dateRegex.test(e.target.value)) {
      this.setState({
        DateofBirth_err: "Please enter DOB in correct format mm/dd/yyyy",
      });
      this.setState({ valid: false });
    } else {
      this.setState({ DateofBirth_err: "" });
      this.setState({ valid: true });
    }
  }
  handleChangePwd(e) {
    this.setState({ [e.target.name]: e.target.value });
    let passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if (e.target.value === "") {
      this.setState({ [`${e.target.name}_err`]: "Required Field" });
      this.setState({ valid: false });
    } else {
      if (e.target.name == "Password") {
        if (!passRegex.test(e.target.value)) {
          this.setState({
            Password_err:
              "Password must be 8 characters long and must contain 1 uppercase, 1 lower case and 1 number.",
          });
          this.setState({ valid: false });
        } else {
          if (this.state.RePassword != e.target.value) {
            this.setState({
              RePassword_err: "Password should be the same.",
              Password_err: "",
            });
            this.setState({ valid: false });
          } else {
            this.setState({ RePassword_err: "", Password_err: "" });
            this.setState({ valid: true });
          }
        }
      } else {
        if (this.state.Password != e.target.value) {
          this.setState({ RePassword_err: "Password should be the same." });
          this.setState({ valid: false });
        } else {
          this.setState({ RePassword_err: "" });
          this.setState({ valid: true });
        }
      }
    }
  }

  handleFormSubmit(e) {
    e.preventDefault(e);

    // if (this.state.MemberNumber === "") {
    //   return false;
    // }

    const data = {
      allowemails: this.state.allowemails,
      allowphonecall: this.state.allowphonecall,
      allowpostalmail: this.state.allowpostalmail,
      allowtext_legislative: this.state.allowtext_legislative,
      allowtext_newsalerts: this.state.allowtext_newsalerts,
      allowtextmessages: this.state.allowtextmessages,
      MemberNumber: this.state.MemberNumber,
    };
    SignUpService.completeRegistration(data);
  }

  ////////Step 1 on submit/////

  registerBtnClick = (e) => {
    var mySelf = this;
    e.preventDefault();
    var valid = this.isValidData();

    if (this.state.valid && valid) {
      const data = {
        //model: {
        MemberNumber: this.state.MemberNumber,
        Email: this.state.Email,
        UserName: this.state.userName,
        Password: this.state.Password,
        DateofBirth: this.state.DateofBirth,
        //}
      };
      SignUpService.sendEmailForVerification(data, mySelf);
    }
  };

  isValidData = () => {
    var result = true;
    if (this.state.MemberNumber === "" || this.state.MemberNumber_err != "") {
      this.setState({ MemberNumber_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.Email === "" || this.state.email_err != "") {
      this.setState({ email_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.DateofBirth === "" || this.state.DateofBirth_err != "") {
      this.setState({ DateofBirth_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.userName === "" || this.state.userName_err != "") {
      this.setState({ userName_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.Password === "" || this.state.Password_err != "") {
      this.setState({ Password_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.RePassword === "" || this.state.RePassword_err != "") {
      this.setState({ RePassword_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }

    return result;
  };

  /////////First Step on submit/////

  componentDidMount() {
    if (this.props.loginpage === false) {
      this.props.UpdateLogin();
    }
    window.onpopstate = (e) => {
      this.props.UpdateLoginAgain();
    };
    ///Pending Registration Scenario
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.registrationPending
    ) {
      var registrationInfo = this.props.location.state.registrationInfo;

      this.resumeWizard(registrationInfo);
    }
  }

  resumeWizard = (registrationInfo) => {
    if (registrationInfo.currentStep == 2) {
      this.setState({ Email: registrationInfo.email });
    }
    if (registrationInfo.currentStep == 3) {
      this.loadData(registrationInfo.memberNumber);
    }
    if (registrationInfo.currentStep > 3) {
      this.setState({
        allowemails: registrationInfo.allowemails,
        allowphonecall: registrationInfo.allowphonecall,
        allowpostalmail: registrationInfo.allowpostalmail,
        allowtextmessages: registrationInfo.allowtextmessages,
        allowtext_newsalerts: registrationInfo.allowtext_newsalerts,
        allowtext_legislative: registrationInfo.allowtext_legislative,
        isPhoneNoVerified: registrationInfo.isPhoneNoVerified,
        phoneNumber: registrationInfo.memberPhone,
      });
    }
    this.setState({
      MemberNumber: registrationInfo.memberNumber,
      currentStep:
        registrationInfo.currentStep > 3 && registrationInfo.currentStep <= 7
          ? 4
          : registrationInfo.currentStep,
    });
  };

  render() {
    return (
      <AUX>
        <div className="wrapper-page">
          <div className="card overflow-hidden account-card mx-3">
            <h6 className="signUpStepper">
              STEP {this.state.currentStep > 4 ? 4 : this.state.currentStep} of
              4
            </h6>
            <div className="bg-primary ptb-login text-white text-center position-relative">
              {/* <h4 className="font-20 m-b-5">Register Now !</h4>
              <p className="text-white-50 mb-4">Sign up for your Account.</p>
               */}
              <span className="logo logo-admin">
                <img
                  src="assets/images/natca-logo.png"
                  height="142"
                  alt="logo"
                />
              </span>
            </div>

            <div className="account-card-content m-t-40 pt-1">
              {this.state.status && (
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              )}

              <form
                className="form-horizontal m-t-20"
                onSubmit={this.handleFormSubmit}
              >
                <Step1
                  MemberNumber={this.state.MemberNumber}
                  Email={this.state.Email}
                  DateofBirth={this.state.DateofBirth}
                  userName={this.state.userName}
                  Password={this.state.Password}
                  RePassword={this.state.RePassword}
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  handleChangeDOB={this.handleChangeDOB}
                  handleChangeEmail={this.handleChangeEmail}
                  changeUserNameHandler={this.changeUserNameHandler}
                  handleChangePwd={this.handleChangePwd}
                  changeHandler={this.changeHandler}
                  email_err={this.state.email_err}
                  MemberNumber_err={this.state.MemberNumber_err}
                  DateofBirth_err={this.state.DateofBirth_err}
                  userName_err={this.state.userName_err}
                  Password_err={this.state.Password_err}
                  RePassword_err={this.state.RePassword_err}
                  valid={this.state.valid}
                  next={this._next}
                  previous={this._prev}
                  registerClick={this.registerBtnClick}
                />
                <Step2
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  verificationcode={this.state.verificationcode}
                  next={this._next}
                  previous={this._prev}
                  enteredEmail={this.state.Email}
                  resendcode={this.resendEmailVerificationCode}
                  verifyEmailAddressBtnClick={this.verifyEmailAddressBtnClick}
                />
                <Step3
                  hasLoadedContactInfo={this.state.hasLoadedContactInfo}
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  MemberNumber={this.state.MemberNumber}
                  next={this._next}
                  previous={this._prev}
                  ////Phone Stuff/////
                  phonenumbers={this.state.phonenumbers}
                  handleChangePhone={this.handleChangePhone}
                  handleChangePPhone={this.handleChangePEmail}
                  handleRemovePhone={this.handleRemovePhone}
                  handleFormSubmitPhone={this.handleFormSubmitPhone}
                  handleResetProfilePhones={this.handleResetProfilePhones}
                  handleAddPhone={this.handleAddPhone}
                  handleSelectChangePhoneType={this.handleSelectChangePhoneType}
                  phoneTypes={this.state.phonenumbers}
                  ////Phone Stuff END/////

                  //////////Addresses Stuff/////////
                  toggleModal={this.toggleModal}
                  SelectAddress={this.SelectAddress}
                  addresses={this.state.addresses}
                  ///toggleModal={(param)=>this.toggleModal(param)}
                  //////////Addresses Stuff END/////////

                  //////Email Stuff///////////////
                  editItem={this.state.editItem}
                  emails={this.state.emails}
                  editItemId={this.state.editItemId}
                  EditItem={this.EditItem}
                  SelectEmail={this.SelectEmail}
                  OnEdit={this.OnEdit}
                  DeleteProcess={this.DeleteProcess}
                  /////Email Stuff END////////

                  myInfoCorrectBtnClick={this.myInfoCorrectBtnClick}
                />
                <Step4
                  currentStep={this.state.currentStep}
                  allowtextmessages={this.state.allowtextmessages}
                  allowemails={this.state.allowemails}
                  allowphonecall={this.state.allowphonecall}
                  allowpostalmail={this.state.allowpostalmail}
                  allowtext_newsalerts={this.state.allowtext_newsalerts}
                  allowtext_legislative={this.state.allowtext_legislative}
                  handleChange={this.handleChange}
                  username={this.state.username}
                  onChangeCB={this.onChangeCB}
                  next={this._next}
                  resendcode={this.resendPhoneCode}
                  changeNo={this.changePhoneNumber}
                  sendSMS={this.sendSMS}
                  previous={this._prev}
                  phoneNumber={this.state.phoneNumber}
                  isPhoneNoVerified={this.state.isPhoneNoVerified}
                  phoneVerificationCode={this.state.phoneVerificationCode}
                  verifySMSCode={this.verifySMSCode}
                />
                {/* {this.previousButton}
                {this.nextButton} */}
              </form>
            </div>
          </div>
          <MemberAddressModal
            changeHandlerAddress={this.changeHandlerAddress}
            handleFormSubmitAddresses={this.handleFormSubmitAddresses}
            handleSelectChange={this.handleSelectChange}
            toggleModal={this.toggleModal}
            currentaddress={this.state.currentAddress}
            isModalOpen={this.state.isAdressModalOpen}
            addressTypes={this.state.addresstypes}
            modalLabel={this.state.modalLabel}
            firstname={this.state.firstname}
            changeHandlerfirstname={this.changeHandlerfirstname}
          />

          <MemberPhoneModal
            changeHandlerAddress={this.changeHandlerAddress}
            handleFormSubmitAddresses={this.handleFormSubmitAddresses}
            handleSelectChange={this.handleSelectChange}
            toggleModal={this.toggleModal}
            currentphone={this.state.currentphone}
            isModalOpen={this.state.isPhoneModalOpen}
            modalLabel={this.state.modalLabel}
            phoneTypes={this.state.phoneTypes}
          />

          <div className="m-t-40 text-center">
            <p>© {new Date().getFullYear()} NATCA</p>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Register);
