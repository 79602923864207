import axios from "../../modules/Shared/auth-header";
import { toast } from "react-toastify";
import $ from "jquery";
import history from "../Shared/history";

export default {
  sendEmailForVerification: (data, myself) => {
    $(".loading").show();
    axios
      .post("/api/Account/Register", data)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          $(".loading").hide();
          if (myself.state.currentStep === 1) {
            myself._next();
          }
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      })
      .catch(function(error) {
        console.log(error);
      });
  },

  verifyEmailVerificationCode: (keydata, myself) => {
    $(".loading").show();
    const data = { verificationcode: keydata };
    axios
      .post("/api/Account/ConfirmEmail", data)
      .then(({ data }) => {
        if (data.status.toLowerCase() == "success") {
          myself.setState({ isVerified: true });
          $(".loading").hide();
          myself._next();
          toast.success(data.message);
        } else {
          myself.setState({ isVerified: false });
          toast.error(data.message);
        }
        $(".loading").hide();
      })
      .catch(function(error) {
        $(".loading").hide();

        console.log(error);
      });
  },
  completeRegistration: (data) => {
    $(".loading").show();
    axios
      .post("/api/Account/CompleteRegistration", data)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          $(".loading").hide();
          toast.success(data.message);
          history.push("/login");
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  SendSMS: (number, membernumber, myself) => {
    $(".loading").show();
    const url = `/api/Nexcom/SendVerificationCode`;
    axios
      .post(url, null, {
        params: {
          number,
          membernumber,
        },
      })
      .then(({ data }) => {
        $(".loading").hide();

        if (data.status && data.status.toLowerCase() == "success") {
          $(".loading").hide();
          myself._next();
          toast.success(data.message);
        } else {
          $(".loading").hide();
          toast.error(data.message);
        }
      });
  },

  VerifySMSCode: (code, membernumber, phoneNumber, myself) => {
    $(".loading").show();
    const url = "/api/Nexcom/VerifyCode";
    axios
      .post(url, null, {
        params: {
          code,
          membernumber,
          phoneNumber,
        },
      })
      .then(({ data }) => {
        $(".loading").show();
        if (data.status && data.status.toLowerCase() == "success") {
          $(".loading").hide();
          toast.success(data.message);
          myself.setState({ isPhoneNoVerified: true });
          myself.setState({ phoneNumber: data.pNumber });
          myself._next();
        } else {
          $(".loading").hide();
          toast.error(data.message);
        }
      });
  },
  updateMemberStepInfo: (stepNo, memberNumber) => {
    $(".loading").show();

    axios
      .post("/api/Account/UpdateMemberStepInfo/", null, {
        params: {
          stepNo,
          memberNumber,
        },
      })
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          $(".loading").hide();
        } else {
          $(".loading").hide();
          toast.error(data.message);
        }
      });
  },

  resendEmailVerificationCode: (memberNumber, email, myself) => {
    $(".loading").show();
    const url = "/api/Account/ResendEmailVerificationCode";
    axios
      .post(url, null, {
        params: {
          memberNumber,
          email,
        },
      })
      .then(({ data }) => {
        $(".loading").show();
        if (data.status && data.status.toLowerCase() == "success") {
          $(".loading").hide();
          toast.success(data.message);
          myself.setState({ isVerified: true });
        } else {
          $(".loading").hide();
          myself.setState({ isVerified: false });
          toast.error(data.message);
        }
      });
  },
};
