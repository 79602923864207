import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf",
      fontWeight: "light",
    },
  ],
});

const styles = StyleSheet.create({
  GrienvantSecondFooter: {
    marginTop: 10,
    textDecoration: "underline",
    fontSize: 13,
    fontWeight: "500",
  },
  grievanceHeaderLeft: {
    width: "100%",
    float: "left",
  },
  grievanceHeaderRight: {
    width: "50%",
    marginLeft: 330,
    marginTop: -30,
    float: "right",
  },
  headerImageView: {
    marginLeft: 100,
  },
  GrienvantFooter: {
    marginTop: 30,
  },
  image: {
    width: 80,
    height: 80,
    float: "left",
  },
  floatLeft: {
    float: "left",
  },
  sec: {
    //marginTop: 80
  },
  Dear: {
    marginTop: 30,
    marginBottom: 30,
  },
  afterSalution: {
    marginTop: 10,
  },
  afterDateText: {
    marginTop: 30,
  },
  dateText: {
    marginTop: 50,
  },
  logoImage: {
    width: 80,
    height: 80,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    fontSize: 12,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    marginTop: 20,
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export function PARPdfDocument(props) {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.grievanceHeaderLeft}>
          <Image
            style={styles.image}
            src="https://upload.wikimedia.org/wikipedia/en/6/67/NATCA_logo.png"
          />
          <View style={[styles.headerImageView, { marginTop: -60 }]}>
            <Text>{props.data.representativeName}</Text>
            <Text>{props.data.representativePhone}</Text>
            <Text
              style={{ width: "200px", display: "flex", flexDirection: "row" }}
            >
              {props.data.representativeEmail}
            </Text>
          </View>
        </View>
        <Text style={styles.dateText}>{props.data.submissionDate}</Text>
        <Text>  </Text>
        <Text style={styles.afterDateText}>{props.data.recipientName}</Text>
        <Text style={styles.afterSalution}>{props.data.memberName}</Text>
        <Text style={styles.afterSalution}>{props.data.memberFacilityAddress}</Text>
        <View>
          <Text style={styles.Dear}>Dear {props.data.recipientName},</Text>
        </View>
        <View>
          <Text>
            This letter is to request that, in accordance with the applicable Collective Bargaining Agreement, the following grievance(s) be submitted for Pre-Arbitration Review:
          </Text>
        </View>

        <View>
          <Text style={styles.GrienvantFooter}>
            <Text>  1</Text>
            <Text>                  {props.data.faaNumber} </Text>
            <Text>                  {props.data.grievanceNumber}</Text>
          </Text>

        </View>
        <Text>  </Text>
        <Text>  </Text>
        <View>
          <Text
            style={[
              styles.GrienvantSecondFooter,
              { fontWeight: "100 !mportant" },
            ]}
          >
            Sincerely,
          </Text>
        </View>


        <View style={{ marginTop: 80 }}>
          <Text style={{ textDecoration: "underline" }}>
            {props.data.rvpName}
          </Text>
          <Text> </Text>
          <Text style={{ textDecoration: "underline" }}>
            {props.data.rvpTitle}
          </Text>
          <Text> </Text>
          <Text style={{ textDecoration: "underline" }}>
            {props.data.regionName}
          </Text>
        </View>
        <View style={{ marginTop: -15 }}>
          <Text
            style={{
              textDecoration: "underline",
              fontWeight: "light",
              width: "180px",
              marginLeft: 190,
              textAlign: "right",
            }}
          >
            {props.data.submittionDate}
          </Text>
          <Text> </Text>
          <Text> </Text>
          <Text> </Text>
          <Text> </Text>
          <Text> </Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
