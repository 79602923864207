import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import axios from "../Shared/auth-header";
import { toast } from "react-toastify";
import Papa from "papaparse";
import $ from "jquery";
import BootstrapTable from "react-bootstrap-table-next";
import NCEPTUpload from "../Dashboard/FacilityDashboard/NCEPTUpload";
import _ from "lodash";
import { Collapse, Card, CardBody, Spinner, Row, Col } from "reactstrap";
import MainImportSummary from "./MainImportSummary";
import PayFileColumnsModal from "./PayFileColumnsModal";
import PayFileOneColumns from "./PayFileOneColumns";
import PayFileTwoColumns from "./PayFileTwoColumns";
import FinanceFileColumns from "./FinanceFileColumns";
import { FixedWidthParser } from "fixed-width-parser";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import payfileActions from "../../store/payfile/action";
import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";

class Payfile extends Component {
  constructor(props) {
    super(props);
    const year = new Date().getFullYear();
    this.years = Array.from(new Array(20), (val, index) => year - index);
    this.state = {
      upLoadStatus: 0,
      progress: false,
      payfile: "",
      importType: 0,
      data: [],
      guid: "",
      payfilecardopen: true,
      nceptcardopen: true,
      payperiod: "",
      showPayfileCols: false,
      payyear: "",
      missingColumn: false,
      missingColumnData: [],
    };
    this.uploadHandler = this.uploadHandler.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.filePapaParse = this.filePapaParse.bind(this);
    this.filehandle = this.filehandle.bind(this);
    this.files = [];
    this.columns = [
      {
        text: "Error",
        dataField: "errorMessage",
        sort: true,
      },
      {
        text: "Field",
        dataField: "fieldName",
        sort: true,
      },
      {
        text: "Line Number",
        dataField: "lineNumber",
        sort: true,
      },
    ];
  }
  componentDidMount = async () => {
    // console.log("componentDidMount Payfile");
    $("#payfileType option:first-child").attr("disabled", "disabled");
    this.setState({ payyear: new Date().getFullYear().toString() });
    this.setState({
      payperiod: "1",
    });
    await this.props.actions.wsConnect(
      `${process.env.REACT_APP_API_BASE_URL}/updateProgress`
    );
  };
  componentWillUnmount = async () => {
    //console.log("componentWillUnmount Payfile");
    await this.props.actions.wsDisconnect(
      `${process.env.REACT_APP_API_BASE_URL}/updateProgress`
    );
  };
  createUUID = () => {
    const CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
      ""
    );
    let chars = CHARS,
      uuid = new Array(36),
      rnd = 0,
      r;
    for (let i = 0; i < 36; i++) {
      if (i == 8 || i == 13 || i == 18 || i == 23) {
        uuid[i] = "-";
      } else if (i == 14) {
        uuid[i] = "4";
      } else {
        if (rnd <= 0x02) rnd = (0x2000000 + Math.random() * 0x1000000) | 0;
        r = rnd & 0xf;
        rnd = rnd >> 4;
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
    this.state.guid = uuid.join("");
    this.setState({ guid: this.state.guid });
  };
  fileChangedHandler = (event) => {
    this.setState({ missingColumn: false });
    const file = event.target.files[0];

    if (typeof file === "undefined") {
      return false;
    }
    this.files[event.target.dataset.ref] = file;
    var fr = new FileReader();
    if (
      !/\.(csv)$/i.test(file.name) &&
      (this.state.importType === "3" ||
        this.state.importType === "4" ||
        this.state.importType === "5") &&
      !/\.(txt)$/i.test(file.name)
    ) {
      toast.error("Unsupported File Type.");
      this.setState({
        missingColumn: this.state.missingColumn,
        missingColumnData: [],
      });
      return false;
    }
    this.setState({ payfile: file.name });
    if (
      this.state.importType === "3" ||
      this.state.importType === "4" ||
      this.state.importType === "5"
    ) {
      fr.onload = this.filehandle;

      fr.readAsText(event.target.files[0]);
    } else {
      Papa.parse(file, {
        header: true,
        complete: this.filePapaParse,
      });
    }
    this.setState({
      missingColumn: this.state.missingColumn,
      missingColumnData: this.state.missingColumnData,
    });
  };
  filehandle(event) {
    let data = event.target.result;
    this.setState({ missingColumn: true });
    this.setState({ missingColumnData: [] });
    var currentCols = [];
    if (this.state.importType === "3") {
      currentCols = FinanceFileColumns.DUES;
    } else if (this.state.importType === "4" || this.state.importType === "5") {
      currentCols = FinanceFileColumns.PAC_DRF_NCF;
    }
    if (
      this.state.importType === "3" ||
      this.state.importType === "4" ||
      this.state.importType === "5"
    ) {
      const fixedWidthParser = new FixedWidthParser(currentCols);
      const result = fixedWidthParser.parse(data);
      if (result.length > 0) {
        if (this.state.importType === "3") {
          if (
            result[0].YYPPxxx &&
            !currentCols[0].regex.test(result[0].YYPPxxx.trim())
          ) {
            this.state.missingColumnData.push(currentCols[0].name);
          }
          if (
            result[0]["LNAME, FNAME"] &&
            !currentCols[1].regex.test(result[0]["LNAME, FNAME"].trim())
          ) {
            this.state.missingColumnData.push(currentCols[1].name);
          }

          if (
            result[0].AMOUNT &&
            !currentCols[2].regex.test(result[0].AMOUNT.trim())
          ) {
            this.state.missingColumnData.push(currentCols[2].name);
          }

          if (
            result[0].ECI &&
            !currentCols[3].regex.test(result[0].ECI.trim())
          ) {
            this.state.missingColumnData.push(currentCols[3].name);
          }
        } else if (
          this.state.importType === "4" ||
          this.state.importType === "5"
        ) {
          if (
            result[0].YYPPxx &&
            !currentCols[0].regex.test(result[0].YYPPxx.trim())
          ) {
            this.state.missingColumnData.push(currentCols[0].name);
          }
          if (
            result[0].REGION &&
            !currentCols[1].regex.test(result[0].REGION.trim())
          ) {
            this.state.missingColumnData.push(currentCols[1].name);
          }
          if (
            result[0]["LNAME, FNAME"] &&
            !currentCols[2].regex.test(result[0]["LNAME, FNAME"].trim())
          ) {
            this.state.missingColumnData.push(currentCols[2].name);
          }

          if (
            result[0].AMOUNT &&
            !currentCols[3].regex.test(result[0].AMOUNT.trim())
          ) {
            this.state.missingColumnData.push(currentCols[3].name);
          }

          if (
            result[0].ECI &&
            !currentCols[4].regex.test(result[0].ECI.trim())
          ) {
            this.state.missingColumnData.push(currentCols[4].name);
          }
        }
        this.setState({ missingColumnData: this.state.missingColumnData });
      }
    }
  }
  filePapaParse(results) {
    let cColumns = results.meta.fields;
    this.setState({ missingColumn: true });
    this.setState({ missingColumnData: [] });
    if (_.isArray(cColumns)) {
      var currentCols = [];
      if (this.state.importType === "1") {
        currentCols = PayFileOneColumns;
      } else if (this.state.importType === "2") {
        currentCols = PayFileTwoColumns;
      } else if (this.state.importType === "3") {
        currentCols = FinanceFileColumns.DUES;
      }
      if (this.state.importType === "4" || this.state.importType === "5") {
        currentCols = FinanceFileColumns.PAC_DRF_NCF;
      }
      if (this.state.importType === "1" || this.state.importType === "2") {
        currentCols.map((item) => {
          let idx = cColumns.findIndex(
            (x) => x.split(" ").join("") == item.split(" ").join("")
          );
          if (idx == -1) {
            this.setState({
              missingColumnData: [...this.state.missingColumnData, item],
            });
          }
        });
      } else if (
        this.state.importType === "3" ||
        this.state.importType === "4" ||
        this.state.importType === "5"
      ) {
        const fixedWidthParser = new FixedWidthParser(currentCols);
        let string_data = results.data.join("");
        const result = fixedWidthParser.parse(string_data);
        console.log("parsresult", result);
      }
    }
  }

  handleChange = (e) => {
    var selectedVal = document.getElementById("payfileType").value;
    this.setState({ importType: selectedVal });
    this.setState({ missingColumn: false });

    var selectedyear = this.state.payyear;
    //CALL TO GET NEXT PAY PERIOD
    this.props.actions.getNextPayPeriod(selectedVal, selectedyear, this);
  };

  handleChangePayPeriod = (e) => {
    this.setState({ payperiod: document.getElementById("payperiod").value });
  };

  handleChangeYear = (e) => {
    var selectedYear = document.getElementById("payyear").value;
    this.setState({ payyear: document.getElementById("payyear").value });
    var selectedImportType = this.state.importType;
    if (this.state.importType) {
      this.props.actions.getNextPayPeriod(
        selectedImportType,
        selectedYear,
        this
      );
    }
  };
  handleClose = () => {
    this.setState({
      showPayfileCols: false,
    });
  };
  validatePayfile = () => {
    var result = false;
    if (this.state.importType == 0) {
      result = true;
    }
    if (_.isEmpty(this.state.payfile)) {
      result = true;
    }
    // if (this.state.missingColumn) {
    //   result = true;
    // }
    return result;
  };
  validateChoosePayfile = () => {
    var result = false;
    if (this.state.importType == 0) {
      result = true;
    }
    return result;
  };
  uploadHandler = async () => {
    this.createUUID();
    $(".loading").show();
    $(".errorGrid").hide();
    const formData = new FormData();
    //this.files.map(f => formData.append("File", f));
    formData.append("File", this.files[0]);
    formData.append("importType", this.state.importType);
    formData.append("guid", this.state.guid);
    formData.append("year", this.state.payyear);
    formData.append("pp", this.state.payperiod);

    axios
      .post("/api/DataImport/SubmitDataImportJob", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.status && res.data.status.toLowerCase() == "success") {
          toast.success("Payfile uploaded for processing successfully.");
          $(".loading").hide();
          const formData = {
            PageNo: 1,
            PageSize: 10,
            SortBy: "file_id",
            SortOrder: "desc",
          };
          // if (this.state.importType == 2) {
          //     this.props.actions.getSummaryList(formData);
          // }
          $("#payfileType").val("0"); //reset pay file type
        } else {
          // $(".errorGrid").show();
          toast.error(res.data.message);
          // if (res.data && res.data.data) {
          //   this.setState({ data: res.data.data });
          // }
          $(".loading").hide();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  render() {
    return (
      <AUX>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Uploads</h4>
              </div>
            </div>
          </div>
          {AuthService.canView(PermissionFeature.AdminPayfiles) && (
            <Card>
              <h5
                className="card-header cursorPointer"
                onClick={() =>
                  this.setState({
                    payfilecardopen: !this.state.payfilecardopen,
                  })
                }
              >
                <i className="fas fa-file" aria-hidden="true"></i> Pay Files
                <i
                  className={`pull-right float-right ${
                    this.state.payfilecardopen
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }`}
                ></i>
              </h5>
              <Collapse isOpen={this.state.payfilecardopen}>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        {AuthService.canAdd(
                          PermissionFeature.AdminPayfiles
                        ) && (
                          <React.Fragment>
                            <div className="row mx-0">
                              <div className="form-group col-lg-2 col-md-3">
                                {/* <label for="payfileType">Pay File Type</label> */}
                                <select
                                  className={`${this.state.importType === 0 &&
                                    "font-weight-bold"} browser-default custom-select`}
                                  id="payfileType"
                                  onChange={this.handleChange}
                                >
                                  <option
                                    className="font-weight-bold"
                                    value="0"
                                  >
                                    Select File...
                                  </option>
                                  <option value="1">Pay File 1</option>
                                  <option value="2">Pay File 2</option>
                                  <option value="3">Dues Remittance</option>
                                  <option value="4">PAC Remittance</option>
                                  <option value="5">DRF Remittance</option>
                                </select>
                              </div>
                              <div className="payperiodoptions form-group col-lg-2 col-md-3">
                                <select
                                  className="browser-default custom-select"
                                  id="payperiod"
                                  onChange={this.handleChangePayPeriod}
                                >
                                  {/* <option className="font-weight-bold" value="0">
                              Select Pay Period...
                            </option> */}
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>

                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                </select>
                              </div>
                              <div className="form-group col-lg-2 col-md-3">
                                <select
                                  className="browser-default custom-select"
                                  id="payyear"
                                  onChange={this.handleChangeYear}
                                >
                                  {this.years.map((year, index) => {
                                    return (
                                      <option key={index} value={year}>
                                        {year}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-group col-xl-2 pr-md-2 col-lg-3 col-md-4">
                                <input
                                  className="d-none"
                                  ref={(fileinput1) =>
                                    (this.fileinput1 = fileinput1)
                                  }
                                  type="file"
                                  onChange={this.fileChangedHandler}
                                  name="payfile1"
                                  data-ref="0"
                                />

                                <button
                                  disabled={this.validateChoosePayfile()}
                                  className="w-100 btn btn-primary"
                                  onClick={() => this.fileinput1.click()}
                                >
                                  Choose Pay File
                                </button>
                              </div>
                              <div className="form-group px-md-2 col-lg-2 col-md-2">
                                <button
                                  disabled={this.validatePayfile()}
                                  className="btn btn-primary w-100"
                                  onClick={this.uploadHandler}
                                >
                                  Upload
                                </button>
                              </div>
                            </div>

                            {!_.isEmpty(this.state.importType) && (
                              <div className="col px-0">
                                <a
                                  data-toggle="modal"
                                  href="#"
                                  className="p-2"
                                  // data-target="#NCEPTColumnsModal"
                                  onClick={() => {
                                    this.setState({
                                      showPayfileCols: !this.state
                                        .showPayfileCols,
                                    });
                                  }}
                                >
                                  View Pay File Columns
                                </a>
                              </div>
                            )}
                            <span className="p-2">{this.state.payfile}</span>
                            {this.state.missingColumn &&
                              !_.isEmpty(this.state.missingColumnData) && (
                                <div className="p-2 col-12 mb-4">
                                  <p className="h6 text-secondary font-weight-normal">
                                    Following are the missing columns in this
                                    file:
                                  </p>
                                  <ol>
                                    {this.state.missingColumnData.map(
                                      (item) => (
                                        <li>{item}</li>
                                      )
                                    )}
                                  </ol>
                                </div>
                              )}
                            <div className="row mt-3 errorGrid dispNone">
                              <div className="col-12">
                                <h5 className="mt-3">Upload Result:</h5>
                                <p className="error">
                                  File upload was not successful. Please fix the
                                  below errors and upload the file again.
                                </p>
                              </div>

                              <div className="col">
                                <BootstrapTable
                                  striped
                                  hover
                                  keyField="id"
                                  data={this.state.data}
                                  columns={this.columns}
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        <div className="row">
                          <div className="col">
                            <MainImportSummary />
                          </div>
                        </div>
                        <PayFileColumnsModal
                          payfiletype={this.state.importType}
                          showModal={this.state.showPayfileCols}
                          handleClose={this.handleClose}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </Card>
          )}
          <NCEPTUpload></NCEPTUpload>
        </div>
      </AUX>
    );
  }
}

///export default Payfile;

function mapStateToProps(state, ownProps) {
  return {
    payFileState: state.payfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(payfileActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Payfile);
