import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf",
      fontWeight: "light",
    },
  ],
});

const styles = StyleSheet.create({
  GrienvantSecondFooter: {
    marginTop: 10,
    textDecoration: "underline",
    fontSize: 13,
    fontWeight: "500",
  },
  grievanceHeaderLeft: {
    width: "100%",
    float: "left",
  },
  grievanceHeaderRight: {
    width: "50%",
    marginLeft: 330,
    marginTop: -30,
    float: "right",
  },
  headerImageView: {
    marginLeft: 100,
  },
  GrienvantFooter: {
    marginTop: 30,
  },
  image: {
    width: 80,
    height: 80,
    float: "left",
  },
  floatLeft: {
    float: "left",
  },
  sec: {
    //marginTop: 80
  },
  Dear: {
    marginTop: 30,
    marginBottom: 30,
  },
  afterSalution: {
    marginTop: 10,
  },
  afterDateText: {
    marginTop: 30,
  },
  dateText: {
    marginTop: 50,
  },
  logoImage: {
    width: 80,
    height: 80,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    fontSize: 12,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    marginTop: 20,
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export function PdfDocument(props) {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.grievanceHeaderLeft}>
          <Image
            style={styles.image}
            src="https://upload.wikimedia.org/wikipedia/en/6/67/NATCA_logo.png"
          />
          <View style={[styles.headerImageView, { marginTop: -60 }]}>
            <Text>{props.data.facilityName}</Text>
            <Text
              style={{ width: "200px", display: "flex", flexDirection: "row" }}
            >
              {props.data.facilityAddress}
            </Text>
          </View>
        </View>
        <View style={styles.grievanceHeaderRight}>
          <Text>Re : {props.data.grievanceSubject}</Text>
          <Text>Grievance # : {props.data.grievanceNumber}</Text>
          <Text>FAA Grievance # : {props.data.faaNumber} </Text>
          <Text>Date of Violation : {props.data.voilationDate}</Text>
        </View>
        <Text style={styles.dateText}>{props.data.submissionDate}</Text>
        <Text style={styles.afterDateText}>{props.data.recipientName}</Text>
        <Text style={styles.afterSalution}>{props.data.memberName}</Text>

        <View>
          <Text style={styles.Dear}>Dear {props.data.recipientName},</Text>
        </View>
        <View>
          <Text>
            This grievance is filed pursuant to Article 9 of the 2016 Collective
            Bargaining Agreement and 5 USC 7103 (a) (9). The Agency's actions
            constitute a violation of the 2016 Agreement, 5 U.S.C. Chapter 71
            and all applicable laws, rules, regulations, and past practice.
          </Text>
        </View>

        <View>
          <Text style={styles.GrienvantFooter}>
            Grievant(s) : {props.data.grievantName}
          </Text>
          <Text>Representative : {props.data.representativeName}</Text>
          <Text>Meeting Requested : {props.data.isMeetingRequested}</Text>
        </View>

        <View>
          <Text
            style={[
              styles.GrienvantSecondFooter,
              { fontWeight: "100 !mportant" },
            ]}
          >
            Statement Of Facts
          </Text>
          <Text>{props.data.statementOfFact}</Text>
        </View>

        <View>
          <Text
            style={[
              styles.GrienvantSecondFooter,
              { fontWeight: "100 !mportant" },
            ]}
          >
            Remedy Sought
          </Text>
          <Text>{props.data.remedySought}</Text>
        </View>

        <View style={{ marginTop: 80 }}>
          <Text style={{ textDecoration: "underline" }}>
            {props.data.representativeName}
          </Text>
        </View>
        <View style={{ marginTop: -15 }}>
          <Text
            style={{
              textDecoration: "underline",
              fontWeight: "light",
              width: "180px",
              marginLeft: 190,
              textAlign: "right",
            }}
          >
            {props.data.recipientName}
          </Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
