import React, { Component } from "react";
import AuthService from "./Auth/AuthService";
import * as actionTypes from "../store/action";
import Axios from "./Shared/auth-header";
function withAuth(AuthComponent) {
  const Auth = AuthService;

  return class AuthWrapped extends Component {
    constructor(props) {
      super(props);

      this.state = {
        user: null,
        loginpage: this.props.loginpage,
        show: false,
        };
        this.checkMember = this.checkMember.bind(this);
    }
      async checkMember() {
          let loggeduser = AuthService.getProfile();
          if (
              loggeduser.memberData != null &&
              AuthService.isAuthenticated()
          ) {
              var { data } = await Axios.get("/api/Account/GetStatus?Id=" + loggeduser.memberData.id);
              if (data) {
                  AuthService.logoutAuthO();
              }
          }
          await this.setState({ show: true });
      }
    componentWillMount() {
      const page = window.location.href;
      var memberData = AuthService.getProfile().memberData;
      if (
        memberData &&
        memberData.membertypeid != 6 &&
        memberData.membertypeid != 8 &&
        memberData.membertypeid != 10
      ) {
        this.props.history.push("/");
      }
      if (
        page.indexOf("resetpassword") > 0 ||
        page.indexOf("confirmEmail") > 0
      ) {
        this.setState({ show: true });
      } else if (page.indexOf("startSession") > 0) {
          this.checkMember();
      } else {
        if (!Auth.isAuthenticated()) {
          sessionStorage.setItem(
            "redirect",
            window.location.href.split(window.location.host)[1]
          );
          Auth.silentAuthorization();
        } else {
          this.setState({ show: false });
          Auth._checkStatus().then((res) => {
            if (!res) {
              //this.setState({ show: true });

              Auth.logoutAuthO();
              //this.props.history.push("/login");
            }

            if (!Auth.loggedIn()) {
              //this.setState({ show: true });

              this.props.history.push("/login");
            } else {
              try {
                const profile = Auth.getProfile();
                this.setState({
                  user: profile,
                });
                this.setState({ loginpage: false });
                if (
                  process.env.NODE_ENV === "development" ||
                  process.env.NODE_ENV === "local"
                ) {
                  AuthService.checkSession();
                } else {
                  let $this = this;
                  AuthService.auth0.checkSession(
                    {
                      scope: "code",
                      domain: process.env.REACT_APP_AUTH0_DOMAIN,
                      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,

                      connection: process.env.REACT_APP_AUTH0_DB,
                    },
                    function(err, authResult) {
                      console.log("check session");
                      // err if automatic parseHash fails
                      if (err !== undefined && err) {
                        AuthService.logoutAuthO();
                        console.error(err);
                        return;
                      }
                      let loggeduser = AuthService.getProfile();
                      if (
                        loggeduser.memberData != null &&
                        AuthService.isAuthenticated()
                      ) {
                          $this.checkMember();
                        $this.setState({ show: true });
                        setTimeout(function() {
                          AuthService.checkSessionRepeat();
                        }, 30000);
                        AuthService.intercomInitialization();

                        return;
                      } else {
                        AuthService.intercomInitialization();
                        AuthService.silentAuthorization();
                      }

                      //resolve(authResult);
                    }
                  );
                }

                //this.setState({ show: true });
              } catch (err) {
                Auth.logoutAuthO();

                // this.props.history.push("/login");
              }
            }
          });
        }
      }
    }

    render() {
      if (this.state.show) {
        return (
          <AuthComponent
            topbar={this.props.topbar}
            footer={this.props.footer}
            sidebar={this.props.sidebar}
            loginpage={this.props.loginpage}
            history={this.props.history}
            user={this.state.user}
            loading={this.state.loading}
          />
        );
      } else {
        return <></>;
      }
    }
  };
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};
export default withAuth;
